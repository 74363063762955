

export class PlacedOrderDetails {

    constructor() {

        this.invoicedDate = '';
        this.invoiceNumber = '';
        this.orderNumber = '';
        this.purchaseOrder = '';
        this.total = '';
        this.parts = [];
    }


}
