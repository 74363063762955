export default {

    baseUrl: window.config.api.partsUri,

    /**
     * The catalog API URL
     */
    catalogPartsApiUrl: `${window.config.api.partsUri}/v1/catalog/parts`,

    catalogApiUrl: `${window.config.api.partsUri}/v1/catalog`,

    /**
     * The fulfillment API URL
     */
    fulfillmentApiUrl: `${window.config.api.partsUri}/v1/fulfillment`,
}