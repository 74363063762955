import {PartNumberSearchService} from "./services/PartNumberSearchService";
import {PartNumberSearchResult} from "./models/PartNumberSearchResult";
import {Tracer} from "@api/common/Tracer";

export class PartNumberSearch {

    constructor(auth){
        this._auth = auth;
        this._service = new PartNumberSearchService(auth);
    }

    search(text){

        const vm = this;
        return new Promise((resolve, reject) => {

            if (!text || (text && text.length === 0)){
                resolve(new PartNumberSearchResult(0));
                return;
            }

            try {

                vm._service.search(text).then((res) => {

                    if (res == null) {
                        resolve(new PartNumberSearchResult(0));
                        return;
                    }

                    if (typeof res.count === 'undefined') {
                        resolve(new PartNumberSearchResult(0));
                        return;
                    }

                    const result = new PartNumberSearchResult(res.count);

                    res.items.forEach(function (item) {
                        if (item) {
                            result.appendResult(item.partNumber, item.description);
                        }
                    });

                    resolve(result);

                }).catch((rej) => {
                    Tracer.current.error('985564-(part_number_search): Error encounter when searching for part numbers.');
                    Tracer.current.error(rej);
                    reject(rej);
                });
            }
            catch(e){
                Tracer.current.error('4F8L2S7-(part_number_search): Internal Error');
                Tracer.current.error(e);
                reject(e);
            }

        });
    }

    searchDescription(text){

        const vm = this;
        return new Promise((resolve, reject) => {

            if (text == null){
                resolve([]);
                return;
            }

            if (text.length === 0)
            {
                resolve([]);
                return;
            }

            try {
                vm._service.searchDescriptions(text).then((obj) => {

                    if (obj == null) {
                        resolve(new PartNumberSearchResult(0));
                        return;
                    }

                    resolve(obj);

                }).catch((rej) => {
                    Tracer.current.error('4607747-(part_number_search): Error encounter when searching for part numbers.');
                    Tracer.current.error(rej);
                    reject(rej);
                });
            }
            catch(e){
                Tracer.current.error('80RC7I3-(part_number_search): Internal Error');
                Tracer.current.error(e);
                reject(e);
            }

        });
    }
}