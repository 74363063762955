const correlationId = "AER-OE"+Math.random().toString(36).substring(2, 9).toUpperCase();

window.config = {
  version: process.env.VUE_APP_VERSION,
  build: process.env.VUE_APP_CI_BUILD,
  buildDate: process.env.VUE_APP_CI_BUILD_DATE,
  buildStamp: process.env.VUE_APP_BUILD_STAMP,
  buildCommitHash: process.env.VUE_APP_COMMIT_HASH,
  environment: process.env.VUE_APP_ENVIRONMENT,
  isLocal: (process.env.VUE_APP_ENVIRONMENT === 'local'),
  isDebugMode: process.env.VUE_APP_IS_DEBUG_MODE,
  correlationId: correlationId,
  isIE11: false,
  aerCompanyId: process.env.VUE_APP_AER_COMPANY_ID,
  apsCompanyId: process.env.VUE_APP_APS_COMPANY_ID,
  excludeZeroInventoryBranches: process.env.VUE_APP_EXCLUDE_ZERO_INVENTORY_BRANCHES,
  appPrefix: process.env.VUE_APP_NAME_PREFIX,
  api: {
    authUri: process.env.VUE_APP_AUTH_URL,
    partsUri : process.env.VUE_APP_API_URL,
    errorsMessages: {
        '422' : 'Server Error: Please Try Again Later',
        '500' : 'Server Error: Please Try Again Later',
        '404' : 'Record not found',
        '401' : 'Session Expired. Please login back again.',
        '403' : 'Current logged user does not have rights for this action',
        generic : 'Server Error: Please Try Again Later'
    }
  },
  pdfjs: {
      statementsBaseUrl: process.env.VUE_APP_PDF_STATEMENTS_BASE_URL,
      materialsBaseUrl: process.env.VUE_APP_PDF_MATERIALS_BASE_URL,
      noFaultWarrantyUrl: process.env.VUE_APP_NO_FAULT_WARRANTY_PDF_URL,
  },
  messages: {
    error: '',
  },
  emergencyOrders: {
      branch: "10",
      maxQty: 1,
      companies: [
          process.env.VUE_APP_AER_COMPANY_ID
      ],
      inStockMessage: 'THIS PART IS IN STOCK. IT CANNOT BE ORDERED AS EMERGENCY ORDER',
  },
  userSettings : { },

};

window.setUserSettings = function(key, value){
    window.config.userSettings[key] = value;
};

window.getUserSettings = function(key){
    return window.config.userSettings[key];
};
