
//@fix: in future iteration, make this list configurable.
import {StoreProxy} from "@api/common/StoreProxy";
import _ from "lodash";
import {Tracer} from "@api/common/Tracer";

const MOPAR_BRANCHES = [
    'C10',
    'C20',
    'C24',
    'C30',
    'C31',
    'C33',
    'C35',
    'C36',
    'C37',
    'C38',
    'C39',
    'C40',
    'C41',
    'C42',
    'C45',
    'C46',
    'C47',
    'C48',
    'C49'];

export class CustomMessages {


    static backOrderRestrictedMessage($store){
        let profile = StoreProxy.getProfile($store);

        let homeBranch = profile.account.homeBranch;

        let isMopar = _.find(MOPAR_BRANCHES, function(o){
           return o === homeBranch;
        });

        if (isMopar){
            Tracer.current.debug("U6XVM-(backorder-msg): Setting up MOPAR back order restricted message");
            return "AER CANNOT ACCEPT MOPAR BACK ORDERS";
        }
        else {
            Tracer.current.debug(`616510-(backorder-msg): Setting up standard back order restricted message for ${homeBranch}`);
            return "CANNOT ACCEPT BACK ORDERS";
        }

    }
}

