export class Convert {

    constructor() {

    }

    /**
     * Convert a value to a string type
     * @param value
     * @returns {string|*}
     */
    static toString(value) {
        if (value == null) return '';
        return value;
    }

    /**
     * Convert a value to a boolean type
     */
    static toBoolean(value) {
        if (value == null) return false;

        if (typeof value == 'boolean') {
            return value;
        } else {
            return value === 'true';
        }
    }


    static toInt(value) {
        if (value == null) return 0;

        if (typeof value == 'number') {
            return value;
        }

        return Number.parseInt(value);
    }

    static isNumber(value) {
        if (value == null) {
            return false;
        }

        return typeof value == 'number';
    }

    static toFloat(value, decimal) {
        if (value == null) return 0;
        if (!decimal) {
            decimal = 2;
        }

        if (typeof value == 'number') {
            return value;
        }

        return Number.parseFloat(value).toFixed(decimal);
    }
}