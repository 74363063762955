import { render, staticRenderFns } from "./LanguageToggle.vue?vue&type=template&id=2c1c7b46&scoped=true&"
import script from "./LanguageToggle.js?vue&type=script&lang=js&"
export * from "./LanguageToggle.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c1c7b46",
  null
  
)

export default component.exports