import {SETTINGS_BACKORDER_SHOPPING_CART_MESSAGE_KEY} from '@/constants';
import _ from 'lodash';
import {ShoppingCart} from '@api/modules/shoppingCart/ShoppingCart';
import {PartLookupService} from '@api/modules/partCatalog/PartLookupService';
import {CompanySettings} from "@api/modules/userManagement/CompanySettings";
import WarrantyDetailsViewer from "../../../components/WarrantyDetailsViewer/WarrantyDetailsViewer.vue";
import {Tracer} from "@api/common/Tracer";

export default {
    name: "ShoppingCart",
    data() {
        return {
            _$shoppingCart: null,
            _$partLookupService: null,
            quantity: {},
            itemBranch: {},
            items: [],
            outOfStockMessage: '',
            viewState: {
                hasError: false,
                errorMessage: ''
            },

            warrantyDialog: false,
            warranties: [],
            warrantySelected: '',
            warrantyDetailsVisible: false
        }
    },
    components:{
        WarrantyDetailsViewer: WarrantyDetailsViewer
    },
    created(){

        this._$shoppingCart = new ShoppingCart(this.$store.state.auth, this.$store.state.profile.userId);
        this._$partsLookupService = new PartLookupService(this.$store.state.auth);

        let vm = this;

        this._$partsLookupService.getWarranties().then(
            warranties => {
                vm.warranties = warranties;
            }
        ).catch(e => {
            if (!e) {
                window.tracer.error(e);
            }
        });

        this._$shoppingCart.getItems(this.$store).then(items => {


            if (items == null){
                console.warn('[W144067]: shopping cart items is undefined!.');
                return;
            }

            vm.items = items;


            for(let i = 0; i < vm.items.length; i++){

                vm.$set(vm.quantity, vm.items[i].partNumber, new ItemQuantity(vm.items[i].partNumber, vm.items[i].qty));


                let assignedBranch = _.find(vm.items[i].branches, function(b) {
                    return b.assigned;
                });

                if (!assignedBranch){
                    console.warn('[w951934] Assigned branch is undefined!');
                }

                vm.$set(vm.itemBranch, vm.items[i].partNumber, new ItemBranch(vm.items[i].partNumber, assignedBranch));

            }


        }).catch(rej => {
            vm.items = [];
            vm.setError('[E039450] Error loading cart items.');
        });

        let warrantyOption = this._$shoppingCart.getWarranty(this.$store);
        if (warrantyOption != null){
            this.warrantySelected = warrantyOption;
            Tracer.current.debug('GDQLBF386-(shopping_cart): Selected warranty loaded from store.');
        }

    },
    mounted(){
        let companySettings = CompanySettings.getCompanyInstance(this.$store);
        this.outOfStockMessage = companySettings.getSetting(SETTINGS_BACKORDER_SHOPPING_CART_MESSAGE_KEY);
    },
    computed: {

        grandTotal: {
            get() {
                let total = 0;
                for (let i = 0; i < this.items.length; i++) {
                    total = total + this.items[i].total;
                }

                return total;
            }
        },
        hasWarrantyBeenSelected: {
            get() {
                return this.warrantySelected != null && this.warrantySelected !== '';
            }
        },
        showWarrantySelected: {
            get() {
                return this.warrantySelected != null && this.warrantySelected !== '' && this.warrantySelected.id !== '';
            }
        }
    },
    methods:{

        onQuantityChanged(partNumber){
            let vm = this;

            this.quantity[partNumber].commit(this._$shoppingCart, this.$store).then(success =>{

                vm.$notify({
                    title: `Part ${partNumber} quantity updated.`,
                    type: 'Info'
                });

            }).catch(rej => {
                Tracer.current.error('[W684976]: Quantity update seems to be have failed. ');
                Tracer.current.error(rej);
            });


        },
        onDeleteItem(partNumber){

            let vm = this;
            this.$confirm(`Part ${partNumber} will be removed from your cart. do you want to continue?`, 'Shopping Cart Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this._$shoppingCart.removeItem(this.$store, partNumber).then(res => {
                    delete this.quantity[partNumber];

                    vm.$notify({
                        title: `Part ${partNumber} removed.`,
                        type: 'Info'
                    });

                }).catch(rej => {
                    console.warn('[W617807]: Item failed to be removed.');
                });

            }).catch(() => {
                console.debug("[D372906] User cancelled item removal for part "+partNumber);
            });
        },
        totalStock(branches){

            if (branches == null)return 0;

            let stock = 0;
            for(let i = 0; i < branches.length; i ++){
                stock = stock + branches[i].qty;
            }

            return stock;

        },
        onBranchChange(partNumber){

            if (this.itemBranch[partNumber] == null){
                window.tracer.error(`[E700139]: part number ${partNumber} not found on collection tracking the branches.`);

                this.$notify.error(`[E351588] Internal Error: Unable to update branch for ${partNumber}`);
                return;
            }

            let selectedBranch = this.itemBranch[partNumber];
            let informUser = this.informUserAboutAvailabilityInDifferentBranch(partNumber, selectedBranch.selectedBranchId);

            if (informUser){
                let vm = this;
                this.notifyUserAboutAvailabilityInDifferentBranch().then(resolve => {
                    this.commitSelectedBranch(partNumber, selectedBranch);
                }).catch(rej => {
                    selectedBranch.revert();
                });
            }
            else{
                this.commitSelectedBranch(partNumber, selectedBranch);
            }

        },
        informUserAboutAvailabilityInDifferentBranch(partNumber, branchId){

            if (!partNumber){
                throw new Error('[E015030] PartNumber argument is undefined')
            }

            if (!branchId){
                throw new Error('[E058470] Branch Id argument is undefined!')
            }

            let part = _.find(this.items, function(i){
               return i.partNumber === partNumber;
            });

            if (part == null){
                window.tracer.error(`[E544661]: Part ${partNumber} was not found in the shopping cart item collection!`);
                return false;
            }

            let selectedBranch = _.find(part.branches, function(b){
               return b.id === branchId;
            });

            if (selectedBranch == null){
                window.tracer.error(`[E923926]: Selected branch ${branchId} not found in the branches list for part ${partNumber}`);
                return false;
            }

            let stockInDifferentBranch = false;

            if (selectedBranch.qty > 0){
                return false;
            }

            part.branches.forEach((branch) =>{
                if (branch.qty > 0 && selectedBranch.id !== branch.id){
                    stockInDifferentBranch = true;
                }
            });

            return stockInDifferentBranch;

        },
        notifyUserAboutAvailabilityInDifferentBranch(){

            let vm = this;
            return new Promise((resolve, reject) => {

                const h = vm.$createElement;
                this.$msgbox({
                    title: 'Warning',
                    message: h('div', {class: 'warning-branch-confirmation-dialog'}, [
                        h('div', null, 'This part is out of stock at the location selected. It is in stock at a different location.'),
                        h('div', {style: 'font-weight: bold; margin-top: 5px;'}, 'Do you want to continue?')
                    ]),
                    confirmButtonText: 'Yes, Continue',
                    cancelButtonText: 'No, Cancel',
                    showCancelButton: true,
                    type: 'warning',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            resolve();
                            done();
                        } else {
                            reject();
                            done();
                        }
                    }
                });
            });
        },
        commitSelectedBranch(partNumber, selectedBranch){
            let vm = this;

            selectedBranch.commit(this._$shoppingCart, this.$store).then(success =>{

                vm.$notify({
                    title: `Part ${partNumber} branch updated!.`,
                    type: 'Info'
                });

                console.debug('[d359775]: Branch successfully committed.');

            }).catch(rej => {
                window.tracer.error('[W479385]: Branch update seems to be have failed. ');
                if (rej) {
                    window.tracer.error(rej);
                }
            });
        },
        onWarrantySelected(item){
            this.warrantyDialog = false;
            console.debug(`[d566085] warranty with id of: '${item.id}' selected`);
            if (item.id !== '') {
                this._$shoppingCart.addWarranty(this.$store, item);
                this.$router.push("/place-order");
            }
            else{
                this._$shoppingCart.addWarranty(this.$store, '');
                this.$router.push("/place-order");
            }

            console.debug(`[d519879] Warranty ${item.desc} selected`);
        },
        onWarrantyChange(){
            this.warrantyDialog = true;
        },
        onWarrantyRemove(){
            this.warrantySelected = '';
            this._$shoppingCart.addWarranty(this.$store, '');
        },
        onWarrantyDialogClose(){
           console.debug('[d709730] Warranty dialog closed');
        },
        onPlaceOrder(){

            if (this.warranties.length > 0 && !this.hasWarrantyBeenSelected){
                this.warrantyDialog = true;
            }
            else {
                this.$router.push("/place-order")
            }
        },
        setError(msg){
            this.viewState.hasError = true;
            this.viewState.errorMessage = msg;
        },
        openNoFaultWarrantyDetails(){
            this.warrantyDetailsVisible = true
        },
        closeNoFaultWarrantyDetails(){
            this.warrantyDetailsVisible = false
        }
    }
}

class ItemBranch {
    constructor(partNumber, branch){

        this.partNumber = partNumber;
        this._branchId = '';
        if (branch) {
            this._branchId = branch.id;
        }

        this._selectedBranchId = this._branchId;
        this.timeoutHandle = 0;

    }

    set branchId(selectedBranch){

       this._selectedBranchId = selectedBranch;

    }

    get selectedBranchId(){
        return this._selectedBranchId;
    }

    get branchId() {
       return this._branchId;
    }

    revert(){
        this._selectedBranchId = this._branchId;
    }

    commit(shoppingCart, $store){

        if (this.timeoutHandle !== 0){
            clearTimeout(this.timeoutHandle);
        }

        let vm = this;
        return new Promise((resolve, reject) => {

            let partNumber = this.partNumber;
            let branchId = this._selectedBranchId;

            vm.timeoutHandle = setTimeout(function(){

                let updatePromise = shoppingCart.changeBranch($store, partNumber, branchId);

                updatePromise.then(result => {
                    vm._branchId = branchId;
                    console.debug(`[d631685]: Branch update to ${vm._branchId}`);
                    resolve();
                }).catch(rej => {
                    window.tracer.error('[W661464]: Failed to update branch on item.');
                    if (rej) {
                        window.tracer.error(rej);
                    }

                    reject();
                });

            }, 600);

        });

    }

}



class ItemQuantity {

    constructor(partNumber, quantity){

        this.partNumber = partNumber;
        this._internalQuantity = quantity;
        this._savedQuantity = quantity;
        this.timeoutHandle = 0;
    }

    get quantity(){
        return this._savedQuantity;
    }

    set quantity(value){
       this._internalQuantity = value;
    }

    commit(shoppingCart, $store){

        if (this.timeoutHandle !== 0){
            clearTimeout(this.timeoutHandle);
        }

        return new Promise((resolve, reject) => {

            let partNumber = this.partNumber;
            let quantity = this._internalQuantity;

            let vm = this;
            this.timeoutHandle = setTimeout(function(){

                let updatePromise = shoppingCart.updateQuantity($store, partNumber, quantity);

                updatePromise.then(result => {
                    vm._savedQuantity = quantity;
                    resolve();
                }).catch(rej => {
                    window.tracer.error('[W392288]: Failed to update Item');
                    if (rej) {
                        window.tracer.error(rej);
                    }

                    reject();
                });

            }, 600);

        });

    }

}