import axios from 'axios';
import {HttpUtils} from '@/api/common/HttpUtils';
import {MemoryCache} from "@/api/common/MemoryCache";
import {AccountInfo} from "@/api/modules/userManagement/models/AccountInfo";
import {Tracer as Trace, Tracer} from "@api/common/Tracer";
import {Convert} from "@api/common/Convert";


/**
 * Connects to the Order Entry API: <code>/v1/accounts</code><br><br>
 *
 * This class is to be used internaly within the UserManagement modules (folder).
 */

export class UserManagementService {

    /**
     * Constructor
     * @param auth - jwt token.
     */
    constructor(auth){

        if (auth == null){
            throw new Error("[e966277] auth missing.");
        }

        this._baseUrl = `${window.config.api.partsUri}/v1/accounts`;
        this._headers = HttpUtils.getHttpHeaders(auth, 'text/json');
        this._auth = auth;
    }



    getCompanySettings(companyId, data){

        if (!companyId){
            throw new Error('[e245315] company Id is not defined!.');
        }

        let serviceUrl = `${this._baseUrl}/companies/${companyId}/settings`;

        axios.get(serviceUrl, {headers: this._headers}).then(response => {
            if (data){
                data(response.data);
            }
            else{
                throw new Error("[e489419] Expected a data callback function");
            }
        }).catch( e => {

            data([]);
            let is404 = HttpUtils.handled404(e, func => {success()});

            if (!is404) {
                HttpUtils.handleError(e, serviceUrl);
            }
        });
    }

    /**
     * Returns the available credit for current user
     * @param userId
     * @returns {Promise | Promise<number>}
     */
    getUserCredit(userId){

        if (!userId){
            throw new Error('[e118349] userId missing.');
        }

        let url = `${this._baseUrl}/users/${userId}/credit`;
        return HttpUtils.get(url, this._auth);
    }

    /**
     * Returns the available credit for current user
     * @param userId
     * @returns {Promise | Promise<number>}
     */
    getAvailableCredit(){

        let url = `${this._baseUrl}/users/current/available-credit`;
       return new Promise((resolve, reject) => {

           HttpUtils.get(url, this._auth).then(data => {
              if (data == null){
                  resolve(0);
              }
              else{
                  let value = Convert.toFloat(data, 2);
                  resolve(value);
              }
           }).catch(e => {
               Tracer.current.error("#3B1BIJ:!GET_AVAILABLE_CREDIT!: Error while access API for getting the available credit for this account");
               Tracer.current.error(e);
           })

       });
    }

    /**
     * Change user's password
     * @param currentPassword
     * @param newPassword
     * @returns {Promise<unknown>}
     */
    changePassword(currentPassword, newPassword){


        if (currentPassword  == null || currentPassword.length === 0){
            throw new Error('023344-(change_password): Current password cannot be an empty string');
        }

        if (newPassword == null || newPassword.length === 0){
            throw new Error ('810014-(change_password): New password cannot be an empty string' )
        }


        return new Promise((resolve, reject) => {

            let url = `${this._baseUrl}/users/current/change-password`;
            let payload = {
                password: currentPassword,
                newPassword:  newPassword
            };

            HttpUtils.put(url, this._auth, payload).then(data => {

                Trace.current.info("406398-(change_password): Password updated.");
                resolve(true);

            }).catch(error => {

                    if (error != null) {

                        if (error.response.status === 401)
                        {
                            Trace.current.debug("6VT52N-(change_password): Wrong Password");
                            resolve(false)
                        }
                        else {

                            Trace.current.debug("3CTIJU-(change_password): Error updating user's password");
                            window.tracer.error(error);
                            reject(error)
                        }
                    } else {
                        reject();
                    }
                }
            );

        } );

    }

    /**
     * search accounts. This is meant to be used with the autocompletion
     * @param searchString
     * @returns {Promise<string[]>}
     */
    searchAccounts(searchString){
        return new Promise((resolve, reject) => {

            if (searchString == null || searchString.length === 0){
                resolve([]);
                return;
            }

            const onlyNumbers = /^\d+$/;

            if (!onlyNumbers.test(searchString)){
                resolve([]);
                return;
            }

            let url = `${this._baseUrl}/search?q=${searchString}`;

            if (MemoryCache.instance.contains(url)){
                resolve(MemoryCache.instance.get(url));
                window.tracer.debug(`[d119199] Account list retrieved from cache for key ${url}`)
                return;
            }

            HttpUtils.get(url, this._auth).then(data => {

                if (data == null){
                    MemoryCache.instance.set(url, []);
                    resolve([]);
                    return;
                }

                if (data === true){
                    MemoryCache.instance.set(url, []);
                    resolve([]);
                    return;
                }

                if (data.length > 0){
                    MemoryCache.instance.set(url, data);
                    resolve(data);
                }
                else{
                    MemoryCache.instance.set(url, []);
                    resolve([]);
                }

            }).catch(e => {
                window.tracer.error("[e689715] Error while query server for accounts")
                if (e != null) {
                    window.tracer.error(e);
                }

                reject(e);
            });

        });
    }

    /**
     * Impersonate an account
     * @param account
     * @returns {Promise<()>}
     */
    impersonateAccount(account){
        return new Promise((resolve, reject) => {
            try{
                if (account == null){
                    reject();
                }

                let url = `${this._baseUrl}/users/current/impersonate-account/${account}`;

                HttpUtils.put(url, this._auth, null).then(data =>{
                   if (data === true || data === "OK"){
                       window.tracer.debug(`[d971425] Succesfully Impersonated account ${account}`);
                       resolve();
                        return;
                   }

                   if (data === false){
                       reject();

                   }


                }).catch(e => {

                    window.tracer.error("[e842349] Server Error when attempting to impersonate account");
                    if (e != null) {
                        window.tracer.error(e);
                    }
                    reject(e);

                });
            }
            catch(e){

                window.tracer.error("[e232963] Error when impersonating account")
                window.tracer.error(e);
                reject(e);
            }

        });

    }

    getCurrentAccount(){
        return new Promise((resolve, reject) => {
            try{
                let url = `${this._baseUrl}/users/current/account`;
                HttpUtils.get(url, this._auth).then(data => {

                    if (data != null){
                        let accountInfo = new AccountInfo();
                        accountInfo._name = data.billingAddress.customerName;
                        accountInfo._address1 = data.billingAddress.address1;
                        accountInfo._address2 = data.billingAddress.address2;
                        accountInfo._city = data.billingAddress.city;
                        accountInfo._state = data.billingAddress.state;
                        accountInfo._zipCode= data.billingAddress.zipCode;

                        resolve(accountInfo);
                    }
                    else{
                        reject();
                    }

                }).catch(e => {
                    window.tracer.error("[e120327] Server error when access the account information for current user");
                    if (e != null) {
                        window.tracer.error(e);
                    }
                    reject(e);
                });
            }
            catch(e){

                window.tracer.error("[e272111] Error getting current account info.")
                window.tracer.error(e);
                reject(e);
            }

        });
    }

}


