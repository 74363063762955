export class Utils{

    constructor()
    {

    }

    static isAER($store){

        if ($store == null)return false;
        if ($store.state.profile == null)return false;
        return ($store.state.profile.companyId === window.config.aerCompanyId);
    }

    static isAPS($store){

        if ($store == null)return false;
        if ($store.state.profile == null)return false;
        return ($store.state.profile.companyId === window.config.apsCompanyId);
    }
}