import {OrderSearchBase} from "./OrderSearchBase";
import {OrderHistoryService} from './services/OrderHistoryService';
import {SearchResults} from "@api/modules/orderHistory/SearchResults";
import {Credit} from "@api/modules/orderHistory/models/Credit";


export class CreditsSearch extends  OrderSearchBase {
    constructor(auth){
        super();
        this._service = new OrderHistoryService(auth);
    }

    search(searchParams){
        return this._service.searchCredits(searchParams);
    }

    // override parent (base class)
    _transformResults(data){

        if (data == null) {
            return new SearchResults(0, 0, 0, 0);
        }

        let orders = new SearchResults(data.page, data.count, data.totalPages, data.totalRecords);

        if (!data.items){
            throw new Error('[err-343700] Expected `items` missing. Check API response.');
        }

        data.items.forEach(function (item) {

            let tmpOrder = Credit.Create(item);
            orders.setItems(tmpOrder);

        });

        return orders;
    }

}