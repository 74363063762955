/***
 * Represents a collection (list) of search results.
 */
export class SearchResults {

    constructor(page, count, totalPages, totalRecords){
        this._count = count;
        this._page = page;
        this._totalPages = totalPages;
        this._totalRecords = totalRecords;
        this._items = [];
    }

    /**
     * Gets the number of items
     * @returns {*}
     */
    get count(){
        return this._count;
    }

    /**
     * gets the current page number.
     * @returns {*}
     */
    get page(){
        return this._page;
    }

    /**
     * Gets the total number of pages the search returned
     * @returns {*}
     */
    get totalPages(){
        return this._totalPages;
    }

    /**
     * Gets the total of records that the search returned
     * @returns {*}
     */
    get totalRecords(){
        return this._totalRecords;
    }

    /**
     * Get the items of the list
     * @returns {[]}
     */
    get items(){
        return this._items;
    }

    /**
     * Sets an item to the result list.
     * @param item
     */
    setItems(item){

        if (!item){
            throw new Error('[err-534143] item is not defined.')
        }

        this._items.push(item)
    }


}