/**
 * Represents the dealer account profile
 */
export class DealerAccountProfile {

    constructor() {
        this._homeBranch = '';
        this._accountNumber = '';
        this._dealerCode = '';
        this._roles = [];
        this._backOrderRestricted = false;
    }

    get homeBranch() {
        return this._homeBranch;
    }

    set homeBranch(value) {
        this._homeBranch = value;
    }

    get accountNumber() {
        return this._accountNumber;
    }

    set accountNumber(value) {
        this._accountNumber = value;
    }

    get roles() {
        return this._roles;
    }

    set roles(value) {
        this._roles = value;
    }

    get backOrderRestricted() {
        return this._backOrderRestricted;
    }

    set backOrderRestricted(value) {
        this._backOrderRestricted = value;
    }

    get dealerCode() {
        return this._dealerCode;
    }

    set dealerCode(value) {
        this._dealerCode = value;
    }



}