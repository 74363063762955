export class MimeTypeMapper {

    static getIconType(mimeType){
        switch (mimeType){
            case 'application/pdf':
            case 'application/x-pdf':
                return 'file_type_pdf@3x.png';
            case 'image/png':
            case 'image/jpg':
            case 'image/jpeg':
            case 'image/gif':
            case 'image/apng':
                return 'file_type_image@3x.png';
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'file_type_word@3x.png';
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'file_type_excel@3x.png';
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return 'file_type_powerpoint@3x.png';
            case 'application/zip':
                return 'file_type_archive@3x.png';
            case 'text/plain':
                return 'file_type_text@3x.png';
            default:
                return 'file_type_default@3x.png';

        }
    }

    static getLabelType(mimeType){
        switch (mimeType){
            case 'application/pdf':
            case 'application/x-pdf':
                return 'PDF Document';
            case 'image/png':
            case 'image/jpg':
            case 'image/jpeg':
            case 'image/gif':
            case 'image/apng':
                return 'Image';
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'Word Document';
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'Excel File';
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return 'Power Point File';
            case 'application/zip':
                return 'Zip File';
            case 'text/plain':
                return 'Text File';
            default:
                return '';

        }
    }

    static isImage(mimeType){
        switch (mimeType){
            case 'image/png':
            case 'image/jpg':
            case 'image/jpeg':
            case 'image/gif':
            case 'image/apng':
                return true;
            default:
                return false;

        }
    }

    static isPdf(mimeType){
        switch (mimeType){
            case 'application/pdf':
            case 'application/x-pdf':
                return true;
            default:
                return false;

        }
    }
}