import {StoreProxy} from "@/api/common/StoreProxy";
import {ShoppingCart} from "@/api/modules/shoppingCart/ShoppingCart";
import {CompanySettings} from "@/api/modules/userManagement/CompanySettings";
import {UserManagement} from "@/api/modules/userManagement/UserManagement";
import {Credit} from "@/api/modules/userManagement/models/Credit";
import {SessionCheckResult} from "@/api/modules/auth/models/SessionCheckResult";
import {HttpUtils} from "@/api/common/HttpUtils";
import {Tracer} from "@api/common/Tracer";
import {DateTime} from 'luxon';

// module imports
import {_AuthHttpService} from "@/api/modules/auth/_httpService/_AuthHttpService";
import posthog from 'posthog-js';
import {Analytics} from "@/lib/analytics/Analytics";


export class Authentication {

    constructor(){}

    static revalidate($store) {

        if ($store == null){
            throw new Error("898560-(revalidate_session): $store is required to revalidate");
        }

        try {
            return new Promise((resolve, reject) => {

                const service = new _AuthHttpService();

                service.revalidate().then(authResult => {

                    Tracer.current.tag("sessionId", authResult.sessionId);

                    StoreProxy.setLogin($store, authResult.jwt, authResult.sessionId);

                    StoreProxy.reload(authResult, $store).then((result) => {

                        let profile = null;

                        if (result === true) {
                            profile = StoreProxy.getProfile($store);
                        }

                        if (result === false || profile == null) {

                            Authentication.loadProfile($store).then(() => {

                                Tracer.current.debug("156795-(load_profile): Retrieving the profile (full retrieval)");
                                resolve();

                            }).catch(e => {

                                Tracer.current.error("181413-(load_profile): Error loading the profile (full retrieval)");
                                Tracer.current.error(e);

                                reject(e);
                            });


                        } else {

                            Tracer.current.debug("306283-(revalidate-session): User profile retrieved from the store");
                            resolve();
                        }


                    }).catch(e => {

                        Tracer.current.error("764044-(revalidate-session): failure to reload the profile from the store.")
                        Tracer.current.error(e);

                        StoreProxy.setLogout($store);

                        reject(e);
                    });

                }).catch(rej => {

                    Tracer.current.debug("89F0SZ-(revalidate-session): Session expired!");

                    if (rej.status === 500) {
                        Tracer.current.error(rej, {message: "89F0SZ-(revalidate-session): Session expired!"});
                    }

                    StoreProxy.setLogout($store);
                });

            });
        }
        catch(ex) {
            Tracer.current.error("G6ODSR-(revalidate-session): General runtime error.");
            Tracer.current.error(ex);
        }

    }

    /**
     * Load Current User Profile
     * @param $store
     * @returns {Promise<()>}
     */
    static loadProfile($store) {

        if ($store == null){
            throw new Error("185177-(load_profile): the argument $store is expected");
        }

        return new Promise((resolve, reject) => {

            const service = new _AuthHttpService();
            const auth = StoreProxy.getAuth($store);

            service.getProfile(auth).then(profile => {

                $store.commit("setProfile", profile);

                Analytics.current.identifyUser({
                   userId: profile.userId,
                   account: profile.customerNumber,
                   name: profile.name,
                   sessionId : auth.sessionId,
                   branch:  profile.account.homeBranch
                });


                // load shopping cart from server.
                StoreProxy.shoppingCartLoading($store);
                let shoppingCart = new ShoppingCart(auth, profile.userId);

                shoppingCart.load($store).then(res => {
                    StoreProxy.shoppingCartLoaded($store, true);
                }).catch(rej => {
                    StoreProxy.shoppingCartLoaded($store, false);
                    Tracer.current.error("325443-(load_profile): Fail to load user's shopping cart");
                    Tracer.current.error(rej);
                });

                const companySettings = new CompanySettings(auth, profile.companyId);

                companySettings.load().then(() => {
                    try {
                        StoreProxy.setCompanySettings($store, companySettings);
                    } catch (err) {
                        Tracer.current.error(err);
                    }
                });

                if (!profile.restricted) {
                    const userManagement = new UserManagement(auth);

                    userManagement.getUserCredit(profile.userId).then(credit => {

                        StoreProxy.setCredit($store, credit);
                        if (!credit.hasCredit) {
                            StoreProxy.setCreditAlertVisibility($store, true);
                        } else {
                            StoreProxy.setCreditAlertVisibility($store, false);
                        }
                        Tracer.current.debug('d287946-(load_profile): credit info loaded into the vuex store');


                    }).catch(error => {

                        StoreProxy.setCredit($store, Credit.empty());
                        Tracer.current.error('680802-(load_profile): Failure get user\' credit info.');
                        Tracer.current.error(error);

                    });
                } else {
                    Tracer.current.info("277546-(load_profile): Logged user is restricted!");
                }

                resolve();


            }).catch(e => {

                StoreProxy.setLogout(this.$store);

                Tracer.current.error('441624-(load_profile): Failure to get user profile');
                Tracer.current.error(e);

                reject(e);

            });

        });
    }

    /**
     * Logins user.
     * @param $store - The store where the login information will be stored during the session
     * @param userId - user id
     * @param password - password
     * @returns {Promise<()>}
     */
    static login($store, userId, password){

        return new Promise((resolve, reject) => {

            const service = new _AuthHttpService();

            service.login(userId, password).then(authResult => {

               StoreProxy.setLogin($store, authResult.jwt, authResult.sessionId);

               Authentication.loadProfile($store).then( () => {

                   Tracer.current.debug("370795-(auth): Profile loaded");
                   resolve();

               }).catch(err => {

                   Tracer.current.error(`854980-(auth): There seems to be a problem loading the profile for this user. UserId: ${userId}`);
                   StoreProxy.setLogout($store);
                   reject(err);

               })

            }).catch(e => {

                Tracer.current.error(`605613-(auth): Error when attempted authentication for user ${userId}`);
                Tracer.current.error(e);

                StoreProxy.setLogout($store);

                reject(e);
            });
        });
    }

    static AuthJwt($store, auth){

        return new Promise((resolve, reject) => {

            const service = new _AuthHttpService();

            service.authJwt(auth).then(authResult => {

                StoreProxy.setLogin($store, authResult.jwt, authResult.sessionId);

                Authentication.loadProfile($store).then( () => {

                    Tracer.current.debug("CAN8259-(auth_jwt): Profile loaded");
                    resolve();

                }).catch(err => {

                    Tracer.current.error(`DK39HL6-(auth_jwt): There seems to be a problem loading the profile.`);
                    StoreProxy.setLogout($store);
                    reject(err);

                })

            }).catch(e => {

                Tracer.current.error(`390WCN1-(auth): Error when authenticating user with provided jwt.`);
                Tracer.current.error(e);

                StoreProxy.setLogout($store);

                reject(e);
            });
        });
    }

    static logout($store){
        return new Promise((resolve, reject) => {

            const auth = StoreProxy.getAuth($store);

            if (auth == null){
                resolve();
                return;
            }

            const service = new _AuthHttpService();
            service.logout(auth).then(() => {
                HttpUtils.deleteCookie(window.$instance.sessionCookieName);

                Analytics.current.exit()
                resolve();

            }).catch(e => {
                Tracer.current.error(e);
                reject(e);
            });
        });
    }

    static checkSession($store) {

        const userId = StoreProxy.getUserId($store);
        const auth = StoreProxy.getAuth($store);

        return new Promise((resolve, reject) => {

            if (userId == null || userId.length === 0) {
                resolve(SessionCheckResult.noAuthenticated());
            }

            if (auth == null){
                resolve(SessionCheckResult.noAuthenticated());
            }

            const service = new _AuthHttpService();


            service.checkSession(userId, auth).then((result) => {

                if (result.hasWarning() || result.isMaintenance()) {

                    service.maintenanceInformation().then(info => {

                        if (info.status === "On" || info.status === "Warning") {

                            const status = info.status;
                            const start = DateTime.fromISO(info.start).toLocal();
                            const end = DateTime.fromISO(info.end).toLocal();
                            const warnTime = info.warnTime;

                            $store.commit("setMaintenance", {
                                status,
                                start,
                                end,
                                warnTime
                            });

                        }
                    })
                }

                resolve(result);

            }).catch( (e) => {

                Tracer.current.error("779779-(auth): Error when checking for the current session.");
                Tracer.current.error(e);
            })
        });
    };

}