import {NotificationTypes} from "@api/modules/dealerAdmin/models/NotificationTypes";
import {Tracer} from "@api/common/Tracer";
import {AppConfig} from "@/config/AppConfig";
import {HttpUtils} from "@api/common/HttpUtils";
import {StringUtils} from "@api/common/StringUtils";
import {Convert} from "@api/common/Convert";
import {NotificationItem} from "@api/modules/dealerAdmin/models/NotificationItem";

/**
 * Communicates with the API.
 *
 * **Internal to this module. Do not access externally**
 */
export class _DealerAdminHttpService {

    constructor(auth){
        this._auth = auth;
    }

    /**
     * Retrieves from the server the email notifications for an specific type.
     * @param account The dealer account
     * @param type The notification type
     * @returns {Promise<NotificationItem[]>}
     */
    getEmailNotifications(account, type){

        if (StringUtils.isEmptyOrNull(type)){
            throw new Error("[e-758853] The type of email notification cannot be null");
        }

        try{
            let notificationType = this._mapType(type);

            return new Promise((resolve, reject) => {

                let url = `${AppConfig.current.ApiUrl}/v1/accounts/dealers/${account}/notification-emails/${notificationType}`;

                HttpUtils.get(url, this._auth).then(data => {

                    if (data == null){
                        resolve([]);
                        return;
                    }

                    let emails = [];
                    for(let i = 0; i < data.length; i++){

                        if (data[i].account !== account){
                            // should not happen, but if does, ignore it.
                            continue;
                        }

                        let tmpEmail = data[i].email;
                        let tmpSequence = Convert.toInt(data[i].sequence);

                        if (StringUtils.isEmptyOrNull(tmpEmail) || tmpSequence === 0){
                            continue;
                        }

                        let tmpEmailNotification = new NotificationItem(tmpEmail, tmpSequence, type);
                        emails.push(tmpEmailNotification);
                    }

                    resolve(emails);

                }).catch(ex => {
                    Tracer.current.error("[e-488740] Error communicating with server to retrieve the email notifications");
                    Tracer.current.error(ex);
                    reject(ex);
                });

            });
        }
        catch(e){
            Tracer.current.error("[e-904528] Error retrieving email notification accounts");
            Tracer.current.error(e);
        }
    }

    addEmailNotification(account, type, email){


        if (StringUtils.isEmptyOrNull(account)){
            throw new Error("[e-915428] account cannot be empty");
        }

        if (StringUtils.isEmptyOrNull(email)){
            throw new Error("[e-453387] email cannot be empty");
        }

        try {

            let notificationType = this._mapType(type);
            return new Promise((resolve, reject) => {
                let url = `${AppConfig.current.ApiUrl}/v1/accounts/dealers/${account}/notification-emails/${notificationType}`;

                let obj = {
                    email: email,
                }

                HttpUtils.post(url, this._auth, obj).then( (data) => {
                    if (data){
                        resolve();
                    }
                    else{
                       Tracer.current.warning("[e-319027] Failed to add a new email.");
                       reject();
                    }

                }).catch(ex => {
                    Tracer.current.error("[e-386409] Error communicating with server to add a new email notification to the list");
                    Tracer.current.error(ex);
                    reject(ex);
                })
            });

        }
        catch(e){
            Tracer.current.error("[e-578470] Error adding a new email notifications. Runtime Error");
            Tracer.current.error(e);
        }
    }

    updateEmailNotification(account, type, email, sequence){

        if (StringUtils.isEmptyOrNull(account)){
            throw new Error("[e-915428] account cannot be empty");
        }

        if (StringUtils.isEmptyOrNull(email)){
            throw new Error("[e-453387] email cannot be empty");
        }

        if (sequence == null){
            throw new Error("[e-414986] sequence cannot be empty");
        }

        try {
            let notificationType = this._mapType(type);
            let _tmpSequence = Convert.toInt(sequence);
            return new Promise((resolve, reject) => {
                let url = `${AppConfig.current.ApiUrl}/v1/accounts/dealers/${account}/notification-emails/${notificationType}`;

                let obj = {
                    email: email,
                    sequence: _tmpSequence
                }

                HttpUtils.put(url, this._auth, obj).then( (data) => {
                    if (data){
                        resolve();
                    }
                    else{
                        Tracer.current.warning("[e-653005] Failed to update email.");
                        reject();
                    }
                }).catch(ex => {
                    Tracer.current.error("[e-323102] Error communicating with server to change the email.");
                    Tracer.current.error(ex);
                    reject(ex);
                })
            });
        }
        catch(e){
            Tracer.current.error("[e-449722] Error updating email notification. Runtime Error");
            Tracer.current.error(e);
        }
    }

    removeEmailNotification(account, type, sequence){

        if (StringUtils.isEmptyOrNull(account)){
            throw new Error("[e-872895] account cannot be empty");
        }

        if (sequence == null){
            throw new Error("[e-929610] sequence cannot be empty");
        }

        try {

            let notificationType = this._mapType(type);
            let _tmpSequence = Convert.toInt(sequence);
            return new Promise((resolve, reject) => {
                let url = `${AppConfig.current.ApiUrl}/v1/accounts/dealers/${account}/notification-emails/${notificationType}/${_tmpSequence}`;

                HttpUtils.delete(url, this._auth).then( (data) => {
                    if (data){
                        resolve();
                    }
                    else{
                        Tracer.current.warning("[e-728162] Failed to delete email.");
                        reject();
                    }
                }).catch(ex => {
                    Tracer.current.error("[e-298921] Error communicating with server to change the email.");
                    Tracer.current.error(ex);
                    reject(ex);
                })
            });
        }
        catch(e){
            Tracer.current.error("[e-449722] Error updating email notification. Runtime Error");
            Tracer.current.error(e);
        }
    }

    _mapType(type){
        switch(type){
            case NotificationTypes.INVOICE:
                return 'InvoiceNotification';
            case NotificationTypes.ORDER:
                return 'OrderNotification';
            case NotificationTypes.SHIPPING:
                return 'ShippingNotification';

            default:
                return '';
        }
    }

}

