/**
 * Represents the user's credit
 *
 * This class is designed to be immutable.
 */
export class Credit{

    /**
     * @param limit
     * @param balance
     * @param availableCredit
     */
    constructor(availableCredit, limit, balance){

        if (availableCredit == null){
            throw new Error('[e237979] available credit argument is require!')
        }

        this._availableCredit = availableCredit;
        this._limit = (!limit) ? limit : -1;
        this._balance = (!balance) ? balance : -1;

    }

    /**
     * Returns the credit limit.
     * @returns {number}
     */
    get limit(){
        return this._limit;
    }

    /**
     * ** Do not use **
     * It will not set the value.
     * @param value
     */
    set limit(value){
        // this object is immutable.
    }

    /**
     * Returns the credit balance
     * @returns {number}
     */
    get balance(){
        return this._balance;
    }

    set balance(value){
        // this object is immutable.
    }

    /**
     * Returns the availableCredit credit.
     * @returns {number}
     */
    get availableCredit(){
        return this._availableCredit;
    }

    set availableCredit(value){
        // this object is immutable.
    }

    /**
     * Returns true if the user has any credit, otherwise false.
     * @returns {boolean}
     */
    get hasCredit(){
       // return false;

        return this._availableCredit > 0;
    }

    /**
     * Returns true if this instance is a valid instance, meaning
     * that is contains real user's values.
     * @returns {boolean}
     */
    get isValidInstance(){
        return this._availableCredit  !== -1;
    }

    /**
     * Checks if a specific value is withing the purchase power of the user
     * @param price
     * @returns {boolean}
     */
    hasEnoughCredit(price){
       //return false;
         return this._availableCredit >= price;
    }

    /**
     * Returns an empty credit object.
     * @returns {Credit}
     */
    static empty(){
        return new Credit(-1, -1, -1);
    }
}