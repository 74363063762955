import {StoreProxy} from "@api/common/StoreProxy";
import {Tracer} from "@api/common/Tracer";
import {PartNumberSearch} from "@api/modules/partNumberSearch/PartNumberSearch";

/**
 * Part Number Search Component.
 *
 * @internal - Only to be used on the PartCatalog View.
 */

const AUTOCOMPLETE_ENABLED = false;

export default {
    name: "PartNumberSearch",
    props: {
        partNumber: {
            type: String,
            default: ''
        },

    },
    created(){
        this._partsSearch = new PartNumberSearch(StoreProxy.getAuth(this.$store));
    },
    mounted(){
    },
    computed: {
    },
    data() {
        return {
            text: '',
            autocompleteEnable: AUTOCOMPLETE_ENABLED,
        }
    },
    watch: {
      partNumber() {
          this.text = this.partNumber;
      }
    },
    methods: {

        /**
         *
         * @param {string} q - the text which the part will be search
         * @param {function([])} cb
         */
        search(q, cb) {

            if (q != null && q.length > 2) {

                this._partsSearch.searchDescription(q).then(data => {

                    if (data == null || data.length === 0) {
                        cb([]);
                        return;
                    }

                    cb(data.items);

                }).catch(err => {
                    cb([]);

                    Tracer.current.error('FR664R-(part_catalog::part_number_search): Internal error (possible server) for part autocomplete');
                    Tracer.current.error(err);
                });
            } else {
                cb([]);
            }
        },
        onKeydown(e){
            this.$emit('selected', this.text)
        },
        selectedPart(item) {

            if (item != null) {
                this.fireSelected(item);
            }

        },
        typedPartNumber() {

            this.$emit('selected', this.text);
            Tracer.current.debug(`2VTQU4-(part_catalog:part_number_search): fired part selected event for part number: ${this.text}`);

        },
        clearPart(){
            this.fireCleared();
        },
        fireSelected(item){

            if (item == null){
                return;
            }

            this.text = item.partNumber;

            this.$emit('selected', this.text);
            Tracer.current.debug(`2VTQU4-(part_catalog->part_number_search): fired part selected event for part number: ${this.text}`);
        },
        fireCleared(){

            this.partNumber = '';
            this.text = '';
            this.$emit('cleared');
            Tracer.current.debug('FBLMJF-(part_catalog:part_number_search): fired cleared event');
        }
    }
}