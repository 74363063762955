export class CartType {

    static get STANDARD() {
        return 'standard';
    }

    static get BULK(){
        return 'bulk'
    }

    static get EMERGENCY(){
        return 'emergency';
    }
}