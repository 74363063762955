
export class ImageGallery {

    constructor() {
        this.partNumber = '';
        this.images = [];
        this.previewImage = '';
        this.visible = false;
    }

    setPartImages(part) {
        if (typeof part == 'undefined' || part == null) return;

        if (part.images != null && part.images.length > 0) {
            this.previewImage = part.images[0].previewUri;
        }

        for (let i = 0; i <= part.images.length; i++) {
            if (part.images[i] == null || part.images[i].previewUri === '') continue;
            if (part.images[i] == null || part.images[i].thumbnailUri === '') continue;
            this.images[i] = part.images[i];
        }

        this.partNumber = part.partNumber;
        this.visible = true;
    }

    reset() {
        this.partNumber = '';
        this.images = [];
        this.previewImage = '';
        this.visible = false;
    }
}