import { render, staticRenderFns } from "./PlaceOrder.vue?vue&type=template&id=f9984804&scoped=true&"
import script from "./PlaceOrder.js?vue&type=script&lang=js&"
export * from "./PlaceOrder.js?vue&type=script&lang=js&"
import style0 from "./css/PlaceOrder.scss?vue&type=style&index=0&id=f9984804&prod&lang=scss&scoped=true&"
import style1 from "./css/PlaceOrder.Global.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9984804",
  null
  
)

export default component.exports