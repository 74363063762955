export class ImpersonatedAccount {

    constructor(account, name){
        this._account = account;
        this._name = name;
    }

    get account(){
        return this._account;
    }

    get name(){
        return this._name;
    }
}