import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
	state: {

		layout: {
			navPos: 'left', //top, bottom, left, right, false
			toolbar: 'top', //top, bottom, false
			footer: true, //above, below, false
			boxed: false, //true, false
			roundedCorners: false, //true, false
			viewAnimation: 'fade-top' // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
		},
		splashScreen: true,
		logged: false,
		auth: {
		    jwt : '',
		    isAuthenticated: false,
		    timestamp: null,
			sessionId: ''
		},
		maintenance: {
			status: 'Off',
			start: {},
			end: {},
			warnTime: ''
		},
		profile: {
			userId: '',
			name: '',
			restricted: false,
			customerNumber: '',
			customerName: '',
			companyId: '',
			companyLogo: '',
			companyName: '',
			roles: [],
			rolesOptions: {},
			branches: [],
			billingAddress: {},
			shippingAddress: {},
			credit: {},
			account: {},
			isImpersonated: false,
			impersonated: {
				customerNumber: '',
				customerName: '',
			}
		},
		companySettings: {},
		views: {

			/**
			 * Save the state of the items (parts) added in the open orders.
			 */
			bulkOrders: {
			   cartId: '',
			   orderItems: [],
			   warranty: '',
		   },

			/**
			 * Save the state of the emergency order view
			 */
			emergencyOrders: {
				cartId: '',
				orderItems: {}, // only one item allowed
				warranty: '',
			},
		   showCreditLimitAlert: false
		},
		search: {
			partNumber: ''
		},
		shoppingCart: {
			version: '',
			items: [],
			warranty: '',
			loaded: false,
			loading: false
		}
	},
	mutations: {
		setLayout(state, payload) {
			if (payload && payload.navPos !== undefined)
				state.layout.navPos = payload.navPos;

			if (payload && payload.toolbar !== undefined)
				state.layout.toolbar = payload.toolbar;

			if (payload && payload.footer !== undefined)
				state.layout.footer = payload.footer;

			if (payload && payload.boxed !== undefined)
				state.layout.boxed = payload.boxed;

			if (payload && payload.roundedCorners !== undefined)
				state.layout.roundedCorners = payload.roundedCorners;

			if (payload && payload.viewAnimation !== undefined)
				state.layout.viewAnimation = payload.viewAnimation;
		},
		setState(state, loadedState){
			Object.assign(state, loadedState);
		},
		setLogin(state, payload) {
			state.logged = true;
			state.auth.isAuthenticated = true;
			state.auth.jwt = payload.jwt;
			state.auth.timestamp = Date.now();
			state.auth.sessionId = payload.sessionId;
		},
		setLogout(state) {
			state.layout.navPos = null;
			state.layout.toolbar = null;

			state.logged = false; // deprecated.
			state.auth.jwt = '';
			state.auth.sessionId = '';
			state.isAuthenticated = false;
			state.timestamp = null;
			state.profile = {
				userId: '',
				customerNumber: '',
				customerName: '',
				dealerCode: '',
				restricted: false,
				name: '',
				companyId: '',
				companyLogo: '',
				companyName: '',
				roles: [],
				branches: [],
				billingAddress: {},
				shippingAddress: {},
				credit: {},
				account: {},
				isImpersonated: false,
				impersonated: {
					customerNumber: '',
					customerName: '',
				}
			};

			state.shoppingCart = {
				version: '',
				items: [],
				warranty: '',
				loaded: false,
				loading: false
			};

			state.views.bulkOrders = {
				cartId: '',
				orderItems: [],
				warranty: '',
			};

			state.views.emergencyOrders = {
				cartId: '',
				orderItems: [],
				warranty: '',
			}
		},
		setSplashScreen(state, payload) {
			state.splashScreen = payload
		},
		setProfile(state, profile) {

			state.profile.userId = profile.userId;
			state.profile.customerNumber = profile.customerNumber;
			state.profile.dealerCode = profile.dealerCode;
			state.profile.restricted = profile.restricted;
			state.profile.name = profile.name;
			state.profile.companyName = profile.companyName;
			state.profile.companyId = profile.companyId;
			state.profile.companyLogo = profile.companyLogo;
			state.profile.roles = profile.roles;
			state.profile.rolesOptions = profile.rolesOptions;
			state.profile.billingAddress = profile.billingAddress;
			state.profile.shippingAddress = profile.shippingAddress
			state.profile.isImpersonated = false;
			state.profile.account = profile.account;

			if (profile.billingAddress != null) {
				state.profile.customerName = profile.billingAddress.customerName;
			}

			window.tracer.debug("B62UI8-(store): Add tags");
			window.tracer.setTag("userId", profile.userId);
			window.tracer.setTag("account", profile.customerNumber);
			window.tracer.setTag("companyId", profile.companyId);
			window.tracer.setTag("name", profile.billingAddress.customerName);

		},
		setImpersonated(state, payload){

			if (payload == null){
				return;
			}

		   let account = payload.account;
		   let name = payload.name;

		   state.profile.isImpersonated = true;
		   state.profile.impersonated.customerNumber = account;
		   state.profile.impersonated.customerName =  name;
		},
		setCompanySettings(state, settings){
			state.companySettings = settings;
		},
		setCreditLimit(state, credit){
			state.profile.credit = credit;
		},
		setPartNumber(state, partNumber) {
			state.search.partNumber = partNumber;
		},
		setShoppingCart(state, payload) {

			state.shoppingCart.version = payload.version;
			state.shoppingCart.items = payload.items;

		},
		setShoppingCartLoading(state){
		   state.shoppingCart.loading = true;
		   state.shoppingCart.loaded = false;
		},
		setShoppingCartLoaded(state, value){
			if (value){
				state.shoppingCart.loading = false;
				state.shoppingCart.loaded = true;
			}
			else{
				state.shoppingCart.loading = false;
				state.shoppingCart.loaded = false;
			}
		},

		setOrdersItems(state, payload){
		    state.views.bulkOrders.orderItems = payload.items;
		    state.views.bulkOrders.cartId = payload.cartId;
		},
		setEmergencyItems(state, payload){
			state.views.emergencyOrders.orderItems = payload.items;
			state.views.emergencyOrders.cartId = payload.cartId;
		},

		ordersRemoveItem(state, payload){

			let bulkItems = state.views.bulkOrders.orderItems;

			let deleteIndex = -1;
			for(let i = 0; i < bulkItems.length; i++){
				if (bulkItems[i].itemId === payload.itemId){
					deleteIndex = i;
					break;
				}
			}

			if (deleteIndex > -1) {
				state.views.bulkOrders.orderItems.splice(deleteIndex, 1);
				console.debug(`[d211986] item with id of ${payload.itemId} and of index ${deleteIndex} was removed from the vuex store.`);
			}
		},
		emergencyOrdersRemoveItem(state, payload){
			state.views.emergencyOrders.orderItems = [];
		},
		ordersRemoveAll(state, payload){
			state.views.bulkOrders.orderItems = [];
			state.views.bulkOrders.cartId = '';
		},
		emergencyOrderRemoveAll(state, payload){
			state.views.emergencyOrders.orderItems = [];
			state.views.emergencyOrders.cartId = '';
		},
		ordersAddWarranty(state, payload){
			state.views.bulkOrders.warranty = payload;
		},
		emergencyOrderAddWarranty(state, payload){
			state.views.emergencyOrders.warranty = payload;
		},
		changeShoppingCartItemQuantity(state, payload) {

			let item = state.shoppingCart.items.find(function (element) {
				return element.itemId === payload.itemId;
			});

			if (!item) {
				console.warn("[w194525]: Item not found in the vuex store. shopping cart state not updated. ItemId:"+payload.itemId);
				return;
			}

			item.qty = payload.quantity;
			item.total = payload.newTotal;
			item.inStock = payload.inStock;
			state.shoppingCart.version = payload.version;


		},
		removeShoppingCartItems(state, payload) {

			let itemId = payload.itemId;
			let version = payload.version;

			let itemIndex = state.shoppingCart.items.findIndex(function (element) {
				return element.itemId === itemId;
			});

			state.shoppingCart.items.splice(itemIndex, 1);
			state.shoppingCart.version = payload.version;

		},
		changeShoppingCartItemBranch(state, payload){
			let part = payload.part;
			let branchId = payload.branchId;
            let version = payload.version;

			let item = state.shoppingCart.items.find(function (element) {
				return element.itemId === part.itemId;
			});

			if (item == null){
				window.tracer.error(`[e100244]: Part ${part.partNumber} not found. Branch not updated!.`);
				return;
			}

			item.branches.forEach(function(element){

				if (element.id === branchId){
					element.assigned = true;
				}
				else{
					element.assigned = false;
				}
			});

			state.shoppingCart.version = version;
		},
		addShoppingCartWarranty(state, payload) {

			state.shoppingCart.warranty = payload;

		},
		addShoppingCartItem(state, payload) {

			if (payload.item != null) {

				state.shoppingCart.version = payload.version;
				if (state.shoppingCart.items == null) {
					state.shoppingCart.items = [ payload.item ];
				} else {

					let index = state.shoppingCart.items.findIndex(function (element) {
						return element.partNumber === payload.item.partNumber;
					});

					if (index < 0) {
						state.shoppingCart.items.push(payload.item);
					}
					else{
						state.shoppingCart.items[index] = payload.item;
					}

				}
			}

		},
		showCreditLimitAlert(state, payload){
			state.views.showCreditLimitAlert = payload;
		},
		setMaintenanceOff(state){
			state.maintenance.status = "off";
			state.maintenance.start = '';
			state.maintenance.end = '';
		},
		setMaintenance(state, payload){
			state.maintenance.status = payload.status;
			state.maintenance.start = payload.start;
			state.maintenance.end = payload.end;
			state.maintenance.warnTime = payload.warnTime;
		}

	},
	actions: {

	},
	getters: {
		layout(state, getters) {
			return state.layout
		},
		navPos(state, getters) {
			return state.layout.navPos
		},
		toolbar(state, getters) {
			return state.layout.toolbar
		},
		footer(state, getters) {
			return state.layout.footer
		},
		boxed(state, getters) {
			return state.layout.boxed
		},
		roundedCorners(state, getters) {
			return state.layout.roundedCorners
		},
		viewAnimation(state, getters) {
			return state.layout.viewAnimation
		},
		isLogged(state, getters) {
			return state.auth.isAuthenticated;
		},
		splashScreen(state, getters) {
			return state.splashScreen
		},
		getProfile(state, getters){
			return state.profile;
		},
		getCompanySettings(state, getters){
		    return state.companySettings;
		},
		getCreditLimit(state, getters){
			return state.profile.credit;
		},
		shoppingCart(state, getters){
			return state.shoppingCart;
		},
		shoppingCartCount(state, getters){

			if (state.shoppingCart == null){
				return 0;
			}

			if (state.shoppingCart.items == null){
				return 0;
			}

			return state.shoppingCart.items.length;
		},
		shoppingCartGrandTotal(state, getters) {

			if (state.shoppingCart.items == null) {
				return 0;
			}

			let total = 0;
			for (let i = 0; i < state.shoppingCart.items.length; i++) {
				total = total + state.shoppingCart.items[i].total;
			}

			return total;

		},
		shoppingCartWarranty(state, getters){
		   return state.shoppingCart.warranty;
		},
		bulkOrderItems(state, getters){
			return state.views.bulkOrders.orderItems;
		},
		emergencyOrderItems(state, getters){
			return state.views.emergencyOrders.orderItems;
		},
		bulkOrderCount(state, getters){
			return state.views.bulkOrders.orderItems.length;
		},
		bulkOrderGrandTotal(state, getters) {

			if (state.views.bulkOrders.orderItems == null){
				return 0;
			}

			let total = 0;
			for (let i = 0; i < state.views.bulkOrders.orderItems.length; i++) {
				total = total + state.views.bulkOrders.orderItems[i].total;
			}

			return total;
		},
		emergencyOrderGrandTotal(state, getters) {

			if (state.views.emergencyOrders.orderItems == null){
				return 0;
			}

			let total = 0;
			for (let i = 0; i < state.views.emergencyOrders.orderItems.length; i++) {
				total = total + state.views.emergencyOrders.orderItems[i].total;
			}

			return total;
		},
		bulkOrderWarranty(state, getters){
			return state.views.bulkOrders.warranty;
		},
		showCreditLimitAlert(state, getters){
			return state.views.showCreditLimitAlert;
		},

		/**
		 * Get the maintenance state.
		 * @param state
		 * @param getters
		 * @return {{warnTime: string, start: string, end: string, message: string, status: string}}
		 */
		maintenance(state, getters){
			return state.maintenance;
		},
		maintenanceStatus(state) {
			return state.maintenance.status;
		}

	},
	plugins: [createPersistedState({paths: ['layout', '']})],
	strict: debug
})
