/**
 * Represents the arguments to search parts in the catalog
 */
export class CatalogSearchArgs {

    constructor() {
        this._year = 0;
        this._makeId = '';
        this._modelId = '';
        this._categoryId = '';
        this._engineId = '';
        this._driveId = '';
        this._fuelId = '';
        this._vinId = '';
    }

    get vinId() {
        return this._vinId;
    }

    set vinId(value) {
        this._vinId = value;
    }

    get fuelId() {
        return this._fuelId;
    }

    set fuelId(value) {
        this._fuelId = value;
    }

    get driveId() {
        return this._driveId;
    }

    set driveId(value) {
        this._driveId = value;
    }

    get engineId() {
        return this._engineId;
    }

    set engineId(value) {
        this._engineId = value;
    }

    get categoryId() {
        return this._categoryId;
    }

    set categoryId(value) {
        this._categoryId = value;
    }

    get modelId() {
        return this._modelId;
    }

    set modelId(value) {
        this._modelId = value;
    }

    get makeId() {
        return this._makeId;
    }

    set makeId(value) {
        this._makeId = value;
    }

    get year() {
        return this._year;
    }

    set year(value) {
        this._year = value;
    }
}
