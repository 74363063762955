
import OpenOrdersHistoryGrid from './parts/OpenOrdersHistoryGrid/OpenOrdersHistoryGrid.vue';
import OrderHistorySearchToolbox from './parts/OrderHistorySearchToolbox/OrderHistorySearchToolbox.vue';
import OrderDetailPdfViewer from './parts/OrderDetailPdfViewer/OrderDetailPdfViewer.vue'
import {InvoicesResults} from "./models/InvoicesResults";
import {CreditsResults} from "./models/CreditsResults";
import {OpenOrdersResults} from "./models/OpenOrdersResults";
import {CancelledOrdersResults} from "./models/CancelledOrdersResults";
import ViewHeader from "../../../components/ViewHeader/ViewHeader.vue";
import StatementPdfViewer from "./parts/StatementPdfViewer/StatementPdfViewer.vue";
import {StoreProxy} from "@api/common/StoreProxy";
import InvoiceHistoryGrid from "@/views/apps/OrderHistory/parts/InvoiceHistoryGrid/InvoiceHistoryGrid.vue";
import CreditsHistoryGrid from "@/views/apps/OrderHistory/parts/CreditsHistoryGrid/CreditsHistoryGrid.vue";
import {Tracer} from "@api/common/Tracer";
import OrderDetailView from "./parts/OrderDetailView/OrderDetailView.vue";


export default {
    name: 'OrderHistory',

    components: {
        ViewHeader: ViewHeader,
        InvoiceHistoryGrid,
        CreditsHistoryGrid,
        OpenOrdersHistoryGrid,
        OrderHistorySearchToolbox,
        OrderDetailPdfViewer,
        StatementPdfViewer,
        OrderDetailView,
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {

        });
        next();
    },
    beforeRouteUpdate(to, from, next) {
        next();
    },
    beforeRouteLeave(to, from, next) {

        next();
    },

    data() {
        return {
            pane: 'invoices',
            invoices: new InvoicesResults(StoreProxy.getAuth(this.$store)),
            credits: new CreditsResults(StoreProxy.getAuth(this.$store)),
            openOrders: new OpenOrdersResults(StoreProxy.getAuth(this.$store)),
            cancelledOrders: new CancelledOrdersResults(StoreProxy.getAuth(this.$store)),
            statementsItems: [],
            creditsNotFound: false,
            openOrdersNotFound: false,
            statementsNotFound: false,
            isLoading: false,
            creditsSearchState: {},
            invoiceSearchState: {},
            openOrdersSearchState: {},
            view: {
                name: "",
                type : "",
                id : "",
            }
        }
    },
    mounted() {

        this.isLoading = true;
        this.invoices.searchLastMonths().then(r => {
            this.isLoading = false;
        });


    },
    computed: {

    },
    methods: {
        onSearch(data) {

            Tracer.current.debug("4YVS3P: On search event triggered");
            let searchType = data.searchType;
            let value = data.value;

            if (this.pane === 'invoices') {

                this.invoiceSearchState = data;
                this.isLoading = true;
                this.invoices.clear();

                if (searchType === 'dateRange') {

                    if (!(value instanceof Array)) {
                        throw new Error('713752: expected an array with dates.')
                    }

                    if (value.length !== 2) {
                        throw new Error('686368: expected an array with items (start and end dates).')
                    }

                    let start = value[0];
                    let end = value[1];

                    this.invoices.searchDateRange(start, end, 1).then(r => {
                        this.isLoading = false;
                    });
                } else {

                    if (value === '') {
                        this.invoices.searchLastMonths().then(r => {
                            this.isLoading = false;
                        });
                    } else {
                        this.invoices.search(searchType, value, 1).then(r => {
                            this.isLoading = false;
                        });
                    }
                }

            }

            if (this.pane === 'credits') {
                this.creditsSearchState = data;
                this.isLoading = true;
                this.credits.clear();

                if (searchType === 'dateRange') {

                    if (!(value instanceof Array)) {
                        throw new Error('788641: expected an array with dates.')
                    }

                    if (value.length !== 2) {
                        throw new Error('460284: expected an array with items (start and end dates).')
                    }

                    let start = value[0];
                    let end = value[1];

                    this.credits.searchDateRange(start, end, 1).then(r => {
                        this.isLoading = false;
                    });
                } else {

                    if (value === '') {
                        this.credits.searchLastMonths().then(r => {
                            this.isLoading = false;
                        });
                    } else {
                        this.credits.search(searchType, value, 1).then(r => {
                            this.isLoading = false;
                        });
                    }

                }
            }

            if (this.pane === 'openOrders') {

                this.openOrdersState = data;
                this.isLoading = true;

                this.openOrders.clear();

                if (searchType === 'dateRange') {

                    Tracer.current.debug("7U7ZQD: Open Orders search by date range");

                    if (!(value instanceof Array)) {
                        throw new Error('1HH0ZB: expected an array with dates.')
                    }

                    if (value.length !== 2) {
                        throw new Error('B8ZT7W: expected an array with items (start and end dates).')
                    }

                    let start = value[0];
                    let end = value[1];

                    this.openOrders.searchDateRange(start, end, 1).then(r => {
                        this.isLoading = false;
                    });

                } else {

                    if (value === '') {
                        this.openOrders.searchDefault().then(r => {
                            this.isLoading = false;
                        });
                    } else {
                        this.openOrders.search(searchType, value, 1).then(r => {
                            this.isLoading = false;
                        });
                    }

                }
            }

            if (this.pane === 'statements') {
                this.viewStatementsPdf();
            }

            if (searchType === '') {
                throw new Error('44KLFX: unrecognized tab name.');
            }

        },
        invoicedOrderDetails(order){
             this._setView("order-details", "invoice", order.invoiceNumber);
        },
        creditsOrderDetails(order) {
            this._setView("order-details", "credits", order.invoiceNumber);
        },
        openOrderDetails(order){
            this._setView("order-details", "open", order.invoiceNumber);
        },
        viewInvoicePdf(order){
            this._setView("pdf", "invoice", order.invoiceNumber);
        },
        viewCreditsPdf(order){
            this._setView("pdf", "credits", order.invoiceNumber);
        },
        viewStatementsPdf(){
            this._setView("pdf", "statements", 0);
        },
        paneChanged(){

            /** note: using ref to access the method "clearSearch" on the OrderHistorySearchToolBox **/

            Tracer.current.debug("2PQPTT: Pane change default search started");
            if (this.pane === 'credits'){

                this.creditsSearchState = {}
                this.$refs.creditsToolbox.clearSearch();

                this.isLoading = true;

            }

            if (this.pane === 'invoices') {

                this.invoiceSearchState = {}
                this.$refs.invoicesToolbox.clearSearch();

                this.isLoading = true;

            }

            if (this.pane === 'openOrders'){

                this.openOrdersSearchState = {}
                this.$refs.openOrdersToolbox.clearSearch();

                this.isLoading = true;

            }
        },
        closeViewer(){
            this._clearView();
        },
        _setView(name, type, identifier){
            this.view.name = name;
            this.view.type = type;
            this.view.id = identifier;
        },
        _clearView(){
            this.view.name = "";
            this.view.type = "";
            this.view.id = "";
        }
    }

}
