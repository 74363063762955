import {ShoppingCartService} from '@api/modules/shoppingCart/services/ShoppingCartService';
import _ from 'lodash';
import {StoreProxy} from "@api/common/StoreProxy";
import {SETTINGS_BACKORDER_SHOPPING_CART_MESSAGE_KEY} from "@/constants";
import {CompanySettings} from "@api/modules/userManagement/CompanySettings";
import ViewHeader from "../../../../components/ViewHeader/ViewHeader.vue";
import {Tracer} from "@api/common/Tracer";
import {CartType} from "@api/modules/shoppingCart/services/CartType";

export default {
    name: "PlaceOrderConfirm",
    components: {
        ViewHeader: ViewHeader,
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {

            let order = to.params.order;
            let type = to.params.type;

            if (order == null) {
                vm.$router.push("/shopping-cart");

                return;
            }

            if (type == null) {
                Tracer.current.warn("94IAX8-(confirm_order):[CART_TYPE_MISSING]");
                vm.$router.back();

                return;
            }

            vm._load(type, order);

        });
        next();
    },
    beforeRouteUpdate (to, from, next){
        let order = to.params.order;
        let type = to.params.type;

        if (order == null){
            window.tracer.error('B0WG6G-(confirm_order)[undefined]: The order argument is undefined.');
            this.$router.push("/shopping-cart");
        }

        if (type == null) {
            window.tracer.error('45KTC2-(confirm_order)[undefined]: The type argument is undefined');
            this.$router.back();

            return;
        }

        this._load(type, order);

        next();
    },
    beforeRouteLeave (to, from, next){

        next();
    },

    data(){
        return {
            type: 'standard',
            userId: '',
            billing: {
                customer: '',
                address: '',
                address2: '',
                city: '',
                state: '',
                zipCode: ''
            },
            shipping: {
                customer: '',
                address: '',
                address2: '',
                city: '',
                state: '',
                zipCode: ''
            },
            purchaseOrder: '',
            vinNumber: '',
            comments: '',
            attentionTo: '',
            phoneNumber: '',
            email: '',
            dealerCode: '',
            shipToBillingAddress: false,
            ux : {
                   confirmation: {
                       show: false,
                       message: '',
                       type: 'success'
                   },
                   orderCompleted: false

            },
            items : [],
            grandTotal: 0,
            isPlaceOrderButtonDisabled : false

        }
    },
    mounted() {
        this.userId = StoreProxy.getUserId(this.$store);
        const companySettings = CompanySettings.getCompanyInstance(this.$store);
        this.outOfStockMessage = companySettings.getSetting(SETTINGS_BACKORDER_SHOPPING_CART_MESSAGE_KEY);
    },
    computed: {

        groupedItems () {
           return _.groupBy(this.items, function(item){

               if (item.branches) {
                   const branch = _.find(item.branches, function (b) {
                       return b.assigned === true;
                   });

                   if (branch) {
                       return branch.name;
                   }

                   return "";
               }

               return "";
           });
        },
        warranty(){
            let warranty = null;
            if (this.type === 'standard') {
                warranty = this.$store.getters.shoppingCart.warranty;
            }
            else{
                warranty = this.$store.getters.bulkOrderWarranty;
            }
            if (warranty !== '' && warranty.id !== ''){
               return warranty;
            }
            else{
                return null;
            }
        },
        isEmergencyOrder(){
            return this.type === 'emergency';
        }
    },
    methods: {
        _load(type, order){

            this.type = type;
            this.setCart();
            this.populateData(order);

        },
        setCart(){
            if (this.type === 'standard') {
                this.grandTotal = StoreProxy.getShoppingCartGrandTotal(this.$store);
                this.items = StoreProxy.getShoppingCartItems(this.$store);

            }
            else if (this.type === 'emergency'){
                this.grandTotal = StoreProxy.getEmergencyOrderGrandTotal(this.$store);
                this.items = StoreProxy.getEmergencyOrderItems(this.$store);
            }
            else{
                this.grandTotal = StoreProxy.getBulkOrderGrandTotal(this.$store);
                this.items = StoreProxy.getBulkOrderItems(this.$store);
            }
        },
        onPlaceOrder: function() {

            this.isPlaceOrderButtonDisabled = true;

            const shoppingCartService = new ShoppingCartService(StoreProxy.getAuth(this.$store));

            let comments = this.comments;
            if (this.warranty != null) {
                comments = `*** ${this.warranty.desc} ${this.warranty.price} ***\n\n` + comments;
            }

            const order = {
                billing: this.billing,
                shipping: this.shipping,
                purchaseOrder: this.purchaseOrder,
                vinNumber: this.vinNumber,
                dealerCode: this.dealerCode,
                emailAddress: this.email,
                comments: comments,
                attentionTo: this.attentionTo,
                phoneNumber: this.phoneNumber,
                shipToBillingAddress: this.shipToBillingAddress
            };

            const vm = this;

            const loading = this.$loading({
                lock: true,
                text: `Submitting Order. Please wait...`,
                spinner: 'el-icon-loading',
                customClass: 'place-order-confirm-loading',
                background: 'rgba(255, 245, 250, 0.55)'
            });


            let cartType = CartType.STANDARD;

            if (this.type === 'bulk') {
                cartType = CartType.BULK;
            } else if (this.type === 'emergency') {
                cartType = CartType.EMERGENCY;
            } else if (this.type === 'standard') {
                cartType = CartType.STANDARD;
            }

            shoppingCartService.placeOrder(this.userId, order, cartType).then(data => {

                if (data.success === true) {
                    vm.closeOrder(true, data);
                    loading.close();
                } else {
                    if (data.orderStatus === 'NoCreditAvailable') {

                        this.$alert('Your order exceeded your credit limit.', 'Credit Limit Alert', {
                            confirmButtonText: 'OK',
                            type: "warning"
                        }).then(() => {
                            vm.closeOrder(false);
                            loading.close();
                            this.isPlaceOrderButtonDisabled = false;
                        }).catch(() => {
                            Tracer.current.debug("AZE823-(place_order_confirm)[alert_dialog][cancel_event]: User cancelled the alert window");
                        });


                    } else if (data.orderStatus === 'CartLocked') {

                        this.$alert('Another session with this same account is submitting an order. Unable to submit this one. Try again later.', 'Cart Locked', {
                            confirmButtonText: 'OK',
                            type: "warning"
                        }).then(() => {
                            vm.closeOrder(false);
                            loading.close();
                            this.isPlaceOrderButtonDisabled = false;
                        }).catch(() => {
                            Tracer.current.debug("3EMV6K-(place_order_confirm)[alert_dialog][cancel_event]: User cancelled the alert window");
                        });

                    } else {
                        vm.closeOrder(false);
                        loading.close();
                        this.isPlaceOrderButtonDisabled = false;
                    }
                }

            }).catch(error => {
                vm.closeOrder(false);
                loading.close();
                this.isPlaceOrderButtonDisabled = false;
            });

        },
        populateData: function(order) {
            this.billing = order.billing;
            this.shipping = order.shipping;
            this.purchaseOrder = order.details.purchaseOrder;
            this.vinNumber = order.details.vinNumber;
            this.comments = order.details.comments;
            this.attentionTo = order.details.attentionTo;
            this.phoneNumber = order.details.phoneNumber;
            this.email = order.details.email;
            this.dealerCode = order.details.dealerCode;
        },
        onClose: function(){
          this.$router.push('/');
        },
        onBack() {
          this.$router.go(-1);
        },
        closeOrder: function(isSuccess, data){

            if (isSuccess) {

                this.ux.confirmation.title = 'Thank you!. Your order was succesfully submitted!';
                this.ux.confirmation.message = `Your order number(s) are: ${data.orderNumbers.join()}`;
                this.ux.confirmation.type = 'success';
                this.ux.confirmation.show = true;
                this.ux.orderCompleted = true;

                try {
                    if (this.type === 'standard') {
                        StoreProxy.clearShoppingCart(this.$store);
                    } else {
                        StoreProxy.clearBulkOrderCart(this.$store);
                    }
                }
                catch(e){
                    Tracer.current.error("601659-(place_order_confirm): Error clearing the cart after order succesfully placed");
                    Tracer.current.error(e);
                }
            }
            else {

                this.ux.confirmation.title = 'Sorry, An error happened when placing your order';
                this.ux.confirmation.message = 'Your items are still in the shopping cart. Please try again, Contact support if this problem persists.';
                this.ux.confirmation.type = 'error';
                this.ux.confirmation.show = true;
            }
        }
    }


}