
import {StoreProxy} from "../../../../api/common/StoreProxy";
import {MarketingMaterialItem} from "../../../../api/modules/marketingMaterials/MarketingMaterialItem";
import {MimeTypeMapper} from "../../../../api/common/MimeTypeMapper";
import ViewHeader from "@/components/ViewHeader/ViewHeader.vue";

export default {
    name: "MarketingMaterials",
    data() {
        return {
            _auth : '',
            _companyId: '',
            isLoading: false,
            items: [],
            viewerItem: {},
            viewerVisible: false
        }
    },
    components: {
        ViewHeader
    },
    created(){

        this._auth = StoreProxy.getAuth(this.$store);
        this._companydId = StoreProxy.getCompanyId(this.$store);

        this.isLoading = true;
        let vm = this;
        MarketingMaterialItem.getItems(vm._auth, vm._companydId).then(items =>{
            vm.items = items;
            vm.isLoading = false;
            console.debug('[d683588] materials loaded from server');
        }).catch(error => {

            vm.$notify.error({
                title: 'Internal Error',
                message: 'Failed to retrieve material list'
            });

            if (error != null){
                window.tracer.error(error);
            }

            vm.isLoading = false;
        });
    },
    methods: {
        downloadFile(item){
            if (!item){
                throw new Error('[e574897] marketing material item for download is not an object!' );
            }

            // check if file exists first.
            item.existsFile(this._auth).then(data => {

                if (data === true){
                    location.href = item.getDownloadUrl(this._auth);
                }
                else{
                    this.$alert('File not found in the server.\nPlease contact support.', 'File Not Found', {
                        confirmButtonText: 'OK'
                    });
                }
            }).catch(err => {
                this.$notify.error({
                    title: 'Internal Error',
                    message: 'Server error when checking for file!. Please contact support.'
                });
            });

        },
        viewFile(item){

            if (!item){
                throw new Error('[e140652] marketing material item for download is not an object!' );
            }

            this.viewerItem = item;
            this.viewerVisible = true;
        },
        closeViewer(){
            this.viewerItem = {};
            this.viewerVisible = false;
        },
        /**
         * @return {boolean}
         */
        isImage(mimeType){
            return MimeTypeMapper.isImage(mimeType);
        },
        /**
         * @return {string}
         */
        getIconUrl(mimeType){
            return `/static/file-icons/${MimeTypeMapper.getIconType(mimeType)}`;
        },
        /**
         * @return {boolean}
         */
        isPdf(mimeType){
            return MimeTypeMapper.isPdf(mimeType);
        }
    }

}