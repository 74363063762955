export class PriorReplenishCount {

    constructor() {
        this._count7 = -1;
        this._count14 = -1;
        this._count28 = -1;
    }

    get count28() {
        return this._count28;
    }

    get count14() {
        return this._count14;
    }

    get count7() {
        return this._count7;
    }

    /**
     * Set the count for the last 7 days <br>
     * <b>Internal Use Only!</b>
     * @param value
     */
    _$setCount7(value) {
        this._count7 = value;
    }

    /**
     * Set the count for the last 21 days <br>
     * <b>Internal Use Only!</b>
     * @param value
     */
    _$setCount28(value) {
        this._count28 = value;
    }

    /**
     * Set the count for the last 14 days <br>
     * <b>Internal Use Only!</b>
     * @param value
     */
    _$setCount14(value) {
        this._count14 = value;
    }
}