import {HttpUtils} from "@api/common/HttpUtils";
import {PartNumberSearchResult} from "../models/PartNumberSearchResult";
import {Tracer} from "@api/common/Tracer";

export class PartNumberSearchService {

    constructor(auth){
        this._auth = auth;
        this._headers = HttpUtils.getHttpHeaders(auth, 'text/json');
        this._url = `${process.env.VUE_APP_API_URL}/v1/catalog/part-numbers`;
    }

    //@todo: remove this method if not in use.
    search(text){
        const url = `${this._url}?text=${text}`;
        return new Promise((resolve, reject) => {

            HttpUtils.get(url, this._auth).then(data => {
                resolve(data);
            }).catch(e => {
                Tracer.current.error("053065-(part_number_search_service): Error fetching part number search results.");
                reject(e);
            });

        });
    }

    /**
     * Query server for matching part number by text provided
     * and returns an instance of PartNumberSearchResult (Promise).
     * @param text
     * @returns {Promise<PartNumberSearchResult>}
     */
    searchDescriptions(text) {

        const url = `${this._url}/desc?text=${text}`;
        return new Promise((resolve, reject) => {

            try {
                HttpUtils.get(url, this._auth).then(data => {

                    if (data == null) {
                        resolve(new PartNumberSearchResult(0));
                        return;
                    }

                    if (data.count === 0) {
                        resolve(new PartNumberSearchResult(0));
                        return;
                    }

                    const result = new PartNumberSearchResult(data.count);
                    data.items.forEach(function (obj) {
                        result.appendResult(obj.partNumber, obj.description);
                    });

                    resolve(result);

                }).catch(error => {

                    Tracer.current.error('396798-(part_number_search_service): Error getting the search results');
                    Tracer.current.error(error);
                    reject(error);
                });
            } catch (error) {
                Tracer.current.error('049244-(part_number_search_service): Internal Error');
                Tracer.current.error(error);
                reject(error);
            }
        });

    }
}
