/**
 * Represents the shipping tracking information.
 */
export class TrackingInfo {

    constructor() {
        this._trackingNumber = "";
        this._url = "";
        this._invoiceNumber = "";
        this._carrier = "";
    }

    get carrier() {
        return this._carrier;
    }

    set carrier(value) {
        this._carrier = value;
    }

    get invoiceNumber() {
        return this._invoiceNumber;
    }

    set invoiceNumber(value) {
        this._invoiceNumber = value;
    }
    get url() {
        return this._url;
    }

    set url(value) {
        this._url = value;
    }

    get trackingNumber() {
        return this._trackingNumber;
    }

    set trackingNumber(value) {
        this._trackingNumber = value;
    }


}