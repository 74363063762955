
import {Tracer} from "@api/common/Tracer";

export default {
    name: "PartNumberAutoComplete",
    props: {
        partNumber: {
            type: String,
            default: ''
        },
        showDescription: {
            type: Boolean,
            default: false
        }
    },
    created(){
    },
    mounted(){
        this.text = this.partNumber;
    },
    computed: {
    },
    data() {
        return {
            text: '',
            description: ''
        }
    },
    watch: {
      partNumber() {
          this.text = this.partNumber;
      }
    },
    methods: {

        onKeydown(e){

        },
        typedPartNumber() {

            this.$emit('selected', this.text);
            Tracer.current.debug(`2VTQU4-(partnumber_autocomplete): fired part selected event for part number: ${this.text}`);

        },
        clearPart(){
            this.fireCleared();
        },
        fireCleared(){

            this.partNumber = '';
            this.text = '';
            this.$emit('cleared');
            Tracer.current.debug('FBLMJF-(partnumber_autocomplete): fired cleared event');
        },

    }
}