// [BEGIN] Replace in future releases for CatalogSearch (as it is ported)
//import {PartsSearchService} from "@api/modules/partCatalog/PartsSearchService";
// [END]

import {OrderPart} from "./OrderPart";
import {CatalogSearch} from "@api/modules/catalogSearch/CatalogSearch";
import {Tracer} from "@api/common/Tracer";
import {StringUtils} from "@api/common/StringUtils";
import {PartFulfillmentInfo} from "@/views/apps/OrderParts/models/PartFulfillmentInfo";
import {Roles} from "@api/core/Roles";
import {AppConfig} from "@/config/AppConfig";
import {StoreProxy} from "@api/common/StoreProxy";
import {_Constants} from "@/views/apps/OrderParts/models/_Constants";

/**
 * Represents a single part on the Order page.
 */
export class OrderPartItem {

    static _UNINITIALIZED = -1;
    static _SUCCESS = 0;
    static _NOT_FOUND = 1;
    static _ERROR = 99;
    static _OBJECT_COUNTER = 0;

    /**
     * The default max quantity allowed for non emergency orders
     * @type {Number}
     * @private
     */
    static _DEFAULT_MAX_ALLOWED_QTY = 50;

    /**
     * The default max quantity allowed for emergency orders
     * @type {Number}
     * @private
     */
    static get _DEFAULT_MAX_ALLOWED_QTY_EMERGENCY_ORDER() {
        return OrderPartItem._EMERGENCY_ORDER_CONFIG.maxQty;
    }

    static get _$STORE() {
        return window.app.$store;
    }

    /**
     * Gets the account home branch
     * @returns {string|*|string}
     * @private
     */
    static get _HOME_BRANCH() {
        let $store = window.app.$store;
        return StoreProxy.getHomeBranch($store);
    }

    /**
     * The Emergency Order Configuration
     * @type {EmergencyOrdersConfig}
     * @private
     */
    static get _EMERGENCY_ORDER_CONFIG() {
        return AppConfig.current.getEmergencyOrderConfigs();
    }


    /**
     * Initialize class
     * @param auth Auth token
     * @param isEmergencyOrder whether or not this is an emergency order
     */
    constructor(auth, isEmergencyOrder) {

        if (auth == null){
            throw new Error('9XXS7L-(order_item)[NULL_ARGUMENT]: Unable to init object');
        }

        // default to false.
        this._isEmergencyOrder = false;
        this._emergencyOrderBranchId = -1;

        if (isEmergencyOrder != null && isEmergencyOrder) {

            this._isEmergencyOrder = true;

            // get the emergency branch assigned to the dealer (profile).
            // The emergency role is assigned as a role.
            this._emergencyOrderBranchId = Roles.getRoleOption(OrderPartItem._$STORE,
                                                               Roles.EMERGENCY_ORDER_ENABLED,
                                                               "branchId");

            if (isEmergencyOrder) {
                Tracer.current.debug(`8SNR1J-(order_item)[emergency_order_branch=${this._emergencyOrderBranchId}]`);
            }
        }

        this._auth = auth;
        this._catalogSearch = new CatalogSearch(auth);
        this._partNumber = null;
        this._orderPart = null;
        this._loading = false;
        this._state = OrderPartItem._UNINITIALIZED;

        this._selectedBranchIndex = 0;
        this._autoSelectedBranchIndex = -1;
        this._hasClosestBranchSelected = false;
        this._isBranchSelectedOutOfStock = false;
        this._fulfillmentInfo = null;
        this._fulfillmentInfoLoading = false;
        this._quantity = 1;
        this._defaultMaxQty = (this._isEmergencyOrder) ? OrderPartItem._DEFAULT_MAX_ALLOWED_QTY_EMERGENCY_ORDER : _Constants.DEFAULT_MAX_QTY;

        this._maxQty = null;

        this.key = OrderPartItem._OBJECT_COUNTER++;

    }

    /**
     * Gets the part number
     * @returns {string}
     */
    get partNumber(){
        return this._partNumber;
    }

    set partNumber(value){
        // this._partNumber = value;
    }

    get quantity() {

        if (this.maxQty === 0) {
            Tracer.current.debug("6A8OR8-(part_item): Quantity set to 0 as this is the max quantity allowed");
            return 0;
        }

        if (!this._isEmergencyOrder) {
            return this._quantity;
        }

        if (!StringUtils.isEmptyOrNull(this.group)) {

            let cfg = OrderPartItem._EMERGENCY_ORDER_CONFIG;
            let tmpMaxQty = cfg.getMaxQty(this.group);
            if (tmpMaxQty === 0) {

                Tracer.current.debug(`1OTZQM-(part_item)[group=${this.group}]: Quantity set to 0 as this is the max quantity allowed for this group`);
                return 0;

            } else {
                return this._quantity;
            }
        } else {
            return this._quantity;
        }
    }

    set quantity(value) {

        if (this.maxQty > 0) {
            this._quantity = value;
        }
    }

    /**
     * Gets the fulfillment info, which is the projected position and prior replenish information.
     * @returns {PartFulfillmentInfo}
     */
    get fulfillmentInfo(){
        return this._fulfillmentInfo;
    }

    set fulfillmentInfo(value){
        Tracer.current.warning("EZ2R57-(order_item): !READ_ONLY_PROPERTY!");
    }

    get fulfillmentInfoLoading() {
        return this._fulfillmentInfoLoading;
    }

    /**
     * Gets the group this parts belongs
     * @returns {string}
     */
    get group(){
        if (this._orderPart != null){
            return this._orderPart.group;
        }
        else{
            return '';
        }
    }

    set group(value){
        // do nothing.
    }

    /**
     * Gets the max quantity allowed.
     * If this is an emergency order, then it will check the emergency order config and roles. <br/>
     *
     * If this part belongs to a specific group, for example group 260, then it will check if group 260 max qty
     * allowed is configured for this group, else it will use the default config.
     *
     * @returns {number}
     */
    get maxQty() {

        if (this._orderPart == null) {
            return 0;
        }

        if (this._maxQty != null) {
            return this._maxQty;
        }

        if (!this._isEmergencyOrder) {

            let tmpMaxQty = 0;
            if (this._orderPart.maxQty != null) {
                tmpMaxQty = this._orderPart.maxQty;
            } else {
                tmpMaxQty = this._defaultMaxQty;
            }

            if (this._orderPart.backOrderRestricted) {

                const tmpInventoryQty = this._calculateInventory();

                if (tmpInventoryQty <= tmpMaxQty) {
                    tmpMaxQty = tmpInventoryQty;
                } else if (tmpInventoryQty === 0) {
                    tmpMaxQty = 0
                }

                Tracer.current.debug("A9NKK2-(order_item): Limiting max quantity to inventory availability");
            }

            this._maxQty = tmpMaxQty
            return this._maxQty;

        } else if (!StringUtils.isEmptyOrNull(this.group)) {

            //@todo: max quantity must be get from the API
            //@note: leave this code until the api is well tested.
            const cfg = OrderPartItem._EMERGENCY_ORDER_CONFIG;
            const tmpGroupMaxQty = cfg.getMaxQty(this.group);

            Tracer.current.debug(`31C0B2-(order_item):[MAX_QTY=${tmpGroupMaxQty}]:[PART_NUMBER=${this.partNumber}]:[GROUP=${this.group}]: The max quantity for ${this.partNumber} is ${tmpGroupMaxQty}`);

            this._maxQty = tmpGroupMaxQty
            return this._maxQty;

        } else {
            this._maxQty = this._defaultMaxQty;
            return this._maxQty;
        }
    }

    set maxQty(value){
        // do nothing
    }

    /**
     * Gets the part number description
     * @returns {string}
     */
    get description(){
        if (this._orderPart != null){
            return this._orderPart.description;
        }
        else{
            return '';
        }
    }


    get branchName(){
        if (this._orderPart != null){
            return this._orderPart.branchName;
        }
        else{
            return '';
        }
    }

    get branchId() {

        if (this._orderPart != null) {
            return this._orderPart.branchId;
        } else {
            return '';
        }
    }


    /**
     * OBSOLETE!
     * @returns {*[]}
     */
    get branches() {
        Tracer.current.info("E9C06T-(order_item): use GetBranches() instead and copy the value locally as as calcuations must be done each time is invoked");
        return this.getBranches();
    }

    /**
     * Get the list of available branches
     * @returns {*[]}
     */
    getBranches() {

        // empty branch if there is not part
        if (this._orderPart == null || this._orderPart.branches == null) {
            return [];
        }

        if (this._orderPart.branches.length === 0){
            return [];
        }

        let tmpBranch = [];
        const homeBranchId = this._orderPart.branches[0].id;

        /**
         * all branches except those that are only for emergency orders
         */
        if (!this._isEmergencyOrder && !window.config.excludeZeroInventoryBranches) {
            Tracer.current.debug("3CG9LR-(order item):[BRANCHES]: All branched returned. Exclude zero inventory branches configuration is off.");

            this._orderPart.branches.forEach((b) => {
                if (!b.emergencyOnly) {
                    tmpBranch.push(b);
                }
            });

            return tmpBranch;
        }

        /**
         * Only the branches that matches the emergency order assigned branch
         */
        if (this._isEmergencyOrder) {
            this._orderPart.branches.forEach((b) => {

                if (b.id === this._emergencyOrderBranchId) {
                    tmpBranch.push(b);
                }
            });

            return tmpBranch;
        }

        /**
         * Only the home branch
         */
        if (this._orderPart.homeBranchOnly) {

            this._orderPart.branches.forEach((b) => {

                if (b.id === homeBranchId) {
                    tmpBranch.push(b);
                }
            });

            return tmpBranch;
        }

        /**
         * Home branch plus all branches that has inventory excluding branches that are only for emergency orders.
         */
        this._orderPart.branches.forEach((b) => {

            if ((b.qty > 0 || b.id === homeBranchId) && !b.emergencyOnly) {
                tmpBranch.push(b);
            }
        });


        return tmpBranch;
    }


    /**
     * Returns all available branches without distiction if the branch is only for emergency order
     * or does not have stock.
     * @returns {*[]}
     */
    getEmergencyOnlyBranches() {


        // empty branch if there is no a part
        if (this._orderPart == null || this._orderPart.branches == null) {
            return [];
        }

        if (this._orderPart.branches.length === 0){
            return [];
        }

        let tmpBranch = [];
        this._orderPart.branches.forEach((b) => {
            if (b.emergencyOnly) {
                tmpBranch.push(b)
            }
        });

        return tmpBranch;
    }
    /**
     * Gets whether the current part is restricted from making a back order
     * @returns {*|boolean|boolean}
     */
    get isBackOrderRestricted() {

        if (this._orderPart == null) {
            return false;
        }

        if (this._orderPart.backOrderRestricted){
            return true;
        }


        return false;
    }

    /**
     * Gets the price of this part.
     * @returns {string|*|string|number}
     */
    get price(){
        if (this._orderPart != null){
            return this._orderPart.price;
        }
        else{
            return '';
        }
    }

    /**
     * Gets the core price of this part.
     * @returns {string|*|string|string}
     */
    get corePrice(){
        if (this._orderPart != null){
            return this._orderPart.corePrice;
        }
        else{
            return '';
        }
    }

    get extendedPrice(){
        if (this._orderPart != null){
            return this._orderPart.extendedPrice;
        }
        else{
            return '';
        }
    }

    /**
     * Gets the total prices.
     * This is the sum of the part price plus core price, then multiplied by the quantity.
     * @returns {number}
     */
    get totalPrice(){
        if (this._orderPart != null){
            return (this._orderPart.price + this._orderPart.corePrice) * this.quantity;
        }
        else{
            return 0;
        }
    }

    /**
     * Gets the quantity of this part that is currently in stock
     * @returns {number|*|number|number}
     */
    get qtyInStock(){
        return this._calculateInventory();
    }

    /**
     * Gets the quantity in stock in the branch assigned for emergency only.
     * @returns {number}
     */
    get qtyInStockInEmergencyOnlyBranch(){
        return this._calculateEmergencyBranchStock();
    }


    /**
     * Gets `true` if this part is in stock, otherwise `false`
     * @returns {boolean|*|boolean}
     */
    get inStock(){

        const qtyInStock = this._calculateInventory();
        return (qtyInStock > 0)
    }

    get isServicePart() {

        if (this._orderPart != null) {
            return this._orderPart.isServicePart;
        }
        else{
            return false;
        }
    }

    get imageUri(){
        if (this._orderPart != null){
            return this._orderPart.imageUri;
        }
        else{
            return '';
        }
    }

    get cartItemId(){
        if (this._orderPart != null){
            return this._orderPart.cartItemId;
        }
        else{
            return null;
        }
    }

    /**
     * Gets `true` if this part is only for view (read only), otherwise `false`
     * @returns {*|boolean|null}
     */
    get viewOnly(){
        if (this._orderPart != null){
            return this._orderPart.viewOnly;
        }
        else{
            return null;
        }
    }

    get hasClosestBranchSelected() {
        return this._hasClosestBranchSelected;
    }

    get isBranchSelectedOutOfStock(){
        return this._isBranchSelectedOutOfStock;
    }

    /**
     * Returns `true` if this part is loading from the server, otherwise `false`
     * @returns {boolean}
     */
    get loading() {
        return this._loading;
    }

    /**
     * Gets the inventory quantity of the current branch
     * @returns {number}
     */
    get branchInventory() {
        const selectedBranch = this.getSelectedBranch();

        if (selectedBranch == null){
            Tracer.current.debug("150956-(part item): Inventory set to 0 (zero) because we can't determine the selected branch.");
            return 0;
        }

        let branchQty = selectedBranch.qty;
        Tracer.current.debug(`EWCS8S-(part item):[PART=${this.partNumber}]:[SELECTED_BRANCH=${selectedBranch.id}]:[QTY=${branchQty}]`);
        return branchQty;

    }

    getImages(){
        if (this._orderPart != null){
            let images = [];

            for (let i = 0; i < this._orderPart.images.length; i++) {
                if (this._orderPart.images[i] == null || this._orderPart.images[i].previewUri === '') continue;
                if (this._orderPart.images[i] == null || this._orderPart.images[i].thumbnailUri === '') continue;

                images.push(this._orderPart.images[i]);
            }

            return images;
        }
        else{
            return [];
        }
    }

    getInventory(){
        if (this._orderPart != null){
            return this.getBranches();
        }
        else{
            return [];
        }
    }

    reload(){
        if (this._partNumber != null && this._partNumber.length === 0){
            return;
        }

        this.load(this._partNumber);
    }

    setPart(partNumber, quantity){

        if (partNumber == null){
            return;
        }

        this.quantity = quantity;
        this.load(partNumber);

    }

    /**
     * Sets the part object bypassing loading from the server.
     *
     * @param part - the part object as it is defined in the catalog service (API).
     */
    setPartObject(part){

        if (part == null) {
            return;
        }

        this._partNumber = part.partNumber;
        this._setPartInfo(part);
        this._defaultBranch(part);
        this.quantity = part.qty;
        this._state = OrderPartItem._SUCCESS;

    }

    /**
     * Loads this part information from the server.
     * @param partNumber
     * @param cb
     */
    load(partNumber, cb){

        if (partNumber == null || partNumber.length === 0){
            return;
        }

        let vm = this;
        this._loading = true;
        this._partNumber = partNumber;

        try {

            this._catalogSearch.getPartInfoRaw(partNumber).then(data => {

                if (data != null) {
                    let part = data.parts[0];

                    if (part != null) {

                        vm._setPartInfo(part);
                        vm._defaultBranch(part);

                        if (vm._isEmergencyOrder){

                            if (vm._emergencyOrderBranchId == null ){
                                throw new Error("3YQCOH-(part_item)[assertion_error]: Emergency Order Branch Id not defined!");
                            }

                            vm._loadFulfillmentInfo(partNumber, vm._emergencyOrderBranchId).then((info) => {

                                        if (info != null){
                                            Tracer.current.debug("CZIS9Z-(part item)[fulfillment_info_success]");
                                        }
                                        else {
                                            Tracer.current.warn(`9QDB0K-(part item)[fulfillment_info_no_data_returned][fail][part=${partNumber}]`);
                                        }

                                        vm._state = OrderPartItem._SUCCESS;
                                        vm._fulfillmentInfo = info;

                                        if (cb != null) {
                                            cb(part);
                                        }

                                        this._loading = false;

                            }).catch(err => {

                                Tracer.current.warning("F4KHYH-(part_item): Failed to load the projected position and the prior replenish info");
                                vm._state = OrderPartItem._SUCCESS;
                                vm._fulfillmentInfo = null;
                                if (cb != null) {
                                    cb(part);
                                }

                                this._loading = false;

                            });
                        }
                        else {

                            vm._state = OrderPartItem._SUCCESS;

                            if (cb != null) {
                                cb(part);
                            }

                            this._loading = false;
                        }

                    }
                } else {
                    this._state = OrderPartItem._NOT_FOUND;
                    this._loading = false;
                }

            }).catch(err => {
                this._loading = false;
                Tracer.current.error(err);
                throw err;
            });
        }
        catch(err){

            this._state = OrderPartItem._ERROR;
            Tracer.current.error(`3HTIAT-(part item): Error loading information for part number ${this.partNumber}`);
            Tracer.current.error(err);

        }
    }

    /**
     * Load the fulfillment information (build time, project order position) for the current branch.
     * @return {Promise<void>}
     */
    loadFulfillmentInfo() {

            if (StringUtils.isEmptyOrNull(this.partNumber)) {
                return;
            }
            const currentBranch = this.branchId;

            const vm = this;

            vm._fulfillmentInfoLoading = true;

            this._loadFulfillmentInfo(this.partNumber, currentBranch).then((info) => {

                if (info == null) {
                    Tracer.current.warn(`7WFS300-(part_item)[part_number=${this.partNumber}]: Failed to load the build time.`);

                } else {
                    vm._fulfillmentInfo = info;

                }

                vm._fulfillmentInfoLoading = false;

            }).catch(err => {

                Tracer.current.warning("CLNT524-(part_item): Failed to load the build time and the prior replenish info");
                Tracer.current.error(err);
                vm._fulfillmentInfo = null;
                vm._fulfillmentInfoLoading = false;

            });


    }

    get isSuccess(){
        return this._state === OrderPartItem._SUCCESS;
    }

    get isError(){
        return this._state === OrderPartItem._ERROR;
    }

    get notFound(){
        return this._state === OrderPartItem._NOT_FOUND;
    }

    get isBlank(){
        return this._state === OrderPartItem._UNINITIALIZED;
    }

    /**
     * Sets the selected branch
     * @param branchId
     */
    setSelectedBranchId(branchId){

        if (this._orderPart == null){
            return;
        }

        let index = this._orderPart.setBranch(branchId);
        this._hasClosestBranchSelected = index === this._autoSelectedBranchIndex;

        // On an emergency order the system will select a pre-determined branch
        if (this._isEmergencyOrder){
            return;
        }

        // When a part is retricted by a rule, then the system will pre-determine the branch
        if (this._orderPart.homeBranchOnly){
            return;
        }

        if (!this._hasClosestBranchSelected){
            this._checkAnotherBranchStock();
        }
        else{
            this._isBranchSelectedOutOfStock = false;
        }
    }


    /**
     * Updates the branch inventory.
     * @param branchId
     * @param newQty
     */
    updateBranchQuantity(branchId, newQty){

        if (this._isEmergencyOrder){
            Tracer.current.debug("#321KMT:[EMERGENCY_ORDER]:[UPDATE_BRANCH_QUANTITY_NOT_ALLOWED]: Emergency order does not allow to update branch quantity.")
            return;
        }

        let branch = this.getBranch(branchId);
        branch.originalQty = branch.qty;
        branch.qty = newQty;

        // now let's reset the default branch if the branch currently selected does not have any inventory.
        this._defaultBranch(this._orderPart);
    }

    getSelectedBranch(){

        if (this._orderPart == null){
            return null;
        }

        return this._orderPart.getSelectedBranch();
    }


    getBranch(branchId){
        return this._orderPart.getBranch(branchId);
    }

    clear(){
        this.quantity = 1;
        this._orderPart = null;
        this._partNumber = null;
        this._fulfillmentInfo = null;
        this._state = OrderPartItem._UNINITIALIZED;
    }

    /* *********************************************************************************************
     * PRIVATE METHODS
     * *********************************************************************************************/


    _setPartInfo(part){

        if (part == null){
            Tracer.current.warning('8PHSDE-(part_item): Part is not an object.');
            return;
        }

        this._orderPart = new OrderPart(part);
        this._partNumber = part.partNumber;

        // if view only, set the quantity as 0.
        if (this._orderPart.viewOnly === true){
            this.quantity = 0;
        }
    }

    /**
     * Sets the default branch
     * @param part
     * @private
     */
    _defaultBranch(part) {

        if (part == null) return;
        const tmpBranches = this.getBranches();

        if (tmpBranches.length === 0) {
            Tracer.current.warn('573641-(BRANCHES_MISSING): Branches are missing!');
            return;
        }

        if (this._isEmergencyOrder){
            Tracer.current.debug(`40Z0BG-(EMERGENCY_ORDER):[SET_DEFAULT_BRANCH:${this._emergencyOrderBranchId}]`)
            this._orderPart.setBranch(this._emergencyOrderBranchId);
            return;
        }


        if (part.homeBranchOnly) {

            this._orderPart.setBranch(OrderPartItem._HOME_BRANCH);
            Tracer.current.debug(`40Z0BG-(default branch):[HOME_BRANCH_ONLY]:[BRANCH=${OrderPartItem._HOME_BRANCH}]`);
            return;
        }

        // if home branch contains a quantity, then just leave.
        if (tmpBranches[0].qty > 0) return;

        // otherwise. look for a branch that has qty.
        for (let i = 1; i < tmpBranches.length; i++) {

            if (tmpBranches[i].qty > 0) {

                this._autoSelectedBranchIndex = this._orderPart.setBranch(tmpBranches[i].id);
                this._hasClosestBranchSelected = true;

                return;
            }

        }
    }

    /**
     * Checks if another branch has this part number in stock
     * @private
     */
    _checkAnotherBranchStock(){

        const selectedBranch = this.getSelectedBranch();

        if (selectedBranch != null && selectedBranch.qty > 0){
            this._isBranchSelectedOutOfStock = false;
            return;
        }

        const tmpBranches = this.getBranches();
        for(let i = 0; i < tmpBranches.length; i++){

            let tmpBranch = tmpBranches[i];

            if (tmpBranch.qty > 0){
                this._isBranchSelectedOutOfStock = true;
                return;
            }
        }

    }

    /**
     * Load in paralled the projected position for this part number and the prior replenish count
     * @param partNumber
     * @param branch
     * @returns {Promise<PartFulfillmentInfo>}
     * @private
     */
    _loadFulfillmentInfo(partNumber, branch) {

        if (StringUtils.isEmptyOrNull(partNumber)) {
            throw new Error("EYJ2666-(fulfillment_info)[empty_argument]: Part number is empty");
        }

        if (StringUtils.isEmptyOrNull(branch)) {
            throw new Error("9WNA666-(fulfillment_info)[empty_argument]: Branch is empty");
        }

        try {

            const vm = this;

            const p1 = new Promise((resolve, reject) => {

                vm._catalogSearch.getProjectedOrderPosition(partNumber, branch).then(data => {

                    let tmpPosition = -1;
                    if (data != null) {
                        tmpPosition = data;
                    }

                    resolve(tmpPosition);

                }).catch(err => {
                    Tracer.current.error("2ETD691-(fulfillment_info): Error getting the projected order position.");
                    Tracer.current.error(err);
                    reject(err);
                });
            });

            const p2 = new Promise((resolve, reject) => {

                vm._catalogSearch.getReplenishCount(partNumber, branch).then(data => {
                    if (data != null) {
                        resolve(data);
                    }
                    else{
                        resolve(null);
                    }

                }).catch(err => {
                    Tracer.current.error("1GZP669-(fulfillment_info): Error getting the replenish counts.");
                    Tracer.current.error(err);
                    reject(err);
                });
            });

            return new Promise((resolve, reject) => {

                Promise.all([p1, p2]).then(results => {

                    if (results.length !== 2){
                        // just check if js engine is returned the right number of results based on the promises given.
                        throw new Error("6FXV932-(fulfillment_info)[promise_all_error]: Expected number of promise not returned. We expect the right number from Javascript!");
                    }

                    const tmpPosition = results[0];
                    const tmpReplenishCount = results[1];

                    if (tmpPosition === -1){
                        Tracer.current.warn("5U9C809-(fulfillment_info): Unable to get the order position.");
                        reject(null);
                        return;
                    }

                    if (tmpReplenishCount === null){
                        Tracer.current.warn("1IPT725-(fulfillment_info): Unable to get the replenishment count (prior count)");
                        reject(null);
                        return;
                    }

                    const info = new PartFulfillmentInfo(tmpPosition, tmpReplenishCount);
                    resolve(info);

                }).catch( (err) => {
                    Tracer.current.error("FLIA165-(fulfillment_info): Unable to get the fullfilment data");
                    reject(null);
                });
            });

        } catch (e) {
            Tracer.current.error("8ATP100-(fulfillment_info): General error.");
            Tracer.current.error(e);
        }

    }

    /**
     * Calculates the inventory by adding the inventory on each branch
     * @returns {number}
     * @private
     */
    _calculateInventory() {

        const tmpBranches = this.getBranches();

        if (tmpBranches.length === 0) return 0;

        let total = 0;

        tmpBranches.forEach(function (branch) {
                total = total + branch.qty;
            }
        );

        return total;
    }

    /**
     * Calculate the global total inventory for all branches available
     * without distinction if the branch is only for emergency orders or not.
     * @returns {number}
     * @private
     */
    _calculateEmergencyBranchStock() {

        const tmpBranches = this.getEmergencyOnlyBranches();

        if (tmpBranches.length === 0) return 0;

        let total = 0;

        tmpBranches.forEach(function (branch) {
                total = total + branch.qty;
            }
        );

        return total;
    }

}