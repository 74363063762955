
export class _Constants {

    /**
     * The default max quantity allowed for non emergency orders
     * @type {Number}
     */
    static  DEFAULT_MAX_QTY = 50;

    /**
     * The default max quantity allowed for emergency orders
     * @type {Number}
     */
    static DEFAULT_EMERGENCY_MAX_QTY = 1;
}