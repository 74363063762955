import {PdfDocumentService} from '@api/modules/pdfDocument/PdfDocumentService';
import {StoreProxy} from "@api/common/StoreProxy";

export default {
    name: "StatementPdfViewer",
    data() {
        return {
            isLoading: false,
            existPdf: false,
            pdfUrl: '',
            sessionId: '',
            instanceId: ''
        }
    },
    created(){
        this.auth = StoreProxy.getAuth(this.$store);
        this._pdfService = new PdfDocumentService(this.auth);
    },
    mounted() {
        this.instanceId = this.$instanceId;
        this.sessionId = this.auth.sessionId;

        let vm = this;
        this.checkPdf(() => {
            vm.isLoading = false;
        });
    },

    methods: {
        checkPdf(onReady) {

            let url = this._pdfService.statementPdfUrl(this.sessionId, this.instanceId);

            let vm = this;
            this._pdfService.existsStatementPdf(result => {
                vm.existPdf = result;
                if (result) {
                    vm.pdfUrl = url;
                }

                onReady();
            })

        },

    }
}