import {OrderHistoryService} from '@api/modules/orderHistory/services/OrderHistoryService';
import {OrderDetails} from "@api/modules/orderHistory/OrderDetails";
import {StoreProxy} from "@api/common/StoreProxy";
import {Tracer} from "@api/common/Tracer";

export default {
    name: "OrderDetailView",
    data() {
        return {
            order: {},
            shippingTrackingUrl: "",
            isLoading: false,
            error: false
        }
    },
    props: {
        id: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: ""
        }
    },

    computed:{

        notes(){
            if (!this.order || !this.order.notes)return [];
            return this.order.notes;
        },
        totalColSpan(){

            if (this.type === 'open' || this.type === 'openOrders')
            {
                return 10;
            }
            else{
                return 9;
            }

        },
        showShippedQuantityColumn(){
            return (this.type === 'open' || this.type === 'openOrders');
        },
        showInvoiceHeader(){
            return !(this.type === 'open' || this.type === 'openOrders');
        },
        hasTrackingInfo(){
            return this.shippingTrackingUrl !== '';
        }

    },
    created() {
        this._auth = StoreProxy.getAuth(this.$store);
        this._orderHistoryService = new OrderHistoryService(this._auth);
    },
    mounted() {
        this.loadOrder(this.type, this.id);
    },
    filters:{
      empty : function(value){
          if (!value) return 'N/A';
          if (value.length === 0) return 'N/A';
          return value;
      }
    },
    methods: {
        print(){
            window.print();
        },
        loadOrder(type, identifier){

            const orderDetails = new OrderDetails(this._auth);
            const vm = this;

            let searchType = "";
            let promise = null;

            this.isLoading = true;


            if (type === 'invoice'){
                const invoiceNumber = identifier;

                window.tracer.debug(`953092-(order_details)[invoice=${invoiceNumber}]: Loading invoice.`);
                searchType = OrderHistoryService.Invoice;
                promise = orderDetails.getInvoiceDetails(invoiceNumber);

                this._orderHistoryService.getShippingTrackingInfo(invoiceNumber).then(trackingInfo => {

                    if (trackingInfo != null){
                        vm.shippingTrackingUrl = trackingInfo.url;
                    }
                    else {
                        vm.shippingTrackingUrl = '';
                    }

                }).catch(err => {
                    vm.shippingTrackingUrl = '';
                    window.tracer.error(`9UNQVS-(order_details)[invoice=${invoiceNumber}]: Error getting the shipping tracking information.`);
                });

            }

            if (type === 'credits' || type === 'returns'){

                const orderNumber = identifier;
                window.tracer.debug(`888950-(order_details): loading order details for credit ${orderNumber}`);
                searchType = OrderHistoryService.Credits;
                promise = orderDetails.getCreditsDetails(orderNumber);
            }

            if (type === 'open' || type === 'openOrders') {

                const orderNumber = identifier;
                searchType = OrderHistoryService.Open;
                promise = orderDetails.getOpenOrderDetails(orderNumber);
            }

            if (!promise){
                throw new Error('229138-(order_details): Expected a promise. We got un undefined value!');
            }

            promise.then(order => {

                this.order = order;
                this.isLoading = false;

            }).catch(err => {

                this.error = true;
                Tracer.current.error('276167-(order_details): error retrieving order');
                Tracer.current.error(err);
                this.isLoading = false;

            });


        },
        orderStatusDescription(status){
            if (status === 'Complete')return 'Completed';
            if (status === 'Picked')return 'Picked';
            if (status === 'Shipped')return 'Shipped';
            if (status === 'CreditHold')return 'Credit Hold';
            if (status === 'Delivered')return 'Delivered';
            if (status === 'Undefined')return '';
        },
        trackPackage(){
            window.open(this.shippingTrackingUrl, '_blank');
        },
        fireCloseEvent() {
            this.$emit('close');
        }

    }

}
