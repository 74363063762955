/**
 * Represents the different notification types.
 */
export class NotificationTypes {

    static get INVOICE() {
        return 'Invoice';
    }

    static get SHIPPING(){
        return 'Shipping';
    }

    static get ORDER(){
        return 'Order';
    }
}