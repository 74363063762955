
import {Tracer} from "@api/common/Tracer";
import {StringUtils} from "@api/common/StringUtils";

/**
 * Represents the list of options for Year, Make, Model, etc.
 */

/* @todo: Change the name of this class. The term "SelectModel" is confusing because it may give the impression that it
          refers to choosing a vehicle model, instead of the process of selecting an item.
   @work-item: AER-807
 */
export class SelectModel {

    constructor() {

        // Holds the list of options for this particular part catalog criteria
        this._options = [];

        // Holds the children `SelectModel` for this instance
        this._children = [];

        this._itemSelected = null;
        this._selectedValueCallback = null;

        this.fetchOptionsX = this.fetchOptions.bind(this);

        this._firstOptionItem =  null;
    }

    getChildrenOptions(index){
        if (this._children.length - 1 >= index){
           return this._children[index].getOptions();
        }

        return null;
    }

    getOptions(){
        return this._options;
    }

    /**
     * Register the call back that will receive the value (text) that was selected
     * @param func
     */
    registerSelectedValueCallback(func){
        if (func != null){
            this._selectedValueCallback = func;
        }
    }

    getSelectedItem() {
        return this._itemSelected;
    }

    getFirstItem() {
        return this._firstOptionItem;
    }

    /**
     * Gets the id of the selected item
     * @returns {string|null|*}
     */
    getSelectedId() {

        if (this._itemSelected == null) {
            Tracer.current.debug("4MNKJN-(select_model): No item selected");
            return '';
        }
        return typeof this._itemSelected.id == 'undefined' ? this._itemSelected : this._itemSelected.id;
    }

    /**
     * Gets the name of the selected item
     * @returns {string|null|*}
     */
    getSelectedName() {

        if (this._itemSelected == null) return '';
        return typeof this._itemSelected.name == 'undefined' ? this._itemSelected : this._itemSelected.name;
    }


    /**
     * Returns the number of options on this instance
     * @returns {number}
     */
    get count(){
        return this._options.length;
    }

    /**
     * Gets the array of options
     * @returns {[]}
     */
    get options() {
        return this._options;
    }

    /**
     * Sets the available values
     * @param values
     */
    setOptions(values){

        if (values == null){
            this._options = [];
        }
        else{

            this._options = [];
            for(let i = 0; i < values.length; i++){

                let _id = (values[i].id) ? values[i].id : values[i];
                let _name = (values[i].name) ? values[i].name : values[i];
                this._options.push(
                    {
                        id : _id,
                        name: `${_name}`
                    }
                );
            }

            this._firstOptionItem = this._options[0];

        }

    }


    /**
     * Clear all values
     */
    clear() {
        this._selected = '';
        this._options = [];
        this._itemSelected = null;
        this.__fireSelectedValueCallback('');
    }

    /**
     * Find a value in the list of options
     * @param value
     * @returns {null|*}
     */
    findValue(value){
        for (let i = 0; i < this._options.length; i++) {

            if (this._options[i].name === value) {
                return this._options[i];
            }
        }

        return null;
    }

    setSelectedItem(item) {

        if (item == null) {
            this._itemSelected = null;
            Tracer.current.debug("EJIUVE-(select_model): no value selected.");
            return false;
        }

        // it must match one of the available options.

        for (let i = 0; i < this._options.length; i++) {

            if (this._options[i].id === item.id) {
                this._itemSelected = this._options[i];

                Tracer.current.debug(`DI2AZG-(select_model): match found for value ${item.id}`);
                this.__fireSelectedValueCallback(this.getSelectedName());
                return true;
            }
        }

        
        Tracer.current.debug("56ORIO (selections): match not found for value.");
        return false;

    }

    setSelectedValue(value) {

        if (StringUtils.isEmptyOrNull(value)) {
            this._itemSelected = null;
            Tracer.current.debug("EJIUVE (selections): no value selected.");
            return false;
        }

        // it must match one of the available options.

        for (let i = 0; i < this._options.length; i++) {

            if (this._options[i].id === value) {
                this._itemSelected = this._options[i];

                Tracer.current.debug(`3OY8N6 (selections): match found for value ${value}`);
                this.__fireSelectedValueCallback(this.getSelectedName());
                return true;
            }
        }


        Tracer.current.debug("56ORIO (selections): match not found for value.");
        return false;

    }
    
    setChildren(children) {

        if (!(children instanceof Array)) {
            // not an array, do not add.
            return;
        }

        this._children = children;
    }

    resetChildren() {

        this._children.forEach((el) => {
            el._itemSelected = null;
            el._options = [];
            el.__fireSelectedValueCallback('');
        });
    }

    fetchOptions(searchString, callback) {

        if (searchString == null || searchString === ''){
            callback(this._options);
            return;
        }

        let values = this._options.filter(this.filterOptions(searchString));

        if (values.length >= 1) {
            this._firstOptionItem = values[0];
            callback(values);
        }
        else{
            this._firstOptionItem = null;
            callback(this._options);
        }

    }

    filterOptions(searchString) {

        return (item) => {
            return item.name.toLowerCase().indexOf(searchString.toLowerCase()) === 0;
        }

    }

    /**
     *
     * @param value
     * @private
     */
    __fireSelectedValueCallback(value){
        if (this._selectedValueCallback != null){
            this._selectedValueCallback(value);
        }
    }
}

