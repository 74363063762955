import {StringUtils} from "@api/common/StringUtils";
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

export default {
    name: "InvoiceHistoryGrid",
    props: {
        orders: {
            type: Array,
            required: false,
            default: function () {
                return [];
            }
        },
        results: {
            type: Object,
            required: false,
            default: function () {
                return null;
            }
        },
        type: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            isLoading: false,
        }
    },
    components: {
        'popper': Popper
    },
    computed: {
        items() {
            return this.results.items;
        },
        currentPage() {
            if (this.results == null){
                return 0;
            }
            else {
                return this.results.currentPage;
            }
        },
        totalRecords() {
            if (this.results == null){
                return 0;
            }
            else {
                return this.results.totalRecords;
            }
        }
    },
    mounted() {

        const vm = this;
        if (this.results.currentPage > 0){
            this.results.page(this.results.currentPage).then(function (data) {
                vm.isLoading = false;
            });
        }
    },
    filters: {
        empty: function (value) {

            if (value.length === 0) {
                return 'N/A';
            }

            return value;
        }
    },
    methods: {
        showDetails(orderNumber, invoiceNumber) {

            this.$emit("details", {
                orderNumber: orderNumber,
                invoiceNumber: invoiceNumber,
                branch: this.branch
            });

        },
        viewDocument(orderNumber, invoiceNumber) {
            this.$emit("document", {
                orderNumber: orderNumber,
                invoiceNumber: invoiceNumber
            });
        },
        showTrackingInfo(carrierUrl) {
            if (!StringUtils.isEmptyOrNull(carrierUrl)) {
                window.open(carrierUrl, '_blank');
            }
        },
        pageChange(page) {
            const vm = this;
            this.isLoading = true;
            this.results.page(page).then(function (data) {
                vm.isLoading = false;
            });
        },
        prevPage(page) {
            this.pageChange(page);
        },
        nextPage(page) {
            this.pageChange(page);
        }

    }

}
