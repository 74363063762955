import {AbbrevMapItem} from "./AbbrevMapItem";

export const CanadaProvinceList : AbbrevMapItem[] = [
    { abbrev:'AB', name: 'Alberta' },
    { abbrev:'BC', name: 'British Columbia' },
    { abbrev:'MB', name: 'Manitoba' },
    { abbrev:'NB', name: 'New Brunswick' },
    { abbrev:'NL', name: 'Newfoundland and Labrador' },
    { abbrev:'NT', name: 'Northwest Territories' },
    { abbrev:'NS', name: 'Nova Scotia' },
    { abbrev:'NU', name: 'Nunavut' },
    { abbrev:'ON', name: 'Ontario' },
    { abbrev:'PE', name: 'Prince Edward Island' },
    { abbrev:'QC', name: 'Quebec' },
    { abbrev:'SK', name: 'Saskatchewan' },
    { abbrev:'YT', name: 'Yukon' },

    // @note: not a province, the DTS API returns CANADA in some occasions.
    { abbrev:'*CA', name: 'Canada' }
];