import {UserManagementService} from "./services/UserManagementService";
import {
    SETTINGS_BACKORDER_CONFIRMATION_MESSAGE_KEY,
    SETTINGS_BACKORDER_SHOPPING_CART_MESSAGE_KEY,
    SETTINGS_BRANCH_SELECTED_MESSAGE_KEY,
    SETTINGS_COMPANY_PHONE_NUMBER
} from "@/constants";

/**
 * Represents the company settings.
 * This is a key/value collection.
 * The values are loaded from the server.
 */
export class CompanySettings {

    constructor(auth, companyId){

        if (!auth){
          throw new Error('[e780807] auth info is required');
        }

        if (!companyId){
            throw new Error('[e277363] companyId info is required');
        }

        this._auth = auth;
        this.companyId = companyId;
        this.dictionary = {};
        this.settings = [];
    }


    load(){

        return new Promise((resolve) => {
            let service = new UserManagementService(this._auth);

            service.getCompanySettings(this.companyId, data => {
                this.settings = data;

                this.settings.forEach( e => {
                    this.dictionary[e.key] = e;
                });

                resolve();
            });
        });


    }

    getSetting(key){

        let setting = this.dictionary[key];
        if (setting == null){
            console.warn(`settings ${key} not found for this company instance`);
            return '';
        }
        else
        {
            return setting.value;
        }
    }

    static getCompanyInstance($store){

        if ($store == null) return null;

        let settings = $store.getters.getCompanySettings;

        if (settings == null){
            console.warn('[w993427] company settings not found on the vuex store!.');
            return;
        }

        return settings;
    }

    static getCompanySetting($store, settingKey){

        if ($store == null) return null;

        let settings = $store.getters.getCompanySettings;

        if (settings == null){
            console.warn('[w993427] company settings not found on the vuex store!.');
            return;
        }

        /* @fix - Error when using 'TestAccount' */
        let value = settings.getSetting(settingKey);

        if (value == null){
            return '';
        }

        return value;
    }

    static getBackOrderMessage($store){

        if ($store == null) return "";
       return CompanySettings.getCompanySetting($store, SETTINGS_BACKORDER_SHOPPING_CART_MESSAGE_KEY);

    }

    static getBackOrderConfirmationMessage($store){

        if ($store == null) return "";
        return CompanySettings.getCompanySetting($store, SETTINGS_BACKORDER_CONFIRMATION_MESSAGE_KEY);
    }

    static getClosestBranchSelected($store){

        if ($store == null)return '';
        return CompanySettings.getCompanySetting($store, SETTINGS_BRANCH_SELECTED_MESSAGE_KEY);
    }

    static getPhoneNumber($store){

        if ($store == null) return "";
        return CompanySettings.getCompanySetting($store, SETTINGS_COMPANY_PHONE_NUMBER);

    }







}
