/**
 * Collection of string utility methods.
 */
export class StringUtils {

    constructor() {

    }

    /**
     * Compares two string ignoring case
     * @param strA
     * @param strB
     * @returns {boolean}
     */
    static compareIgnoreCase(strA, strB) {
        return typeof (strA) === 'string' && typeof (strB) === 'string'
            ? strA.localeCompare(strB, undefined, {sensitivity: 'accent'}) === 0
            : strA === strB;
    }

    /**
     * Checks if a string is empty or null
     * @param str
     * @returns {boolean}
     */
    static isEmptyOrNull(str) {

        if (str === null) return true;

        if (typeof str === "string") {
            return (str === "");
        }

        return true;
    }

    /**
     * Checks if a string is empty, or empty spaces or null
     * @param str
     * @returns {boolean}
     */
    static isEmptySpacesOrNull(str) {

        if (str === null) return true;

        if (typeof str === "string") {
            return (str.trim() === "");
        }

        return true;
    }

    /**
     * returns the length of a string.
     * If the argument is not a string, then it will return -1;
     * @param str
     * @returns {number}
     */
    static len(str) {
        if (str === null) return 0;

        if (typeof str === "string") {
            return str.length;
        }

        return -1;
    }

    static listToArray(str){
       if (str === null || str === "") return [];
       return str.split(",");
    }

    static existsInList(strList, text){

        if (strList === null){
            return false;
        }

        let list = this.listToArray(strList);

        if (list.length === 0){
            return false;
        }

        for(let i = 0; i < list.length; i++){
            let tmpValue = list[i].trim();
            if (StringUtils.compareIgnoreCase(tmpValue, text)){
                return true;
            }
        }

        return false;
    }

}