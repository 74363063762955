import {IAnalytics} from "@/lib/analytics/IAnalytics";
import {PostHogAnalytics} from "@/lib/analytics/_internals/PostHogAnalytics";

export class Analytics {

    /**
     * Gets the current tracer instance.
     * @returns {AppConfig|*}
     */
    static get current(): IAnalytics {

        if ((<any>window).__analytics_instance == null) {
            (<any>window).__analytics_instance = new PostHogAnalytics();
        }

        return (<any>window).__analytics_instance;
    }
}


