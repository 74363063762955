export class OrderPartWarrantyViewer {

    constructor() {
        this.items = [];
        this.visible = false;
        this.detailsVisible = false;

    }

    get hasWarranties() {
        return this.items.length > 0;
    }

    setWarrantyList(list) {
        if (list == null) return;
        this.items = list;
    }

    open(){
        this.visible = true;
    }

    close() {
        this.visible = false;
    }

    openDetails(){
        this.detailsVisible = true;
    }

    closeDetails(){
        this.detailsVisible = false;
    }
}