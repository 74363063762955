import {UserManagement} from "@/api/modules/userManagement/UserManagement";
import {UserPermissions} from "@api/core/UserPermissions";
import {StoreProxy} from "@/api/common/StoreProxy";

export default {
    name: "ImpersonateAccount",
    data() {
        return {
            selectedAccount: '',
            isAccountLoading: false,
            enabled: false
        }
    },
    created(){
        this._userManagement = new UserManagement(StoreProxy.getAuth(this.$store));
    },
    mounted() {

        this.enabled = UserPermissions.canImpersonate(this.$store);
    },
    methods: {
        /**
         * search account. To be used with the autocompletion field
         * @param searchString
         * @param cb
         */
        searchAccounts(searchString, cb) {

            if (searchString == null){
                cb([]);
                return;
            }

            if (searchString.length === 0){
                cb([]);
                return;
            }

            this.isAccountLoading = true;
            let vm = this;
            this._userManagement.searchAccounts(searchString).then(accounts => {

                if (accounts == null){
                    window.tracer.warn("[w831289] result returned is null (expected a valid array)");
                }

                cb(accounts);
                vm.isAccountLoading = false;
            }).catch(e => {

                    vm.isAccountLoading = false;
                    window.tracer.error("[e610224] Error getting accounts")
                    if (e != null) {
                        window.tracer.error(e);
                    }


                    cb([]);
                }
            );
        },
        onSelectedAccount(item){
          this.selectedAccount = item;
        },
        onImpersonate(){

            window.tracer.debug(`[d478251] account selected: ${this.selectedAccount}`);

            let loading = this.showLoadingNotice(this.selectedAccount);
            this._userManagement.impersonateAccount(this.selectedAccount).then(impersonatedAccount => {

                if (impersonatedAccount == null){
                    // shouldn't happen, but it can.
                    window.tracer.warn('[w844355] Unexpected null instance returned when impersonating an account');
                    loading.close();
                    this.$notify.error({
                        title: 'Failed to change account',
                    });

                    return;
                }

                StoreProxy.setImpersonation(this.$store, impersonatedAccount.account, impersonatedAccount.name);

                loading.close();
                const h = this.$createElement;
                this.$msgbox({
                    title: 'Account Succesfully Changed',
                    message: h('p', null, [
                        h('div', {style: 'font-size: 1.2em'}, [ h('span', {style: 'font-size: 1.2em'}, 'Account Changed to '), h('span', { style: 'color: black;font-weight:bold' }, `${impersonatedAccount.account}`)]),
                        h('div', {style: 'font-size: 1.2em;font-weight:bold;color:black'}, `${impersonatedAccount.name}`),
                    ]),
                    confirmationButtonText: 'Ok',
                    callback: action => {
                        this.selectedAccount = '';
                        this.$emit('account-changed');
                        this.accountChanged();
                    }

                })

            }).catch(e => {

                loading.close();
                if (e == null){
                    this.$notify.warning({
                        title: `Account ${this.selectedAccount} not found!`
                    })

                    return;
                }

                window.tracer.error(e);
                this.$notify.error({
                    title: 'Application Error - Failed to change account',
                });

            });
        },
        accountChanged(){
            window.tracer.debug("[d796288] Account change event triggered");
            let tick = Date.now().toPrecision();
            this.$router.replace({ path: `/order-parts`, hash: 'reset-'+tick});
        },
        showLoadingNotice(account) {
            return this.$loading({
                lock: true,
                text: `Loading account ${account}`,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        }
    }
}