export class PlacedOrderParts {

    constructor(){

        this.lineNumber = 0;
        this.partNumber = '';
        this.price = 0.00;
        this.corePrice = '';
        this.extendedPrice = '';
        this.total = '';
        this.description = '';
        this.quantity = 0;
        this.branchId = '';
        this.branchName = '';
        this.imageUrl = '';
        this.carrierUrl = '';


    }

}