import {MarketingMaterialService} from "./services/MarketingMaterialService";

/**
 * Represents a single marketing material items.
 *
 * This is an immutable object, as per design, the values cannot be changed
 * after receiving the data from the server.
 *
 * Use:
 *     MarketingMaterialItems.getItems(auth, companyId) for retrieving items for a particular companyId.
 */
export class MarketingMaterialItem {

    constructor() {

        this._name = '';
        this._description = '';
        this._companyId = '';
        this._itemId = '';
        this._uri = '';
        this._fileName = '';
        this._mimeType = '';

    }

    /**
     * The name of the marketing material item.
     * @returns {string}
     */
    get name() {
        return this._name;
    }

    /**
     * Do not use. immutable
     * @param value
     */
    set name(value) {
        /* immutable */
    }

    /**
     * Gets the marketing material description
     * @returns {string}
     */
    get description() {
        return this._description;
    }

    set description(value) {
        /* immutable */
    }

    /**
     * Gets the marketing material unique identifier.
     * @returns {string}
     */
    get itemId() {
        return this._itemId;
    }

    set itemId(value) {
        /* immutable */
    }

    /**
     * Gets the marketing material viewer Url,
     * @returns {string}
     */
    get uri() {
        return this._uri;
    }

    set uri(value) {
        /* immutable */
    }

    /**
     * Gets the marketing material file type (Mime Type)
     * @returns {string}
     */
    get mimeType() {
        return this._mimeType;
    }

    set mimeType(value) {
        /* immutable */
    }

    /**
     * Returns the url for downloading the file belonging to this instance.
     * @param auth
     * @returns {string}
     */
    getDownloadUrl(auth) {
        let service = new MarketingMaterialService(auth);
        return service.getDownloadUrl(this.itemId);
    }

    /**
     * Returns true if a file exists in the server,
     * false otherwise.
     * @param auth
     * @returns {Promise<boolean>}
     */
    existsFile(auth) {
        let service = new MarketingMaterialService(auth);
        return service.existFile(this.itemId);
    }

    /**
     *  Returns an array of marketing items for the company.
     * @param auth
     * @param companyId
     * @returns {Promise<Array<MarketingMaterialItem>>}
     */
    static getItems(auth, companyId) {

        if (companyId == null) {
            throw new Error('[e573871] companyId arg is required for retrieving marketing material items');
        }

        let service = new MarketingMaterialService(auth);
        let list = [];
        return new Promise((resolve, reject) => {

            try {

                service.getItems(companyId).then(data => {

                    if (data == null) {
                        console.warm('[w460702] Service return null array for marketing material items');
                        resolve(list);
                        return;
                    }

                    data.forEach(function (item) {

                        let marketingMaterialItem = new MarketingMaterialItem();
                        marketingMaterialItem._companyId = companyId;
                        marketingMaterialItem._itemId = item.itemId;
                        marketingMaterialItem._name = item.name;
                        marketingMaterialItem._description = item.description;
                        marketingMaterialItem._uri = item.uri;
                        marketingMaterialItem._fileName = item.fileName;
                        marketingMaterialItem._mimeType = item.mimeType;
                        list.push(marketingMaterialItem);
                    });

                    resolve(list);

                }).catch(error => {

                    window.tracer.error('[e974870 getItems service error');
                    if (error != null) {
                        window.tracer.error(error);
                    }

                    reject(error);
                });
            } catch (err) {
                window.tracer.error('[e644543] getItems error');
                if (!err) {
                    window.tracer.error(err);
                }

                throw err;
            }
        });
    }

}