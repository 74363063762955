export class WarrantyOption{

    constructor(id, desc, price)
    {
        this._id = id;
        this._desc = desc;
        this._price = price;
    }

    get id(){
        return this._id;
    }

    get desc(){
        return this._desc;
    }

    get price(){
        return this._price;
    }

    /* these values should be altered */
    set id(value){}
    set desc(value){}
    set price(value){}
}