/**
 * Represents a dictionary of objects
 */
export class Dictionary {

    constructor(){
        this._items = {};
    }

    /**
     * Sets an item to the dictionary
     * @param key - The item key. This is case sensitive.
     * @param value - The value
     *
     */
    set(key, value) {
        this._items[key] = value;
    }

    /**
     * Gets an item from the dictionary or null if does not exists
     * @param key
     * @returns {*}
     */
    get(key) {
        return this._items[key];
    }

    /**
     * Checks if an item exists in the dictionary
     * @param key
     * @returns {boolean}
     */
    contains(key){
        let obj = this._items[key];
        return (obj != null);
    }

    /**
     * Remove an item from the dictionary
     * @param key
     */
    remove(key){
        delete this._items[key];
    }

}




