import {OrderSearchBase} from "./OrderSearchBase";
import {OrderHistoryService} from './services/OrderHistoryService';

export class CancelledOrderSearch extends  OrderSearchBase {
    constructor(auth){
        super();
        this._service = new OrderHistoryService(auth);
    }

    search(searchParams){
        return this._service.searchCancelledOrders(searchParams);
    }

}