
export default {
    name: "Cart",
    data() {
        return {

        }
    },
    computed: {
        count: function () {
            return this.$store.getters.shoppingCartCount;
        },
        isLoading: function(){
            return this.$store.state.shoppingCart.loading;
        }

    },
    methods: {}
}
