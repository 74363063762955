import {Convert} from "@api/common/Convert";
import moment from "moment";

/**
 * Represents a single invoice record.
 */
export class Invoice {

    constructor() {

        this._orderNumber = '';
        this._purchaseOrder = '';
        this._invoiceNumber = '';
        this._branchId = '';
        this._partNumber = '';
        this._branchName = '';
        this._enteredDate = '';
        this._invoicedDate = '';
        this._state = '';
        this._terms = '';
        this._type = '';
        this._coreTotal = 0;
        this._total = 0;
        this._carrierUrl = '';
        this._trackingNumber = '';
        this._carrierName = '';
    }

    static Create(response){

        if (!response){
            throw new Error('Cannot create an Invoice instance from a undefined data');
        }

        let obj = new Invoice();

        obj._orderNumber = Convert.toString(response.orderNumber);
        obj._purchaseOrder = Convert.toString(response.purchaseOrder);
        obj._invoiceNumber = Convert.toString(response.invoice);
        obj._branchId = Convert.toString(response.branchId);
        obj._branchName = Convert.toString(response.branchName);
        obj._enteredDate = Convert.toString(response.orderDate);
        obj._invoicedDate = Convert.toString(response.invoiceDate);
        obj._state = Convert.toString(response.state);
        obj._terms = Convert.toString(response.term);
        obj._type = Convert.toString(response.type);
        obj._coreTotal = Convert.toFloat(response.coreTotal, 2);
        obj._total = Convert.toFloat(response.orderTotal, 2);
        obj._carrierUrl = Convert.toString(response.trackingUrl);

        obj._trackingNumber = Convert.toString(response.trackingNumber);
        obj._carrierName = Convert.toString(response.carrierName);

        if (response.partNumber != null) {
            obj._partNumber = Convert.toString(response.partNumber);
        }

        return obj;

    }



    /** Getters **/

    /**
     * Gets the order number
     * @returns {string}
     */
    get orderNumber(){
        return this._orderNumber
    }

    /**
     * Gets the part number
     * @returns {string}
     */
    get partNumber(){
        return this._partNumber;
    }

    /**
     * Gets the purchase order.
     * @returns {string}
     */
    get purchaseOrder(){
        return this._purchaseOrder;
    }

    /**
     * Gets the invoice number
     * @returns {string}
     */
    get invoiceNumber() {
        return this._invoiceNumber;
    }

    /**
     * Gets the invoice total
     * @returns {number}
     */
    get total() {
        return this._total;
    }

    /**
     * Gets the core total.
     * @returns {number}
     */
    get coreTotal() {
        return this._coreTotal;
    }

    get type() {
        return this._type;
    }

    get terms() {
        return this._terms;
    }

    get state() {
        return this._state;
    }

    get invoicedDate() {
        return this._invoicedDate;
    }

    get enteredDate() {
        return this._enteredDate;
    }

    get branchName() {
        return this._branchName;
    }
    get branchId() {
        return this._branchId;
    }

    /**
     * Gets the carrier tracking url
     * @returns {string}
     */
    get carrierUrl() {
        return this._carrierUrl;
    }

    get carrierName() {
        return this._carrierName;
    }

    get trackingNumber(){
        return this._trackingNumber;
    }

    /**
     * Whether the package for this invoice was
     * delivered in the last 24 hours.
     */
    get isDeliveredRecent() {
        //obj._invoicedDate
        let tmpInvoiceDate = moment(this.invoicedDate, "MM/DD/YYYY");
        let tmpToday = moment();
        let numberOfDays = tmpToday.diff(tmpInvoiceDate, 'days');
        return numberOfDays <= 1;
    }



}