import {PdfDocumentService} from '@api/modules/pdfDocument/PdfDocumentService';
import {StoreProxy} from "@api/common/StoreProxy";
import {Tracer} from "@api/common/Tracer";

export default {
    name: "OrderDetailPdfViewer",
    data() {
        return {
            isLoading: false,
            existPdf: false,
            pdfUrl: "",
            sessionId: "",
            instanceId: ""
        }
    },
    props: {
        id: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: ""
        }
    },
    created(){
        this.auth = StoreProxy.getAuth(this.$store);
        this._pdfService = new PdfDocumentService(this.auth);
        this.instanceId = this.$instanceId;
        this.sessionId = this.auth.sessionId;
    },
    mounted() {
        this.isLoading = true;
        this.checkPdf(() => {
            this.isLoading = false;
        });
    },
    computed: {
        pdfIdentifier(){
            return this.id;
        },
    },
    methods: {
        checkPdf(onReady) {

            if (this.type === 'invoice') {
                const url = this._pdfService.invoicePdfUrl(this.id, this.sessionId, this.instanceId);
                Tracer.current.debug(`22QUVJ-(pdf_viewer)[invoice][url=${url}]`);
                this._pdfService.existsInvoicePdf(this.id, result => {
                    this.existPdf = result;
                    if (result) {
                        this.pdfUrl = url;
                    }

                    onReady();
                })
            }
            if (this.type === 'returns' || this.type === 'credits') {
                const url = this._pdfService.creditPdfUrl(this.id, this.sessionId, this.instanceId);
                Tracer.current.debug(`IQ2714-(pdf_viewer)[credits_pdf][url=${url}]`);
                this._pdfService.existsCreditPdf(this.id, result => {
                    this.existPdf = result;
                    if (result) {
                        this.pdfUrl = url;
                    }

                    onReady();
                })
            }
            if (this.type === 'open' || this.type === 'openOrders') {
                const url = this._pdfService.statementPdfUrl(this.id, this.sessionId, this.instanceId);
                Tracer.current.debug(`35508P-(pdf_viewer)[open_orders_pdf][url=${url}]`);
                this._pdfService.existsStatementPdf(this.id, result => {
                    this.existPdf = result;
                    if (result) {
                        this.pdfUrl = url;
                    }

                    onReady();
                })
            }
            if (this.type === 'statement') {
                const url = this._pdfService.statementPdfUrl(this.id, this.sessionId, this.instanceId);
                this._pdfService.existsStatementPdf(this.id, result => {
                    this.existPdf = result;
                    if (result) {
                        this.pdfUrl = url;
                    }
                    onReady();
                })
            }
        },
        fireCloseEvent() {
            this.$emit('close');
        }

    }
}