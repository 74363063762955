import {StringUtils} from "../../../common/StringUtils";

/**
 * Collection of utilities methods.
 *
 * **For Internal Use Only!**
 */
export default class Utils {

    /**
     * Create a string to use in the API call from a `filters` object.
     * @example
     * Request Object:
     *
     * var filters = {
     *    category: '',
     *    year: 2020,
     *    make: '',
     *    model: '',
     *    engine: '',
     *    driveType: '',
     *    fuelType: '',
     *    engineVin ''
     *   }
     *
     * @return {string}
     * @param filters
     */
    static CreateParamsFromFilters(filters) {

        let category = typeof filters.category != 'undefined' ? filters.category : '';
        let year = typeof filters.year != 'undefined' ? filters.year : '';
        let make = typeof filters.make != 'undefined' ? filters.make : '';
        let model = typeof filters.model != 'undefined' ? filters.model : '';
        let engine = typeof filters.engine != 'undefined' ? filters.engine : '';
        let driveType = typeof filters.driveType != 'undefined' ? filters.driveType : '';
        let fuelType = typeof filters.fuelType != 'undefined' ? filters.fuelType : '';
        let engineVin = typeof filters.engineVin != 'undefined' ? filters.engineVin : '';

        let urlParams = '';

        if (!StringUtils.isEmptyOrNull(category)) {
            urlParams = `${urlParams}category=${category}`;

        }
        if (year > 0) {
            urlParams = `${urlParams}&year=${year}`;
        }

        if (!StringUtils.isEmptyOrNull(make)) {
            urlParams = `${urlParams}&make=${make}`;
        }

        if (!StringUtils.isEmptyOrNull(model)) {
            urlParams = `${urlParams}&model=${model}`;
        }

        if (!StringUtils.isEmptyOrNull(driveType)) {
            urlParams = `${urlParams}&drive-type=${driveType}`;
        }

        if (!StringUtils.isEmptyOrNull(fuelType)) {
            urlParams = `${urlParams}&fuel-type=${fuelType}`;
        }

        if (!StringUtils.isEmptyOrNull(engine)) {
            urlParams = `${urlParams}&engine=${engine}`;
        }

        if (!StringUtils.isEmptyOrNull(engineVin)) {
            urlParams = `${urlParams}&engine-vin=${engineVin}`;
        }
        return urlParams;
    }

}