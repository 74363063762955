import {required, minLength, requiredIf, sameAs} from "vuelidate/lib/validators";
import {ChangePasswordResult} from "../../api/modules/userManagement/models/ChangePasswordResult";
import {StoreProxy} from "../../api/common/StoreProxy";
import {UserManagement} from "../../api/modules/userManagement/UserManagement";

export default {
    name: "ChangePassword",
    props: ['visible'],
    data() {
        return {

            internalVisible: false,
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            formError: false,
            error: {
                visible: false,
                message: ''
            }
        }
    },
    computed: {
       show: {
           get: function () {
               return this.internalVisible || this.visible;
           },
           set: function(value){

               if (value === false) {
                   this.currentPassword = "";
                   this.newPassword = "";
                   this.confirmPassword = "";

                   this.$emit('close');
                   this.internalVisible = false;
               }
           }
       }

    },
    validations : {
        currentPassword: { required },
        newPassword: { required },
        confirmPassword: {
            required,
            sameAsPassword : sameAs('newPassword')
        }
    },
    methods: {
        save() {

            this.$v.$touch();

            if (this.$v.$invalid){
                this.formError = true;
                return;
            }

            let auth = StoreProxy.getAuth(this.$store);
            let userManagement = new UserManagement(auth);

            let vm = this;
            userManagement.changePassword(this.currentPassword, this.newPassword).then(result => {


                if (result === ChangePasswordResult.SUCCESS){

                    vm.$notify({
                        title: `Password Succesfully Changed!`,
                        type: 'success'
                    });

                    this.close();
                }
                else if (result === ChangePasswordResult.INVALID_PASSWORD) {
                    vm.error.visible = true;
                    vm.error.message = "Password does not match our records"
                }
                else{
                    window.tracer.warn(`[w880100] Unexpected fall thru condition. result value ${result} - It should have been a valid ChangePasswordResult value.`)

                    vm.$notify({
                        title: `[e705247] Application Error - Please try again.`,
                        type: 'error'
                    });

                }
            }).catch(error => {

                vm.$notify({
                    title: `[e277782] Server Error - Please try again.`,
                    type: 'error'
                });

                window.tracer.error("[e122280] Server error when changing user password!")
                if (error){
                    window.tracer.error(error);
                }

            });
        },
        close(){
            this.currentPassword = "";
            this.newPassword = "";
            this.confirmPassword = "";
            this.$emit('close');
        }
    }
};