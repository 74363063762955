import {StringUtils} from "@api/common/StringUtils";
import {Tracer} from "@api/common/Tracer";

export class AppCache {

    static readonly CACHE_PREFIX : string = "urn:cache:oe:";
    constructor() {

    }

    public static set(key : string, value : any, duration : number) : void {


        try {

            if (StringUtils.isEmptyOrNull(key)) {
                return;
            }

            if (value == null) {
                return;
            }

            key = AppCache.CACHE_PREFIX + key;
            if (duration == null) {
                const serializedValue: string = AppCache.serialize(value);
                sessionStorage.setItem(key, serializedValue);
                return;
            }

            const expirationDate: Date = AppCache.calculateExpirationDate(duration);
            const cacheItem: string = AppCache.prepareValue(value, expirationDate);
            localStorage.setItem(key, cacheItem);
        }
        catch(e)
        {
            Tracer.current.error(`188S399-(cache): Failed to store the cache item of ${key}`);
            Tracer.current.error(e);
        }

    }

    public static get(key : string) : any | null {

        try {
            if (StringUtils.isEmptyOrNull(key)) {
                return null;
            }

            key = AppCache.CACHE_PREFIX + key;
            let cacheItem: string | null = sessionStorage.getItem(key);


            if (cacheItem != null) {
                return cacheItem;
            }

            cacheItem = localStorage.getItem(key);
            if (cacheItem != null) {

                const cacheItemObject: any = JSON.parse(cacheItem);

                const expirationDate : Date = cacheItemObject.expiration;
                const now: Date = new Date();
                if (now > expirationDate) {
                    localStorage.removeItem(key);
                    return null;
                }

                return cacheItemObject.value;

            }

        }
        catch(e){
            Tracer.current.error(`BRDD687-(cache): Failed to retrieve the cache item of ${key}`);
            Tracer.current.error(e);
            return null;
        }

    }

    public static getAsString(key : string) : string | null {
        const itemValue : any | null = AppCache.get(key);

        if (itemValue == null){
            return null;
        }

        return String(itemValue);
    }

    public static getAsInt(key : string) : number | null {

        const itemValue : any | null = AppCache.get(key);

        if (itemValue == null){
            return null;
        }

        return Number(itemValue);
    }

    public static getAsBoolean(key : string) : boolean | null {

        const itemValue : any | null = AppCache.get(key);

        if (itemValue == null){
            return null;
        }

        return Boolean(itemValue);
    }

    public static purge(){

        Tracer.current.debug("2ATA957-(app_cache): initiated purge.")
        if (localStorage.length > 255){
            for(let i : number = 0; i < localStorage.length; i ++){
                const key : string | null = localStorage.key(i);

                if (key == null){
                    continue;
                }

                const item : string | null = localStorage.getItem(key);
                if (item != null) {

                    const cacheItemObject: any = JSON.parse(item);

                    const expirationDate : Date = cacheItemObject.expiration;
                    const now: Date = new Date();
                    if (now > expirationDate) {
                        localStorage.removeItem(key);
                        Tracer.current.info(`964P912-(app_cache): The cache entry '${key}' was removed because expired.`)
                    }
                }
            }
        }
    }

    private static prepareValue(value : any, expiration : Date) : string {

        const cacheItem : object =  {
            expiration ,
            value
        };

        return JSON.stringify(cacheItem);
    }

    private static serialize(value : any) : string {

        if (typeof value === 'string'){
            return value;
        }

        if (typeof value === 'number'){
            return value.toString();
        }

        if (typeof value === 'undefined'){
            return "";
        }

        if (typeof value === "boolean"){
            return value.toString();
        }

        return JSON.stringify(value);

    }

    private static calculateExpirationDate(seconds : number) : Date {
        const now : Date = new Date();
        now.setMinutes(seconds);

        return now;
    }
}