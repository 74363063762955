import {ShoppingCartService} from './services/ShoppingCartService';
import {StoreProxy} from "@api/common/StoreProxy";


export class ShoppingCart {

    constructor(auth, userId) {

        if (!auth || auth.length === 0){
            throw new Error("[e712487] Auth is not valid.");
        }
        this._service = new ShoppingCartService(auth);
        this._userId = userId;

        this._items = null;
        this._version = '';
    }


    load($store) {

        return new Promise((resolve, reject) => {

            this._service.getShoppingCart(this._userId).then(response => {

                StoreProxy.setShoppingCart($store, response.version, response.items);

                this._items = StoreProxy.getShoppingCartItems($store);
                this._version = StoreProxy.getShoppingCartVersion($store);

                resolve();

            }).catch(rej => {
                reject();
            });

        });
    }

    getItems($store){

        return new Promise((resolve, reject) => {

            if (this._items == null){

                if (StoreProxy.getShoppingCartVersion($store) === ''){

                    let promise = this.load($store);
                    promise.then(res => {
                        resolve(this._items);

                    }).catch(rej => {
                        if (rej){
                            window.tracer.error('[e341380] failure to load shopping cart from server!');
                            window.tracer.error(rej);
                        }
                       reject();
                    });
                }
                else{
                    console.debug('[d863344]: Shopping cart items loaded from vuex.');
                    this._items = StoreProxy.getShoppingCartItems($store);
                    resolve(this._items);
                    return;
                }
            }

            if (this._items == null){
                console.debug('[d526778]: Shopping cart has empty items.');
                resolve([]);
                return;
            }

             console.debug('[d106209]: Resolving to the cached (on current object) shopping cart items.');
             resolve(this._items);
        });

    }

    updateQuantity($store, partNumber, quantity) {

        let item = this._items.find(function (element) {
            return element.partNumber === partNumber;
        });

        return new Promise((resolve, reject) => {
            if (item) {

                let promise = this._service.updateQuantity(this._userId, item.itemId, quantity);

                promise.then(data => {

                    let tmpItemId = item.itemId;
                    let newTotal = item.extendedPrice * quantity;

                    StoreProxy.changeShoppingCartItemQuantity($store, data.version, item.itemId, quantity, newTotal, data.inStock);

                    console.debug('[d007830]: quantity committed to VUEX store');
                    resolve();
                }).catch(rej => {

                    window.tracer.error('[W965460]: Failed to update item');
                    if (rej) {
                        window.tracer.error(rej);
                    }

                    reject();
                });

            } else {
                window.tracer.error("[e882818] I did not find item to update quantity. PartNumber: " + partNumber);
                reject();
            }
        });

    }

    removeItem($store, partNumber) {

        let item = this._items.find(function (element) {
            return element.partNumber === partNumber;
        });

        return new Promise((resolve, reject) => {
            if (item) {

                let promise = this._service.removeItem(this._userId, item.itemId);
                promise.then(result => {
                    if (result.success) {
                        StoreProxy.removeShoppingCartItem($store, result.version, item.itemId);

                        this._items = StoreProxy.getShoppingCartItems($store);
                        resolve();
                    }
                }).catch(rej => {

                    window.tracer.error('[W633614]: Failed to remove item.');
                    if (rej) {
                        window.tracer.error(rej);
                    }

                    reject();
                });
            }
            else
            {
                window.tracer.error("[E507724]: I failed to remove item. I did find partNumber: " + partNumber+ " in the collection.");
                reject();
            }
        });

    }

    changeBranch($store, partNumber, branchId) {

        let item = this._items.find(function (element) {
            return element.partNumber === partNumber;
        });


        return new Promise((resolve, reject) => {
            if (item) {

                let promise = this._service.changeBranch(this._userId, item.itemId, branchId);

                promise.then(data => {
                    if (data.success) {
                        console.debug('[d638554] branch updated successfully on the server');
                        StoreProxy.changeShoppingCartItemBranch($store, item, branchId, data.version);
                        resolve();
                    }
                }).catch(rej => {

                    window.tracer.error('[W925867]: Failed to update branch');
                    if (rej) {
                        window.tracer.error(rej);
                    }

                    reject();
                });

            } else {
                window.tracer.error("[W034954] I did not find the item to update the branch... PartNumber: " + partNumber);
                reject();
            }
        });

    }

    getGrandTotal(){
        let items = this.getItems();

        let total = 0;
        for(let i = 0; i < items.length; i++){
            total = total + items[i].total;
        }

        return total;
    }

    addWarranty($store, warrantyOption){
        if (!$store){
            throw new Error("[e050476] $store argument is required");
        }

        StoreProxy.setShoppingCartWarranty($store, warrantyOption);
    }

    getWarranty($store){

        if (!$store){
            throw new Error("[e015570 $store argument is required");
        }

        return StoreProxy.getShoppingCartWarranty($store);
    }

    add($store, partNumber, branchId, quantity, description) {

        if (!$store){
            throw new Error("[e989827] $store argument is required")
        }

        let item = ShoppingCart.__createItem(partNumber, branchId, quantity, description);

        return new Promise((resolve, reject) => {

            let promise = this._service.addItem(this._userId, item);

            promise.then(response => {

                StoreProxy.addShoppingCartItem($store, response.version, response.item);

                this._items = StoreProxy.getShoppingCartItems($store);
                resolve();
            }).catch(rej => {
                console.warn("[e399226] Error when adding item to the cart!");
                if (rej){
                    window.tracer.error(rej);
                }
                reject();
            });
        });

    }

    reset($store){

        StoreProxy.clearShoppingCart($store);

        this._items = null;
        this._version = '';

        this.load($store).then(resolve => {
            resolve();
        }).catch(rej =>{
            console.warn('[w476593] Failed to load cart after reset');
           if (!rej){
               window.tracer.error(rej);
           }
        });
        
    }

    static __createItem(partNumber, branchId, quantity, description){
        return {
            partNumber: partNumber,
            branchId: branchId,
            qty: quantity,
            description: description
        }
    }
}
