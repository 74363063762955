import {OrderSearchBase} from "./OrderSearchBase";
import {OrderHistoryService} from './services/OrderHistoryService';
import {OpenOrder} from "@api/modules/orderHistory/models/OpenOrder";
import {SearchResults} from "@api/modules/orderHistory/SearchResults";

/**
 * Permits open order search
 *
 * @extends  OrderSearchBase
 */
export class OpenOrderSearch extends OrderSearchBase {

    constructor(auth){
        super();
        this._service = new OrderHistoryService(auth);
    }

    /**
     * @param searchParams
     * @returns {Promise<any>}
     */
    search(searchParams) {
        return this._service.searchOpenOrders(searchParams);
    }


    // override parent (base class)
    _transformResults(data){

        if (data == null) {
            return new SearchResults(0, 0, 0, 0);
        }

        let orders = new SearchResults(data.page, data.count, data.totalPages, data.totalRecords);

        if (!data.items){
            throw new Error('FCVA69: Expected items array from API. Check API responses.');
        }

        data.items.forEach(function (item) {
            let tmpOrder = OpenOrder.Create(item);
            orders.setItems(tmpOrder);
        });

        return orders;
    }

}