export class PartNumberSearchResult {


     constructor(count){

         if (!count){
             count = 0;
         }

        this._count = count;
        this._items = [];
        this._descriptions = [];
        this._index = -1;
     }

     get count(){
         return this._count;
     }

     get items() {
         return this._items;
     }

     get(index){

         let item = null;
         this._items.forEach(function(i){
             if (i.key === index){
                 item = i;
             }
         });

         return item;
     }

     appendResult(partNumber, description){

         this._index++;

         const obj = {
             key: this._index,
             partNumber: partNumber,
             description: description
         };

         this._items.push(obj);

         return this._index;
     }

}