import {StringUtils} from "@api/common/StringUtils";
import {PartCatalogApiClient} from "./_comm/PartCatalogApiClient";
import {OrderFulfilmentApiClient} from "./_comm/OrderFulfilmentApiClient";
import {PriorReplenishCount} from "./models/PriorReplenishCount";
import {Convert} from "@api/common/Convert";
import {Tracer} from "@api/common/Tracer";
import {CatalogSearchArgs} from "./CatalogSearchArgs";


/** @todo: rename this class to `Catalog` to better represents its method.
 *  @work-item: AER-810
 * **/

/** @todo: Rename this module (directory) as `Catalog` or `PartsCatalog` to better represents what this module represents.
 *  @work-item: AER-811
 * **/

/**
 * Represents the catalog search and related methods
 */
export class CatalogSearch {

    constructor(auth) {
        this._auth = auth;

        if (auth == null) {
            throw new Error("G9D0SP-(catalog_search): Auth is required to init CatalogSearch");
        }

        this._catalogClient = new PartCatalogApiClient(this._auth);
        this._orderFulfillmentClient = new OrderFulfilmentApiClient(this._auth);
    }

    /**
     * Get part information as it is returned from the API. <br />
     * This method is only for backwards compatiblity as we update the application with this module.
     * @param partNumber
     * @returns {Promise<unknown>}
     */
    getPartInfoRaw(partNumber){

        if (StringUtils.isEmptyOrNull(partNumber)){
            throw new Error("83DMPY [EMPTY_OR_NULL]:[PART_NUMBER]: Part number cannot be null or empty");
        }

        return this._catalogClient.getPartInfo(partNumber);
    }

    /**
     * Gets an array of available years
     * @returns {Promise<number|array>}
     */
    getYears(){
        return this._catalogClient.getYears();
    }

    /**
     * Returns the list of automobiles Makes available on the year provided
     * @param year
     * @returns {Promise<object | array>}
     *
     * @example
     * It returns an array of makes with its corresponding Id
     *
     * [{
     *   id: '',
     *   name: ''
     *  }]
     */
    getMakes(year) {

        return new Promise((resolve, reject) => {
            let yearNumber = Convert.toInt(year);
            if (year < 1899) {
                throw new Error("Invalid year number. Unable to get the vehicle makes");
            }

            let filters = {
                year: yearNumber
            }

            return this._catalogClient.getMakes(filters).then(data => {
                resolve(data);
            }).catch(err => reject(err));
        });

    }

    /**
     * Returns the list of automobiles Models available for the year and model provided
     * @param year
     * @param makeId The make identifier
     * @returns {Promise<object | array>}
     *
     * @example
     * It returns an array of makes with its corresponding Id
     *
     * [{
     *   id: '',
     *   name: ''
     *  }]
     */
    getModels(year, makeId) {

        return new Promise((resolve, reject) => {
            let yearNumber = Convert.toInt(year);
            if (year < 1899) {
                throw new Error("9EZ9SW-(catalog_search)(models): Invalid year number. Unable to get the vehicle makes");
            }

            if (StringUtils.isEmptyOrNull(makeId)){
                throw new Error("631OJ3-(catalog_search)(models): Invalid makeId argument. It cannot be empty or null");
            }

            let filters = {
                year: yearNumber,
                make: makeId
            }

            return this._catalogClient.getModels(filters).then(data => {
                resolve(data);
            }).catch(err => reject(err));

        });

    }

    /**
     * Returns the list of available part categories for the year, make and model provided
     * @param year
     * @param makeId The make identifier
     * @param modelId The model identifier
     * @returns {Promise<object | array>}
     *
     * @example
     * It returns an array of makes with its corresponding Id
     *
     * [{
     *   id: '',
     *   name: ''
     *  }]
     */
    getCategories(year, makeId, modelId) {

        return new Promise((resolve, reject) => {
            let yearNumber = Convert.toInt(year);
            if (year < 1899) {
                throw new Error("91VNWR-(catalog_search): Invalid year number. Unable to get the vehicle makes");
            }

            if (StringUtils.isEmptyOrNull(makeId)){
                throw new Error("A6UX38-(catalog_search): Invalid makeId argument. It cannot be empty or null");
            }

            if (StringUtils.isEmptyOrNull(modelId)){
                throw new Error("BLURMX-(catalog_search): Invalid modelId argument. It cannot be empty or null");
            }

            let filters = {
                year: yearNumber,
                make: makeId,
                model: modelId
            }

            return this._catalogClient.getCategories(filters).then(data => {
                resolve(data);
            }).catch(err => reject(err));

        });

    }



    getSpecificConditions(year, makeId, modelId, categoryId){

        return new Promise((resolve, reject) => {
            let yearNumber = Convert.toInt(year);
            if (year < 1899) {
                throw new Error("7H0YAW-(catalog_search): Invalid year number. Unable to get the vehicle makes");
            }

            if (StringUtils.isEmptyOrNull(makeId)){
                throw new Error("E2QNJI-(catalog_search): Invalid makeId argument. It cannot be empty or null");
            }

            if (StringUtils.isEmptyOrNull(modelId)){
                throw new Error("FUSGWF-(catalog_search): Invalid modelId argument. It cannot be empty or null");
            }

            if (StringUtils.isEmptyOrNull(categoryId)){
                throw new Error("55Y24C-(catalog_search): Invalid categoryId argument. It cannot be empty or null");
            }

            let filters = {
                year: yearNumber,
                make: makeId,
                model: modelId,
                category: categoryId
            }

            return this._catalogClient.getSpecificConditions(filters).then(data => {
                resolve(data);
            }).catch(err => reject(err));

        });
    }

    /**
     * Search catalog parts based on the arguments provided.
     * @param {CatalogSearchArgs} catalogSearchArgs Search arguments
     *
     * @returns {Promise<object | array>} - It returns the objects the API returns
     *
     * @todo
     *  1. Create a native CatalogPart class as a return object
     */
    searchParts(catalogSearchArgs){

        return new Promise((resolve, reject) => {

            if (!(catalogSearchArgs instanceof CatalogSearchArgs)) {
                throw new Error("DNRWC9-(catalog_search): Expected an instance of CatalogSearchArgs in the argument");
            }

            const filters = {

                year: catalogSearchArgs.year,
                make: catalogSearchArgs.makeId,
                model: catalogSearchArgs.modelId,
                category: catalogSearchArgs.categoryId,
                engine: catalogSearchArgs.engineId,
                driveType: catalogSearchArgs.driveId,
                fuelType: catalogSearchArgs.fuelId,
                engineVin: catalogSearchArgs.vinId
            }

            return this._catalogClient.searchParts(filters).then(data => {
                resolve(data);
            }).catch(err => reject(err));
        });

    }


    /**
     * Gets the list of compatible vehicles for the part number provided.
     * @param partNumber
     * @returns {Promise<Object|Array>}
     */
    getCompatibleVehicles(partNumber){
        return this._catalogClient.getCompatibleVehicles(partNumber);
    }


    /**
     * <b>NOT IMPLEMENTED YET - FUTURE RELEASE</b>
     * @param partNumber
     */
    getPartInfo(partNumber){
        throw new Error("#CVSXCO:[NOT_IMPLEMENT_YET]: This method will be implemented in future releases");
    }


    /**
     * Gets the projected position if the user place an order for this part number.
     * @param partNumber
     * @param branch
     * @returns {Promise<Number>}
     */
    getProjectedOrderPosition(partNumber, branch){

        if (StringUtils.isEmptyOrNull(partNumber)){
            throw new Error("B7MX253-(order_position)[part_number]: Part number cannot be null or empty.");
        }

        if (StringUtils.isEmptyOrNull(branch)){
            throw new Error("DLHLI3-(order_position)[branch]: Branch cannot be null or empty");
        }

        return new Promise((resolve, reject) => {

            this._orderFulfillmentClient.getProjectedPosition(partNumber, branch).then(data => {
                resolve(data);
            }).catch(err => {

                if (err.status === 439) {

                    resolve(null);
                } else {

                    Tracer.current.error('DRSKUD-(order_position): Failed to retrieve the projected position for this part');
                    Tracer.current.error(err);
                    reject(err);
                }

            })

        });
    }


    /**
     * Gets replenish count for the prior 7, 14 and 21 days
     * @param partNumber
     * @param branch
     * @returns {Promise<PriorReplenishCount>}
     */
    getReplenishCount(partNumber, branch){

        if (StringUtils.isEmptyOrNull(partNumber)){
            throw new Error("DOTJHI-(replenish_count)[part_number]: Part number cannot be null or empty");
        }

        if (StringUtils.isEmptyOrNull(branch)){
            throw new Error("3G6UX4-(replenish_count)[branch]: Branch cannot be null or empty");
        }

        return new Promise((resolve, reject) => {

            this._orderFulfillmentClient.getReplenishCount(partNumber, branch).then(data => {

                if (data == null){
                    reject(null);
                }

                let count = new PriorReplenishCount();

                // noinspection JSUnresolvedVariable
                count._$setCount7(data.prior7);
                // noinspection JSUnresolvedVariable
                count._$setCount14(data.prior14);
                // noinspection JSUnresolvedVariable
                count._$setCount28(data.prior28);

                resolve(count);


            }).catch(err => {

                if (err.status === 439){
                    resolve(null);
                }
                else {
                    Tracer.current.error('5SHH6W-(replenish_count): Failed to retrieve the prior 7, 14, 28 replenish count for part');
                    Tracer.current.error(err);
                    reject(err);
                }
            });
        });
    }

}