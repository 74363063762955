import {AppConfig} from "@/config/AppConfig";

import {HttpUtils} from "@/lib/HttpUtils/HttpUtils";
import {IPostalCodeResponseItem} from "@/modules/PostalCodes/__client/IPostalCodeResponseItem";
import {Tracer} from "@api/common/Tracer";

export class PostalCodeClient {

    private readonly _auth: string;
    private readonly _endpoint: string;

    constructor(auth: string) {
        this._auth = auth;
        this._endpoint = `${AppConfig.current.endpointUrl}/v1/postal-codes`;
        //this._endpoint = `http://localhost:3001/v1/postal-codes`; // only for local tests!
    }

    lookup(code: string): Promise<IPostalCodeResponseItem[]> {

        const url = `${this._endpoint}/${code}`;
        return new Promise<IPostalCodeResponseItem[]>((resolve, reject) => {

            HttpUtils.get(url, this._auth).then((data: IPostalCodeResponseItem[]) => {

                if (data == null || data.length == 0) {
                    const empty: IPostalCodeResponseItem[] = [];
                    resolve(empty);
                    return
                }

                Tracer.current.debug(`BQ9PTX9-(postal_code): Total ${data.length} postal items returned from server.`);

                resolve(data);
                return;

            }).catch(err => {

                Tracer.current.error("CBVDC32-(postal_code)[client]: Error retrieving the postal code from the server.");
                Tracer.current.error(err);
                reject(err);
                return;

            });
        });
    }

}
