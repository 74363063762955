import axios from 'axios';
import {HttpUtils} from '../../common/HttpUtils';

export class PdfDocumentService {

    constructor(auth){
        this.auth = auth;
        this.baseUrl = `${window.config.api.partsUri}/v1/docs`;
        this.headers = HttpUtils.getHttpHeaders(auth, 'text/json');
        this.error = null;
    }

    setErrorHandler(error){
        this.error = error;
    };

    get httpHeaders(){
        return HttpUtils.getHttpHeaders(this.auth);
    }

    invoicePdfUrl(orderNum, sessionId, instanceId){
        return `${this.baseUrl}/invoices/${orderNum}?session-id=${sessionId}&instance-id=${instanceId}`;
    }

    creditPdfUrl(orderNum, sessionId, instanceId){
        return `${this.baseUrl}/credits/${orderNum}?session-id=${sessionId}&instance-id=${instanceId}`;
    }

    statementPdfUrl(sessionId, instanceId){
        return `${this.baseUrl}/statements/current?session-id=${sessionId}&instance-id=${instanceId}`;
    }


    existsInvoicePdf(pdfIdentifier, result){

        let serviceUrl = `${this.baseUrl}/invoices/${pdfIdentifier}/check`;

        axios.head(serviceUrl, {headers: this.headers}).then(response => {

            if (response.status === 200 || response.status === 204){
                result(true);
            }

        }).catch( e => {

            result(false);
            let is404 = HttpUtils.handled404(e, func => {result(false)});

            if (!is404) {
                if (!this.error) {
                    HttpUtils.handleError(e);
                } else {
                    this.error(e);
                }
            }
        });
    }

    existsCreditPdf(pdfIdentifier, result){

        let serviceUrl = `${this.baseUrl}/credits/${pdfIdentifier}/check`;

        axios.head(serviceUrl, {headers: this.headers}).then(response => {

            if (response.status === 200 || response.status === 204){
                result(true);
            }

        }).catch( e => {

            result(false);
            let is404 = HttpUtils.handled404(e, func => {result(false)});

            if (!is404) {
                if (!this.error) {
                    HttpUtils.handleError(e);
                } else {
                    this.error(e);
                }
            }
        });
    }

    existsStatementPdf(result){

        let serviceUrl = `${this.baseUrl}/statements/current/check`;

        axios.head(serviceUrl, {headers: this.headers}).then(response => {

            if (response.status === 200 || response.status === 204){
                result(true);
            }

        }).catch( e => {

            result(false);
            let is404 = HttpUtils.handled404(e, func => {result(false)});

            if (!is404) {
                if (!this.error) {
                    HttpUtils.handleError(e);
                } else {
                    this.error(e);
                }
            }
        });
    }

}


