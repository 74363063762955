
export class AccountInfo {

    constructor(){

        this._name = '';
        this._address1 = '';
        this._address2 = '';
        this._city = '';
        this._state = '';
        this._zipCode = ''
    }

    get name(){
       return this._name;
    }

    get address(){
       return this._address1;
    }

    get address2() {
        return this._address2;
    }

    get city(){
        return this._city;
    }

    get state(){
        return this._state;
    }

    get zipCode(){
        return this._zipCode;
    }





}