import Nav from '@/views/sections/Nav/nav.vue'
import Logo from '@/views/sections/logo.vue'
import {UserPermissions} from "@api/core/UserPermissions";
import ImpersonateAccount from "@/components/ImpersonateAccount/ImpersonateAccount.vue";

export default {
    name: 'HorizontalNav',
    props: ['position'],
    data() {
        return {}
    },
    computed: {
        canImpersonate(){
            return UserPermissions.canImpersonate(this.$store);
        }
    },
    methods: {
        pushPage(index, indexPath) {
            this.$emit('push-page', {page:index})
        }
    },
    components: {
        Nav,
        Logo,
        ImpersonateAccount
    }
}