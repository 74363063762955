import {Tracer} from "@api/common/Tracer";

export default {
    name: "OpenOrderHistoryGrid",
    props: {
        orders: {
            type: Array,
            required: false,
            default: function () {
                return [];
            }
        },
        orderHistoryResults: {
            type: Object,
            required: false,
            default: function () {
                return null;
            }
        }
    },
    data() {
        return {
            isLoading: false,
        }
    },

    computed: {
        items() {
            return this.orderHistoryResults.items;
        },
        currentPage() {
            if (this.orderHistoryResults == null){
                return 0;
            }
            else {
                return this.orderHistoryResults.currentPage;
            }
        },
        totalRecords() {
            if (this.orderHistoryResults == null){
                return 0;
            }
            else {
                return this.orderHistoryResults.totalRecords;
            }
        }

    },
    filters: {
      empty: function(value){
          if (value.length === 0){
              return 'N/A';
          }
          if (value === -1){
              return 'N/A';
          }

          return value;
      },

    },
    methods: {

        showDetails(orderNumber) {

            this.$emit("orderDetails", {
                orderNumber: orderNumber,
                branch: this.branch
            });

        },
        pageChange(page) {

            Tracer.current.debug(`9EWF1Q: Page changed to ${page}`);
            this.isLoading = true;

            let vm = this;
            this.orderHistoryResults.page(page).then(function(data) {
                vm.isLoading = false;
            });
        },
        prevPage(page) {
            this.pageChange(page);
        },
        nextPage(page) {
            this.pageChange(page);
        }

    }

}
