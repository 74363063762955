import {HttpUtils} from "@/api/common/HttpUtils";
import {Tracer} from "@api/common/Tracer";


export class StoreProxy {

    static getAuth($store) {
        StoreProxy.__validateStore($store);
        return $store.state.auth
    }

    static getSessionId($store) {
        StoreProxy.__validateStore($store);
        return $store.state.auth.sessionId;
    }

    static getJwt($store) {
        StoreProxy.__validateStore($store);
        return $store.state.auth.jwt;
    }

    static getProfile($store){
        StoreProxy.__validateStore($store);
        return $store.state.profile;
    }

    static getHomeBranch($store){
        StoreProxy.__validateStore($store);
        let profile = StoreProxy.getProfile($store);
        if (profile.account != null){
            return profile.account.homeBranch;
        }

        Tracer.current.warn("E6TKI6-(store):[assertion][home_branch_not_found_in_profile]: The home branch was not found in the home branch VUEX.");
        return "";
    }

    static getAccountNumber($store){
        StoreProxy.__validateStore($store);
        if (!$store.state.profile.isImpersonated){
            return $store.state.profile.customerNumber;
        }
        else{
            return $store.state.profile.impersonated.customerNumber;
        }
    }

    static getUserId($store){
        StoreProxy.__validateStore($store);
        return $store.state.profile.userId;
    }

    static getCompanyId($store){
        StoreProxy.__validateStore($store);
        return $store.state.profile.companyId;
    }

    static setCredit($store, credit){
        StoreProxy.__validateStore($store);
        $store.commit("setCreditLimit", credit);
    }

    static getCredit($store){
       return $store.getters.getCreditLimit;
    }

    static showCreditAlert($store){
        StoreProxy.__validateStore($store);
        return $store.getters.showCreditLimitAlert;
    }

    static setCreditAlertVisibility($store, showAlert){
        StoreProxy.__validateStore($store);
        $store.commit("showCreditLimitAlert", showAlert);
    }

    static setCompanySettings($store, settings){
        StoreProxy.__validateStore($store);

        if (settings == null){
            Tracer.current.warn("[w-913527] Company setting is null! - It can't be saved in the session store");
        }
        $store.commit("setCompanySettings", settings);
    }

    static getCompanySettings($store){

        StoreProxy.__validateStore($store);
        let settings = $store.getters.getCompanySettings;

        if (!settings){
            console.warn('company settings not found on the vuex store!.');
        }

        return settings;
    }

    static shoppingCartLoaded($store, loaded){
        StoreProxy.__validateStore($store);
        $store.commit("setShoppingCartLoaded", loaded);
    }

    static shoppingCartLoading($store){
        StoreProxy.__validateStore($store);
        $store.commit("setShoppingCartLoading");
    }

    static setLogin($store, jwt, sessionId){
        StoreProxy.__validateStore($store);
        $store.commit('setLogin', {jwt: jwt, sessionId: sessionId});
    }

    static setLogout($store){
        if ($store != null) {
            StoreProxy.__validateStore($store);
            $store.commit("setLogout");
        }
    }

    static setShoppingCart($store, version, items){
        $store.commit("setShoppingCart", {version: version, items: items});
    }


    static getShoppingCartVersion($store){
        return $store.state.shoppingCart.version
    }

    static changeShoppingCartItemQuantity($store, version, itemId, quantity, newTotal, inStock){
        $store.commit("changeShoppingCartItemQuantity", {version: version, itemId: itemId, quantity: quantity, newTotal: newTotal, inStock: inStock });
    }

    static changeShoppingCartItemBranch($store, item, branchId, version){
        $store.commit("changeShoppingCartItemBranch", {part: item, branchId: branchId, version: version});
    }

    static removeShoppingCartItem($store, version, itemId){
        $store.commit("removeShoppingCartItems", { version: version, itemId: itemId });
    }

    static addShoppingCartItem($store, version, item){
        $store.commit("addShoppingCartItem", {version: version, item: item});
    }

    static setShoppingCartWarranty($store, warrantyOption){
        $store.commit('addShoppingCartWarranty', warrantyOption);
    }

    static getShoppingCartWarranty($store){
        return $store.getters.shoppingCartWarranty;
    }

    static clearShoppingCart($store, isEmergency){

        if (isEmergency){
            return;
        }

        $store.commit('setShoppingCart', {version: '', items: []});
        $store.commit('addShoppingCartWarranty', '');
    }

    static clearBulkOrderCart($store, isEmergency){

        if (isEmergency){
            return;
        }

        $store.commit('setOrdersItems', {version: '', items: []});
        $store.commit('ordersAddWarranty', '');
    }

    static setOrderItems($store, items, cartId, isEmergency){

        if (isEmergency){

            if (items.length > 1){
                throw new Error("#CM7Z0X:[IMPLEMENTATION_ERROR]: Emergency order can only have one item.")
            }

            $store.commit("setEmergencyItems", {items: items, cartId: cartId});
        }
        else{
            $store.commit("setOrdersItems", {items: items, cartId: cartId});
        }


    }

    static removeBulkOrderItem($store, itemId, isEmergency){

        if (isEmergency){

            $store.commit("emergencyOrdersRemoveItem", null);
            return;
        }

        $store.commit("ordersRemoveItem", {itemId: itemId})
    }

    static ordersRemoveAll($store, isEmergency){

        if (isEmergency){
            $store.commit("emergencyOrdersRemoveItem", null);
            return;
        }

        $store.commit("ordersRemoveAll");
    }

    static getShoppingCartItems($store){
        return $store.state.shoppingCart.items;
    }

    static getBulkOrderItems($store) {
        return $store.getters.bulkOrderItems;
    }

    static getEmergencyOrderItems($store) {
        return $store.getters.emergencyOrderItems;
    }

    static getShoppingCartGrandTotal($store){
        return $store.getters.shoppingCartGrandTotal;
    }


    static getBulkOrderGrandTotal($store){
        return $store.getters.bulkOrderGrandTotal;
    }

    static getEmergencyOrderGrandTotal($store){
        return $store.getters.emergencyOrderGrandTotal;
    }

    static setBulkOrderWarranty($store, item){
        if (item != null) {
            $store.commit('ordersAddWarranty', item);
        }
    }

    static getRoles($store){

         if ($store == null)return [];

        return $store.state.profile.roles;
    }

    static setImpersonation($store, account, name){

        if ($store == null){
            return;
        }

        let payload = {
            account: account,
            name: name
        }

        $store.commit('setImpersonated', payload);
    }

    static isImpersonated($store){

        if ($store == null){
            return false;
        }

        return $store.state.profile.isImpersonated;
    }

    static isRestricted($store){

        if ($store == null){
            return false;
        }

        let isRestricted = false;
        if ($store.state.profile.restricted != null){
            isRestricted = $store.state.profile.restricted;
        }

        return isRestricted;
    }

    static getCustomerName($store){
        let customerName = $store.state.profile.customerName;
        if (customerName == null || customerName.length === 0){
            customerName = $store.state.profile.billingAddress.customerName;
        }
        return customerName;
    }

    /**
     * Gets the current's user customer number also know as account.
     * @param $store
     * @returns {string|*}
     */
    static getCustomerNumber($store){
        return $store.state.profile.customerNumber;
    }

    /**
     * Gets the current's user customer number also know as account.
     * @param $store
     * @returns {string|*}
     */
    static getAccount($store){
        return $store.state.profile.customerNumber;
    }

    static getImpersonatedCustomerName($store){

        if (!$store.state.profile.isImpersonated)
        {
            return '';
        }

        return $store.state.profile.impersonated.customerName;
    }

    static getImpersonatedCustomerNumber($store){

        if (!$store.state.profile.isImpersonated)
        {
            return '';
        }

        return $store.state.profile.impersonated.customerNumber;
    }

    static isActive($store){

        let customerNumber = StoreProxy.getCustomerNumber($store);
        if (customerNumber != null && customerNumber.length > 0){
            return true;
        }
        else{
            return StoreProxy.getImpersonatedCustomerNumber($store).length > 0;
        }

    }

    static Save($store){

        if ($store == null){
            window.tracer.warn("[w-618310] $store is null. Unable to save it");
            return;

        }

        let auth = StoreProxy.getAuth($store);

        StoreProxy.__storeOnServer(auth, $store.state).then( () =>{
            window.tracer.info("[i-241966] session store saved on the server");
        }).catch(e => {

            window.error("[e-678328] error while saving session store on the server");
            if (e != null) {
                window.error(e);
            }
        })
    }

    static reload(auth, $store) {

        return new Promise((resolve, reject) => {

            StoreProxy.__retrieveFromServer(auth).then(x => {
                if (x != null) {
                    $store.commit("setState", x);
                    tracer.info("858993-(StoreProxy:reload): State object loaded from server");
                    resolve(true);
                }
                else{
                    tracer.info("695618-(StoreProxy:reload):We did not found the store in the server");
                    resolve(false);
                }
            }).catch(e => {

                tracer.error(e,
                    {
                        message: "979561-(StoreProxy:reload): error while retrieving session store on the server"
                    }
                );

                reject(e);
            });

        }
    )



    }


    static __validateStore($store){
        if (!$store){
            throw new Error('[e473782] vuex store ($store) must be passed to this method');
        }
    }

    static __storeOnServer(auth, obj){

        return new Promise((resolve, reject) => {

            let url = `${process.env.VUE_APP_API_URL}/v1/sessions/current/vuex`;
            HttpUtils.post(url, auth, obj).then( result => {

                    if (result){
                        resolve();
                    }
                    else{
                        reject();
                    }
                }

            ).catch(err => {

                if (err != null){
                    window.tracer.error(err);
                }

                reject(err);
            });
        });
    }

    static __retrieveFromServer(auth){

        return new Promise((resolve, reject) => {

            let url = `${process.env.VUE_APP_API_URL}/v1/sessions/current/vuex`;

            HttpUtils.get(url, auth).then(result => {

                    if (result != null) {
                        resolve(result);
                    } else {
                        resolve(null);
                    }
                }
            ).catch(err => {

                Tracer.current.error(err);
                reject(err);
            });
        });
    }

}
