

/**
 * Represent a Part
 *
 * Notes:
 *  This object can be hydrate from two different APIs which are slightly different in the data model<br>
 *  <ul>
 *  <li>The search API </li>
 *  <li>The shopping cart API </li>
 *  </ul>
 *  One of the important difference is the <code>part.qty</code> property from the search API is the total available
 *  inventory in all branches. But it is comes from the shopping cart API, then it means the quantity of this part the user
 *  saved in the shopping cart.
 *
 */


import {Tracer} from "@api/common/Tracer";
import {StringUtils} from "@api/common/StringUtils";
import {Convert} from "@api/common/Convert";
import {_Constants} from "@/views/apps/OrderParts/models/_Constants";

export class OrderPart {

    /**
     * @param part as it is retrieved from the API
     * @exception when part is null or undefined
     * */
    constructor(part) {

        if (part == null) {
            throw new Error('#6NR4Q9 Expected a part object');
        }

        this._cartItemId = null;

        if (part.itemId != null) {
            this._cartItemId = part.itemId;
        }

        this._part = part;
        this.partNumber = part.partNumber;
        this.description = part.description;
        this.price = part.price;
        this.corePrice = part.exchange;
        this.imageUri = this._getImageUri(part);
        this.loaded = true;
        this.found = true;
        this._selectedBranchIndex = 0;

        // mark this part number as service item, like 'FREIGHT'/
        this._isServicePartNumber = false;

        //rules
        if (part.rules != null) {

            this._maxQty = Convert.toInt(part.rules.maxQty);
            this._homeBranchOnly = Convert.toBoolean(part.rules.homeBranchOnly);
            this._backOrderRestricted = Convert.toBoolean(part.rules.backOrderRestricted);

            this.viewOnly = Convert.toBoolean(part.rules.viewOnly);

            // noinspection JSUnresolvedVariable
            if (part.rules.emergency) {
                this._emergencyMaxQty = Convert.toInt(part.rules.emergency.maxQty);
            } else {
                this._emergencyMaxQty = _Constants.DEFAULT_EMERGENCY_MAX_QTY;
            }

        } else {
            this._maxQty = _Constants.DEFAULT_MAX_QTY;
            this._homeBranchOnly = false;
            this._backOrderRestricted = false;
        }

        // @note: Old API takes precendece temporary until more valdiation is done
        if (part.viewOnly != null) {
            this.viewOnly = part.viewOnly;
        } else {
            this.viewOnly = false;
        }

        /* todo: N7O44C-(freight): Make the 'service part number' an attribute that is provide by the API.
           This part of a set of changes to allow 'FREIGHT' to be order an assigned to branch 18.
           Priority update.
           We must return to make it configurable an non-hardcoded.
        */
        if (StringUtils.compareIgnoreCase(part.partNumber, "FREIGHT")){
            this._isServicePartNumber = true;
        }

    }

    /**
     * return the shopping cart Id that belongs to this part.
     * @returns {guid}
     */
    get cartItemId() {
        return this._cartItemId;
    }


    get isServicePart(){
        return this._isServicePartNumber;
    }


    /**
     * Returns the extended price of this part which is price + core price
     * @returns {number}
     */
    get extendedPrice() {
        return this.price + this.corePrice;
    }


    /**
     * Returns the name of the current selected branch
     * @returns {string}
     */
    get branchName() {
        return this._getBranchName(this._part);
    }

    /**
     * Returns the branch id of the current selected branch
     * @returns {string}
     */
    get branchId() {
        return this._getBranchId(this._part);
    }

    /**
     * Returns an array of branches. The branch object is returned directly from the API response.
     * @returns {branch[]}
     */
    get branches() {
        return this._part.branches;
    }

    /**
     * Gets the group this part belongs
     * @returns {string}
     */
    get group(){

        if (StringUtils.isEmptyOrNull(this._part.group)){
            return '';
        }

        return this._part.group;
    }

    /**
     * Gets the max quantity allowed for emergency orders for this part
     * @returns {number|*|number}
     */
    get emergencyMaxQty() {
        return this._emergencyMaxQty;
    }

    /**
     * Gets the max quantity allowed
     * @returns {number|*|number}
     */
    get maxQty() {
        return this._maxQty;
    }

    /**
     * Gets whether the current part is only allowed to order from home branch
     * @returns {*|boolean}
     */
    get homeBranchOnly() {
        return this._homeBranchOnly;
    }



    /**
     * Gets whether this current part is restricted to make back orders
     * @returns {*|boolean}
     */
    get backOrderRestricted() {
        return this._backOrderRestricted;
    }

    /**
     * Returns an array of images URI for this part
     * @returns {string[]}
     */
    get images() {
        return this._part.images;
    }

    /**
     * Returns the current selected branch
     *
     *  @note The returned value is based on the return json by the server, which is
     *     {
     *      "id": "10",
     *      "name": "DALLAS",
     *      "qty": 1
     *      }
     *
     * @returns {branch}
     *

     */
    getSelectedBranch() {


        const selectedBranch =  this._part.branches[this._selectedBranchIndex];
        Tracer.current.debug(`41844P-(order_part)[selected_branch=${selectedBranch.id}][index=${this._selectedBranchIndex}]`);

        // noinspection PointlessBooleanExpressionJS
        if (selectedBranch == null) {
            Tracer.current.warn(`1GPY33-(order_part)[branch_not_found][index=${this._selectedBranchIndex}]: Unable to determine the selected branch`);
            return null;
        }

        return selectedBranch;
    }


    /**
     * Set the branch by branch Id. Returns the index where the branch is stored.
     * @param branchId
     * @returns {number}
     */
    setBranch(branchId) {

        Tracer.current.debug(`DB5XSC-(part): [SET_BRANCH=${branchId}]`);

        for(let i = 0; i < this.branches.length; i++) {

            if (branchId === this.branches[i].id) {

                this._selectedBranchIndex = i;
                Tracer.current.debug(`1D6XNE-(order_part)[set_branch=${branchId}][selected=${this._selectedBranchIndex}]`);

                return this._selectedBranchIndex;
            }

        }

        Tracer.current.debug(`4UNBYK-(order_part)[branch_not_not_found][set_branch=${branchId}][default=${this._selectedBranchIndex}]`);
        return this._selectedBranchIndex;
    }


    /**
     * Returns the branch by branch id
     * @param branchId
     * @returns {branch|null}
     */
    getBranch(branchId){

        for(let i = 0; i < this.branches.length; i++) {
            if (branchId === this.branches[i].id) {
                return this.branches[i];
            }
        }

        Tracer.current.warn(`7MEETW-(order_part)[branch_not_found][branch=${branchId}]`)
        return null;
    }

    /*
     * PRIVATE METHODS
     */
    _getImageUri(part) {
        if (part.images.length === 0) return '';
        return part.images[0].thumbnailUri;
    }

    _getBranchId(part) {

        if (part.branches.length === 0) return '';

        if (this._selectedBranchIndex === -1){
            return part.branches[0].id;
        }
        else{
            return part.branches[this._selectedBranchIndex].id;
        }

    }

    _getBranchName(part) {
        if (part.branches.length === 0) return '';
        return `${part.branches[this._selectedBranchIndex].name} (${part.branches[this._selectedBranchIndex].qty}) `;
    }

}