import {HttpUtils} from "@api/common/HttpUtils";
import {Convert} from "@api/common/Convert";
import {Tracer} from "@api/common/Tracer";

// load module configuration
import config from './../_configs';



export class OrderFulfilmentApiClient {

    constructor(auth) {
        this._auth = auth;

        if (auth == null){
            throw new Error("G13A226-(order_fulfillment): Auth is required to init CatalogSearch.");
        }
    }


    /**
     * Gets the projected position for this part number if order now.
     * @param partNumber
     * @param branch
     * @returns {Promise<Number>}
     */
    getProjectedPosition(partNumber, branch){

        try {

            const url = `${config.fulfillmentApiUrl}/projected-order-position/${partNumber}/${branch}`;
            const vm = this;

            return new Promise((resolve, reject) => {

                HttpUtils.get(url, vm._auth).then(data => {

                    if (data == null){
                        reject({message: "Not found", value: ''});
                        return;
                    }

                    let position = Convert.toInt(data);
                    if (isNaN(position)) {
                        reject({message: "Invalid value", value: ""});
                        return;
                    }

                    if (position === -1) {
                        reject({message: "Invalid value", value: -1});
                        return;
                    }

                    resolve(position);

                }).catch(err => {

                    if (err.status === 439){
                        reject({message: 'Not found', status: 439,  value: ''});
                    }
                    else {
                        Tracer.current.error("QN6B715-(order_fulfillment): An server error occurred while attempting to retrieve the projected order position.");
                        Tracer.current.error(err);
                        reject(err);
                    }
                })

            });

        }
        catch(e){
            Tracer.current.error("93BS618-(order_fulfillment): General error while attempting to retrieve the projected order position.");
            Tracer.current.error(e);
        }
    }

    /**
     * Retrieve replenish count for the prior 7, 14 and 21 days
     * @param partNumber
     * @param branch
     * @returns {Promise<{}>}
     *
     */
    getReplenishCount(partNumber, branch){

        try {
            const url = `${config.fulfillmentApiUrl}/history/replenish/${partNumber}/${branch}`;
            const vm = this;

            return new Promise((resolve, reject) => {

                HttpUtils.get(url, vm._auth).then(data => {

                    if (data == null) {
                        reject({message: "No data"});
                        return;
                    }

                    resolve(data);

                }).catch(err => {

                    if (err.status === 439){
                        reject({message: "Not found", status: 439,  value: ''});
                    }
                    else {
                        Tracer.current.error("7TQX590-(replenish_count): Server Error.");
                        Tracer.current.error(err);
                        reject(err);
                    }
                })

            });

        }
        catch(e){

            Tracer.current.error("BQ1D193-(replenish_count): Error!.");
            Tracer.current.error(e);
        }

    }
}