export class OrderPartInventoryViewer {

    constructor() {
        this.partNumber = '';
        this.branches = [];
        this.visible = false;
    }

    showInventory(orderPartItem) {

        if (orderPartItem == null) return;

        this.branches = orderPartItem.getInventory();
        this.partNumber = orderPartItem.partNumber;
        this.visible = true;
    }

    close() {
        this.partNumber = '';
        this.branches = [];
        this.visible = false;
    }
}