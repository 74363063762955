import {Convert} from "@api/common/Convert";

export class Credit {

    constructor(){

        this._orderNumber = "";
        this._purchaseOrder = "";
        this._invoiceNumber = "";
        this._branchId = "";
        this._partNumber = "";
        this._branchName = "";
        this._enteredDate = "";
        this._invoicedDate = "";
        this._state = "";
        this._terms = "";
        this._type = "";
        this._coreTotal = 0;
        this._total = 0;

    }

    static Create(response){

        if (!response){
            throw new Error("Cannot create an Invoice instance from a undefined data");
        }

        let obj = new Credit();
        obj._orderNumber = Convert.toString(response.orderNumber);
        obj._purchaseOrder = Convert.toString(response.purchaseOrder);
        obj._invoiceNumber = Convert.toString(response.invoice);
        obj._branchId = Convert.toString(response.branchId);
        obj._branchName = Convert.toString(response.branchName);
        obj._enteredDate = Convert.toString(response.orderDate);
        obj._invoicedDate = Convert.toString(response.invoiceDate);
        obj._state = Convert.toString(response.state);
        obj._terms = Convert.toString(response.term);
        obj._type = Convert.toString(response.type);
        obj._coreTotal = Convert.toFloat(response.coreTotal, 2);
        obj._total = Convert.toFloat(response.orderTotal, 2);

        if (response.partNumber != null) {
            obj._partNumber = Convert.toString(response.partNumber);
        }

        return obj;

    }



    /** Getters **/
    get orderNumber(){
        return this._orderNumber
    }

    get partNumber(){
        return this._partNumber;
    }

    get purchaseOrder(){
        return this._purchaseOrder;
    }

    get invoiceNumber() {
        return this._invoiceNumber;
    }
    get total() {
        return this._total;
    }
    get coreTotal() {
        return this._coreTotal;
    }
    get type() {
        return this._type;
    }
    get terms() {
        return this._terms;
    }
    get state() {
        return this._state;
    }
    get invoicedDate() {
        return this._invoicedDate;
    }
    get enteredDate() {
        return this._enteredDate;
    }
    get branchName() {
        return this._branchName;
    }
    get branchId() {
        return this._branchId;
    }


}