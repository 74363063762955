import {CanadaProvinceList} from "./_internals/CanadaProvinceList";
import {USStateList} from "./_internals/USStateList";
import {AbbrevMapItem} from "./_internals/AbbrevMapItem";
import {CountryCode} from "./CountryCode";
import {CountryRegion} from "./CountryRegion";
import {StringUtils} from "@/lib/StringUtils";


export class CountryRegionLookup {

    usStates : Map<string, string>;
    canadasProvinces : Map<string, string>;

    constructor() {
        this.usStates = this.__transformArrayToMap(USStateList);
        this.canadasProvinces = this.__transformArrayToMap(CanadaProvinceList);

    }


    /**
     * Find the country region that matches the abbreviation.
     * @param abbrev
     */
    getRegion(abbrev : string) : CountryRegion | null {

        if (abbrev == null || abbrev.length === 0) {
            return null;
        }

        const abbrevUpperCase = abbrev.toUpperCase();

        const usList: Map<string, string> = this.__getStateList(CountryCode.US);

        let name: string | undefined = usList.get(abbrevUpperCase);

        if (name != null && name.length > 0) {
            return new __InternalStateItem(abbrev, abbrev, name, CountryCode.US);
        } else {

            const caList: Map<string, string> = this.__getStateList(CountryCode.Canada);
            name = caList.get(abbrevUpperCase);

            if (name != null && name.length > 0) {
                return new __InternalStateItem(abbrev, abbrev, name, CountryCode.Canada);
            }
        }

        return null;
    }

    /**
     * Gets the region name that matches the region and country code.
     * @param abbrev
     */
    getRegionName(abbrev : string) : string | null {
        if (abbrev == null || abbrev.length == 0) {
            return null;
        }

        const abbrevUpperCase = abbrev.toUpperCase();

        // Search for the state name in the list of states
        const usList : Map<string, string> = this.__getStateList(CountryCode.US);
        const usStateName : string | undefined = usList.get(abbrevUpperCase);

        if (usStateName != null && usStateName.length > 0) {
            return usStateName;
        }

        // If we don't find the state name in the US, then try Canada provinces.
        if (usStateName == null){
            const canadaList : Map<string, string> = this.__getStateList(CountryCode.Canada);
            const canadaProvinceName : string | undefined = canadaList.get(abbrevUpperCase);

            if (canadaProvinceName != null && canadaProvinceName.length > 0) {
                return canadaProvinceName;
            }
        }

        return null;

    }

    /**
     * Returns an array of country regions for all countries.
     */
    getList() : CountryRegion[] {

        const usList = this.__transformToStateItem(CountryCode.US);
        const canadaList = this.__transformToStateItem(CountryCode.Canada);
        usList.concat(canadaList);

        return usList;
    }

    /**
     * Returns an array of country regions for the country provided.
     * @param countryCode
     */
    getListByCountry(countryCode : CountryCode) : CountryRegion[]{

        return this.__transformToStateItem(countryCode);
    }


    /**
     * Finds the abbreviation for a specific region name
     * @param name
     */
    findAbbreviation(name : string) : string | null{

        let abbrev : string | null = this.__findAbbrev(name, CountryCode.US);
        if (abbrev == null){
            abbrev = this.__findAbbrev(name, CountryCode.Canada);
        }

        return abbrev;
    }

    /**
     * PRIVATE METHODS
     */

    __getStateList(countryCode : CountryCode) : Map<string, string> {
        switch (countryCode) {
            case CountryCode.US:
                return this.usStates;
            case CountryCode.Canada:
                return this.canadasProvinces;
            default :
                throw new Error(`4KIA091-(not_implemented): The country code of '${countryCode}' does not have a state / province list available.`);

        }
    }

    __getStateRawArray(countryCode : CountryCode) : AbbrevMapItem[]{
        switch (countryCode) {
            case CountryCode.US:
                return USStateList;
            case CountryCode.Canada:
                return CanadaProvinceList;
            default :
                throw new Error(`990Y202-(not_implemented): The country code of '${countryCode}' does not have a state / province list available.`);

        }
    }

    __transformArrayToMap(list : AbbrevMapItem[]) : Map<string, string>{

        const map : Map<string, string> = new Map<string, string>()
        for(let i = 0; i < list.length; i++){
            const item : AbbrevMapItem = list[i];
            map.set(item.abbrev, item.name)
        }

        return map;
    }

    __transformToStateItem(countryCode : CountryCode) : CountryRegion[] {

        const list : AbbrevMapItem[] = this.__getStateRawArray(countryCode);

        const tempList : CountryRegion[] = []

        for(let i = 0; i < list.length; i++){
            const item : AbbrevMapItem = list[i];

            tempList.push(new __InternalStateItem(item.abbrev, item.abbrev, item.name, countryCode));
        }

        return tempList;
    }

    __findAbbrev(name : string, countryCode : CountryCode) : string | null {

        const list : AbbrevMapItem[] = this.__getStateRawArray(countryCode);

        let abbrev : string | null= null;

        for(let i = 0; i < list.length; i++){

            if (StringUtils.compareIgnoreCase(name, list[i].name)){
                abbrev = list[i].abbrev;
                break;
            }
        }

        return abbrev;
    }
}


class __InternalStateItem implements CountryRegion {

    abbrev: string;
    countryCode: CountryCode;
    id: string;
    name: string;

    constructor(id: string, abbrev: string, name: string, countryCode: CountryCode) {

        this.id = id;
        this.abbrev = abbrev;
        this.name = name;
        this.countryCode = countryCode;
    }
}