import {AppConfig} from "@/config/AppConfig";
import {StoreProxy} from "@api/common/StoreProxy";

export default {

    name: 'LanguageToggle',
    data() {
        return {
            url: ''
        }
    },
    created() {
        this.currentLocale = AppConfig.current.locale;
    },
    computed: {
        isVisible(){

            const locale = navigator.language.toLowerCase();
            return (locale !== 'en-us' &&
                locale !== 'en-gb' &&
                locale !== 'en-au' &&
                locale !== 'en-ie' &&
                locale !== 'en-ca');
        }
    },
    methods: {
       go() {
           const jwt = StoreProxy.getJwt(this.$store)
           const baseUrl = AppConfig.current.getLocaleUrl('fr-ca');
           this.url = `${baseUrl}/auth/${jwt}/?path=${this.$route.path}`;

           window.location.replace(this.url);
       }

    }

}