import {HttpUtils} from "../../../common/HttpUtils";

/**
 * Represents the end point to the marketing material API service
 */
export class MarketingMaterialService {

    constructor(auth){

        this._auth = auth;

        this._baseUrl = `${process.env.VUE_APP_API_URL}/v1/marketing-materials`;
        this._headers = HttpUtils.getHttpHeaders(this._auth, 'application/json');

    }

    get baseUrl(){
        return this._baseUrl;
    }

    get headers(){
        return this._headers;
    }

    getItems(companyId){
        if (!companyId || companyId === ''){
            throw new Error('[e982952] company id not defined');
        }

        let url = `${this._baseUrl}/companies/${companyId}`;

        return new Promise((resolve, reject) =>{
            HttpUtils.get(url, this._auth).then(data => {

                if (data != null){
                    resolve(data);
                }
                else{
                    resolve([]);
                }

            }).catch(error => {
                reject(error);
            });
        });
    }

    getDownloadUrl(itemId){
        return `${this._baseUrl}/${itemId}/download?session-id=${HttpUtils.getSessionId(this._auth)}&instance-id=${HttpUtils.getInstanceId()}`;
    }

    /**
     * Ask the server if a file for an marketing material item exists
     * @param itemId
     * @returns {Promise<Boolean>}
     */
    existFile(itemId) {
        if (!itemId) {
            throw new Error('[e154854] itemId required is required');
        }

        let url = `${this._baseUrl}/${itemId}/exists-file`;
        return new Promise((resolve, reject) => {
            HttpUtils.head(url, this._auth).then(data => {
                resolve(data.success);
            }).catch(error => {
                reject(error);
            });
        });

    }

}