/**
 * Represents objects stored in memory temporarily.
 */
import {Tracer} from "@api/common/Tracer";

export class MemoryCache {

    constructor(){
        if (window.__cache == null) {
            this._items = {};
        }
        else{
            this._items = window.__cache._items;
        }
    }

    set(name, value) {
        this._items[name] = value;
    }

    get(name) {
        return this._items[name];
    }

    contains(name){
        let obj = this._items[name];
        return (obj != null);
    }

    removeItem(name){
        delete this._items[name];
    }

    static get instance(){

        if (window.__cache == null){
            window.__cache = new MemoryCache();
        }

        return window.__cache;
    }

    static reset(){
        window.__cache = new MemoryCache();
    }

}




