export class QuantityUpdateResult{

    constructor(){

        this._itemId = '';
        this._partNumber = '';
        this._inStock = '';
        this._qty = 0;
        this._version = '';
    }

    get itemId(){
        return this._itemId;
    }

    get partNumber(){
        return this._partNumber;
    }

    get inStock(){
        return this._inStock;
    }

    get qty(){
        return this._qty;
    }

    get version(){
        return this._version;
    }

    set itemId(value){
       /* this class is immutable */
        console.debug('[d912187] Value not changed. This class is immutable.');
    }

    set partNumber(value){
        /* this class is immutable */
        console.debug('[d464280] Value not changed. This class is immutable.');
    }

    set inStock(value){
       /* this class is immutable */
        console.debug('[d807257] Value not changed. This class is immutable.');
    }

    set qty(value){
       /* this class is immutable */
        console.debug('[d111241] Value not changed. This class is immutable.');
    }

    set version(value){
       /* this class is immutable */
        console.debug('[d974384] Value not changed. This class is immutable.');
    }

    static createInstance(itemId, partNumber, version, qty, inStock){

        let tmpInstance = new QuantityUpdateResult();
        tmpInstance._partNumber = partNumber;
        tmpInstance._itemId = itemId;
        tmpInstance._version = version;
        tmpInstance._qty = qty;
        tmpInstance._inStock = inStock;

        return tmpInstance;
    }

}