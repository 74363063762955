import {StoreProxy} from "@api/common/StoreProxy";
import _ from "lodash";
import {StringUtils} from "@api/common/StringUtils";
import {Roles} from "@api/core/Roles";
import {Tracer} from "@api/common/Tracer";


export class UserPermissions {

    static canImpersonate($store){

        if ($store == null){
            throw new Error("[e143866] $store is null");
        }

        let roles = StoreProxy.getRoles($store);

        if (roles == null || roles.length === 0)return false;

        let index =_.findIndex(roles, function(r){

            return r === Roles.ADMIN || r === Roles.SALES_MANAGER;
        });

        return index > -1;
    }

    static isSalesRole($store){

        if ($store == null){
            throw new Error("[e900615] $store is null");
        }

        let roles = StoreProxy.getRoles($store);

        // if not roles defined, then by default it is "Sales" roles
        if (roles == null || roles.length === 0)return true;

        let index =_.findIndex(roles, function(r){

            return r === Roles.SALES;
        });

        return index > -1;
    }

    static isDealerAdmin($store){

        if ($store == null){
            throw new Error("[e-760311] $store is null");
        }

        let roles = StoreProxy.getRoles($store);

        // if not roles defined, then by default it is "Sales" roles
        if (roles == null || roles.length === 0)return true;

        let index =_.findIndex(roles, function(r){

            return r === Roles.DEALER_ADMIN;
        });

        return index > -1;
    }

    static isSalesManagerRole($store) {
        if ($store == null) {
            throw new Error("[e215388] $store is null");
        }

        let roles = StoreProxy.getRoles($store);
        // if not roles defined, then by default it is "Sales" roles
        if (roles == null || roles.length === 0) return false;

        let index = _.findIndex(roles, function (r) {

            return r === Roles.SALES_MANAGER;
        });

        return index > -1;
    }

    static canBackOrder($store) {

        if ($store == null) {
            throw new Error("[err-837412] [MISSING_STORE_ARG] $store is null");
        }

        let profile = StoreProxy.getProfile($store);

        try {
            return !profile.account.backOrderRestricted;
        }
        catch(err){
            Tracer.current.error("[err-179772] Error retrieving the dealer account profile to determine if the account back order is restricted. Defaulting to no.")
            Tracer.current.error(err);
            return true;
        }
    }

    /**
     * Returns weather the current user can only view but not order parts
     * @param $store
     * @returns {boolean}
     */
    static isViewOnly($store){

        if ($store == null){
            throw new Error("[err-397033] [MISSING_STORE_ARG] $store is null");
        }

        let roles = StoreProxy.getRoles($store);

        // no roles, then is not view only.
        if (roles == null || roles.length === 0)return false;

        let index =_.findIndex(roles, function(r){

            if (r === Roles.SALES){
                return true;
            }
            else{
                return false;
            }
        });

        return index === -1;
    }

    /**
     * Returns weather the current user can only view but not order parts
     * @param $store
     * @returns {boolean}
     */
    static isOrdersRestricted($store){

        if ($store == null){
            throw new Error("[err-KN4CB] [MISSING_STORE_ARG] $store is null");
        }

        let roles = StoreProxy.getRoles($store);

        // no roles, then is not view only.
        if (roles == null || roles.length === 0)return false;

        let index =_.findIndex(roles, function(r){

            if (r === Roles.ORDERS_RESTRICTED){
                return true;
            }
        });

        return index !== -1;
    }

    /**
     * Returns true if the current account (current session) is
     * restricted from going over the limit on its credit.
     * @param $store
     * @returns {boolean}
     */
    static isAccountOverCreditRestricted($store){

        if ($store == null){
            throw new Error("#24643L:!ERROR!:[INVALID_ARGUMENT]: $store is null");
        }

        let roles = StoreProxy.getRoles($store);

        // no roles, then is not view only.
        if (roles == null || roles.length === 0)return false;

        let index =_.findIndex(roles, function(r){

            if (r === Roles.OVER_CREDIT_RESTRICTED){
                return true;
            }

        });

        return index !== -1;
    }

    static isEmergencyOrderEnabled($store){

        if ($store == null){
            throw new Error("#7J2YZ2:!ERROR!:[INVALID_ARGUMENT]: $store is null");
        }

        let roles = StoreProxy.getRoles($store);

        // no roles, then is not view only.
        if (roles == null || roles.length === 0)return false;

        let index =_.findIndex(roles, function(r){

            if (r === Roles.EMERGENCY_ORDER_ENABLED){
                return true;
            }

        });

        return index !== -1;
    }


    /**
     * Return true if the current user belongs to any roles provided in the argument
     * @param $store - the vuex store
     * @param args - the roles, comma delimited, example 'ADMIN', 'DEALER', etc
     * @returns {boolean}
     */
    static hasRoles($store, ...args){

        if (args.length === 0){
            return false;
        }

        for(let i = 0; i < args.length; i++){

            if (UserPermissions._belongsToRole($store, args[i])){
                return true;
            }
        }

        return false;
    }

    static hasNotRoles($store, ...args){

        if (args.length === 0){
            return false;
        }

        for(let i = 0; i < args.length; i++){
            if (UserPermissions._belongsToRole($store, args[i])){
                return false;
            }
        }

        return true;
    }

    static _belongsToRole($store, role){

        if ($store == null){
            throw new Error("[e397033] $store is null");
        }

        let roles = StoreProxy.getRoles($store);

        // no roles, then is not view only.
        if (roles == null || roles.length === 0)return false;

        let index =_.findIndex(roles, function(r){
            return StringUtils.compareIgnoreCase(r, role);
        });

        return false;
    }
}