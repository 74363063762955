import ViewHeader from "../../../components/ViewHeader/ViewHeader.vue";
import EMails from "./components/EMails/EMails.vue";
export default {
    name: "EmailNotifications",
    data() {
        return {
            isLoading: false,
            pane: 'Order'
        }
    },
    components: {
        ViewHeader,
        EMails
    },
    methods: {
        paneChanged() {

        }
    }
}