import {Convert} from "@api/common/Convert";
import {Tracer} from "@api/common/Tracer";
import {Dictionary} from "@api/common/Dictionary";
import {StringUtils} from "@api/common/StringUtils";

/**
 * Represents an open order line.
 */
export class OpenOrder {

    constructor() {
        this._orderNumber = '';
        this._type = '';
        this._purchaseOrder = '';
        this._branchId = '';
        this._partNumber = '';
        this._branchName = '';
        this._enteredDate = '';
        this._orderPosition = 0;
        this._total = 0;
        this._y = '';
        this._dor = '';
        this._pa = '';
        this._prior = new Dictionary()
    }


    /**
     *
     * @param response
     * @returns {null|OpenOrder}
     * @constructor
     */
    static Create(response){

        if (response == null){
            Tracer.current.warn("[warn-3K5V8L] The response data is not defined. Cannot create a Open Order instance");
            return null;
        }

        let obj = new OpenOrder();
        obj._orderNumber = Convert.toString(response.orderNumber);
        obj._type = OpenOrder._parseOrderType(response.orderType);
        obj._partNumber = Convert.toString(response.partNumber);
        obj._purchaseOrder = Convert.toString(response.purchaseOrder);
        obj._branchId = Convert.toString(response.branchId);
        obj._branchName = Convert.toString(response.branchName);
        obj._enteredDate = Convert.toString(response.orderDate);
        obj._total = Convert.toFloat(response.orderTotal, 2);
        obj._orderPosition = Convert.toInt(response.orderPosition);
        obj._y = Convert.toString(response.y);
        obj._dor = Convert.toString(response.dor);
        obj._pa = Convert.toString(response.pa);

        if (response.prior != null){

          response.prior.forEach(function (item){
              if (item != null){

                  let days = item.days;
                  let quantity = item.quantity;
                  obj._prior.set(days, quantity);
              }
          });

        }

        return obj;

    }

    /** Getters **/
    get orderNumber(){
        return this._orderNumber
    }

    /**
     * Gets the open order type.
     * @returns {string}
     */
    get type(){
        return this._type;
    }

    /**
     * Gets the part number
     * @returns {string}
     */
    get partNumber(){
        return this._partNumber;
    }

    /**
     * Gets the purchase order
     * @returns {string}
     */
    get purchaseOrder(){
        return this._purchaseOrder;
    }

    /**
     * Gets the total of this open order
     * @returns {number}
     */
    get total() {
        return this._total;
    }

    get enteredDate() {
        return this._enteredDate;
    }

    /**
     * Gets the branch name that is processing this open order record.
     * @returns {string}
     */
    get branchName() {
        return this._branchName;
    }

    /**
     * Gets the branch id that is processing this open order record.
     * @returns {string}
     */
    get branchId() {
        return this._branchId;
    }

    /**
     * Gets the order position
     * @returns {number}
     */
    get orderPosition(){
        return this._orderPosition;
    }

    get y(){
        return this._y;
    }

    get dor(){
        return this._dor;
    }

    get pa(){
        return this._pa;
    }

    /**
     * Gets the sales prior day
     * @param days
     * @returns {number|*}
     */
    getPriorDaysQuantity(days){

        if (days == null){
            throw new Error("[err-77DD1U] Expected a value, not an undefined value.")
        }

        let tmpDays = days;
        if (days instanceof String){
            tmpDays = Convert.toInt(days);
        }

        if (this._prior.contains(tmpDays)){
            return this._prior.get(tmpDays);
        }
        else{
            return -1;
        }
    }

    // Parse order type value from what the server returns to the
    // value expected on the UI.
    static _parseOrderType(value){

        let tmpValue = Convert.toString(value);
        if (StringUtils.compareIgnoreCase("Stock", tmpValue)){
            return "Stock"
        }
        else if (StringUtils.compareIgnoreCase("Direct", tmpValue)){
            return "Direct"
        }
        else {
            return "";
        }

    }
}