import axios from 'axios';
import {Tracer} from "@api/common/Tracer";

/**
 * Utility methods to use HTTP
 */
export class HttpUtils {

    constructor(){
    }

    static handleError(e, url){

        if (e == null)return;
        Tracer.current.debug('9F44727-(deprecated_method): handleError method is deprecated, use HandleHttpError');
        HttpUtils.HandleHttpError(e, url);
    }


    static HandleHttpError(error, url) {

        if (error == null) {
            return;
        }

        if (error.response == null){
            Tracer.current.criticalError(error, {url: url});
            return;
        }


        let statusCode = error.response.status;

        if (statusCode === 200 ||
            statusCode === 201 ||
            statusCode === 202 ||
            statusCode === 204 ||
            statusCode === 404) {
            return;
        }

        if (statusCode === 401) {
            HttpUtils.Handle401();
            return;
        }

        if (statusCode === 403) {
            HttpUtils.Handle403();
            return;
        }

        window.tracer.criticalError(error, {url: url});
        HttpUtils.NotifyError(error.response);
    }



    static Handle401() {

        window.app.$notify.warning({
            title: 'Session Expired',
            message: window.config.api.errorsMessages["401"],
            duration: 12000
        });

        setTimeout(() => {
            HttpUtils.deleteCookie(window.$instance.sessionCookieName);
            location.reload();
        }, 2000);

    }

    static Handle403() {

        window.app.$notify.warning({
            title: 'Unauthorized API call',
            message: window.config.api.errorsMessages["403"],
            duration: 9000
        });

    }


    static handled404(e, func){
        if (e.response){
            if (e.response.status === 404){
                func();
                return true;
            }
        }

        return false;
    }

    static NotifyError(response){

        const errorCode = response.status;
        let message = window.config.api.errorsMessages[errorCode];
        if (message == null){
            message = window.config.api.errorsMessages.generic;
        }

        window.app.$notify.error({
            title: `[${errorCode}] Server Error`,
            message: message
        });
    }


    static getHttpHeaders(auth, contentType){

        let headers = {};

        if (contentType){
            headers['content-type'] = contentType;
        }

        if (auth != null){

            if (auth.jwt == null){
                throw new Error("424913-(http): Auth object does not contain jwt.")
            }

            if (auth.sessionId == null){
                throw new Error("700669-(http): Auth object does not contain sessionId.")
            }

            headers['authorization'] = `Bearer ${auth.jwt}`;
            headers['x-app-session'] = auth.sessionId;
        }
        else{

            let sessionId = HttpUtils.getCookie(window.$instance.sessionCookieName);
            if (sessionId != null) {
                headers['x-app-session'] = sessionId;
            }

        }

        headers['x-correlation-id'] = window.config.correlationId;
        headers['x-app-instance'] = window.appInstanceId;

        return headers;
    }

    static getSessionId(auth){
        return auth.sessionId;
    }

    static getInstanceId(){
        return window.appInstanceId;
    }

    /**
     * Perform GET method on an url
     * @param url
     * @param auth
     * @returns {Promise<unknown>}
     */
    static get(url, auth) {
        return new Promise((resolve, reject) => {
            try {

                const headers = HttpUtils.getHttpHeaders(auth, 'application/json');

                axios.get(url, {headers: headers}).then(response => {
                    if (response.status === 204) {

                        Tracer.current.debug(`C4AAX6-(http)[status=204]: No payload`);
                        resolve(true);

                    } else if (response.status === 200 ||
                               response.status === 201 ||
                               response.status === 202 ||
                               response.status === 204) {

                        if (response.data == null) {
                            Tracer.current.debug(`2VWQAP-(http)[status=${response.status}]: No payload`);
                            resolve(true);
                        } else {
                            resolve(response.data);
                        }

                    } else {

                        Tracer.current.error(`CZ87E8-(http)[status='${response.status}]: status no supported.`, {url: url});
                        reject(response.status);
                    }
                }).catch((e) => {
                    if (e.response) {

                        if (e.response.status === 404) {
                            Tracer.current.debug(`9GBUWN-(http)[status=404][resource_not_found]: ${url}`);
                            resolve(null);
                            return;
                        }

                        if (e.response.status === 439) {
                            Tracer.current.warning(`EWC5JO-(http)[status=439][condition_not_met]: ${url}`);
                            let err = {
                                status: e.response.status,
                                data: e.response.data
                            }

                            reject(err);
                            return;
                        }
                    }

                    HttpUtils.HandleHttpError(e, url);
                    reject(e);
                });
            } catch (err) {

                Tracer.current.error(err, {tag: "65FR41", url: url});
                reject(err);
            }
        });
    }

    static postAuth(url, payload){
        return new Promise((resolve, reject) => {

                try {

                    let headers = HttpUtils.getHttpHeaders(null, 'application/json');

                    axios.post(url, payload, {headers: headers}).then(response => {
                        if (response.status === 204) {
                            resolve(true);
                        } else if  (response.status === 200 ||
                                    response.status === 201 ||
                                    response.status === 202 ||
                                    response.status === 204) {

                            if (response.data == null){
                                resolve(true);
                            }
                            else {
                                resolve(response.data);
                            }

                        } else {
                            throw new Error(`EWC5JO-(http)[status=${response.status}]: SStatus not supported.`);
                        }

                    }).catch( e => {

                        if (e != null && e.response != null) {
                            if (e.response.status === 404) {
                                window.tracer.debug(`AB604S-(http)[status=404][resource_not_found]: ${url}`)
                                reject();
                                return;
                            }

                            if (e.response.status === 439) {
                                window.tracer.warning(`D0SH8U-(http)[status=439][condition_not_met]: ${url}`);
                                let err = {
                                    status: e.response.status,
                                    data: e.response.data
                                }

                                reject(err);
                                return;
                            }

                            if (e.response.status === 401 || e.response.status === 403) {
                                window.tracer.warning(`8ZHTEL-(http)[status=${e.response.status}][not_allowed]: ${url}`);
                                reject();
                                return;
                            }
                        }

                        HttpUtils.HandleHttpError(e, url);
                        reject(e);
                    });
                } catch (err) {

                    Tracer.current.error(err, {tag: "D2KZGY", url: url});
                    reject(err);
                }
            }
        );
    }

    static post(url, auth, payload){

        return new Promise((resolve, reject) => {

                try {
                    let headers = HttpUtils.getHttpHeaders(auth, 'application/json');

                    axios.post(url, payload, {headers: headers}).then(response => {
                        if (response.status === 204) {
                            resolve(true);
                        } else if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 204) {
                            if (response.data == null){
                                resolve(true);
                            }
                            else {
                                resolve(response.data);
                            }
                        } else {

                            Tracer.current.error(`D5DVQ8-(http)[status=${response.status}]: Status not supported.`, {url: url});
                            reject(response.status);
                        }

                    }).catch((e) => {

                        if (e.response) {

                            if (e.response.status === 404) {
                                window.tracer.debug(`2TJO5S-(http)[status=404][resource_not_found]: ${url}`)
                                resolve(null);
                                return;
                            }

                            if (e.response.status === 439) {
                                window.tracer.warning(`1QXZ78-(http)[status=439][condition_not_met]: ${url}`);
                                let err = {
                                    status: e.response.status,
                                    data: e.response.data
                                }

                                reject(err);
                                return;
                            }

                        }

                        HttpUtils.HandleHttpError(e, url);
                        reject(e);
                    });
                } catch (err) {

                    window.tracer.error(err, {tag: "567446", url: url});
                    reject(err);
                }
            }
        );

    }


    static put(url, auth, payload){

        return new Promise((resolve, reject) => {

                try {
                    let headers = HttpUtils.getHttpHeaders(auth, 'application/json');


                    axios.put(url, payload, {headers: headers}).then(response => {
                        if (response.status === 204) {
                            resolve(true);
                        } else if (response.status === 200 || response.status === 201 || response.status === 202) {
                            if (response.data == null){
                                resolve(true);
                            }
                            else {
                                resolve(response.data);
                            }
                        } else {
                            Tracer.current.error(`E3F836-(http)[status=${response.status}]: Status not supported.`, {url: url});
                            reject(response.status);
                        }

                    }).catch((e) => {
                        if (e.response) {
                            if (e.response.status === 404) {
                                window.tracer.debug(`513N0A-(http)[status=404][resource_not_found]: ${url}`);
                                resolve(false);
                                return;
                            }

                            if (e.response.status === 439) {
                                window.tracer.warning(`8LKRO6-(http)[status=439][condition_not_met]: ${url}`);

                                let err = {
                                    status: e.response.status,
                                    data: e.response.data
                                }

                                reject(err);
                                return;
                            }
                        }

                        HttpUtils.HandleHttpError(e, url);
                        reject(e);
                    });
                } catch (err) {

                    window.tracer.error(err, {tag: "46UNX2", url: url});
                    reject(err);
                }
            }
        );

    }

    static head(url, auth){

        return new Promise((resolve, reject) => {

            const headers = HttpUtils.getHttpHeaders(auth, 'application/json');

            axios.head(url, {headers: headers}).then(response => {
                if (response.status === 204 ||
                    response.status === 200 ||
                    response.status === 201 ||
                    response.status === 202) {

                    const warningMessage = response.headers["warning"];

                    let hasWarning = false;
                    if (warningMessage != null){
                        hasWarning = true;
                        Tracer.current.warning(`93ZWV5-(http)[status=${response.status}][warning]: ${warningMessage}`);
                    }

                    const data = {
                        success: true,
                        status: response.status,
                        data: response.data,
                        warning: hasWarning,
                    }

                    resolve(data);
                }

                else {
                    Tracer.current.error(`802525-(http)[status=${response.status}]: Http status not supported.`);
                    throw new Error(`The HTTP status '${response.status}' is not supported by the 'HttpUtils' helpers for the HEAD actions`);
                }

            }).catch((e) => {
                if (e.response) {

                    if (e.response.status === 404) {
                        Tracer.current.debug(`EW63PV-(http)[status=404][resource_not_found]: ${url}`);
                        const data = {
                            success: false,
                            status: e.response.status,
                            data: e.response.data,
                            warning: false,
                        }

                        resolve(data);
                        return;
                    }

                    if (e.response.status === 439) {

                        Tracer.current.warning(`35C2U1-(http)[status=439][condition_not_met]: ${url}`);
                        reject(e);
                        return;

                    }

                    if (e.response.status === 503){
                        Tracer.current.warning(`H2H290-(http)[status=503][server_unavailable]: ${url}`);
                        reject(e);
                        return;
                    }


                }

                HttpUtils.HandleHttpError(e, url);
                reject(e);
            });
        });
    }

    static delete(url, auth){
        return new Promise((resolve, reject) => {

            let headers = HttpUtils.getHttpHeaders(auth, 'application/json');

            axios.delete(url, {headers: headers}).then(response => {
                if (response.status === 204) {
                    resolve(true);
                } else if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 204) {
                    if (response.data == null){
                        resolve(true);
                    }
                    else {
                        resolve(response.data);
                    }
                } else {
                    window.tracer.error(`229362 ${response.status} not supported.`);
                    reject(response.status);
                }

            }).catch((e) => {
                if (e.response) {
                    if (e.response.status === 404) {
                        window.tracer.debug(`6FLJTY-(http)[status=404][resource_not_found]: ${url}`);
                        resolve(false);
                        return;
                    }

                    if (e.response.status === 439) {
                        window.tracer.warning(`9EWU6B-(http)[status=439][condition_not_met]: ${url}`);
                        let err = {
                            status: e.response.status,
                            data: e.response.data
                        }

                        reject(err);
                        return;
                    }
                }

                HttpUtils.HandleHttpError(e, url);
                reject(e);

            });
        });
    }


    static setCookie(name, value, days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }

        document.cookie = name + "=" + (value || "") + expires + "; path=/; SameSite=Strict";
    }

    static getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);

            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }

        return null;
    }

    static deleteCookie(name){

        document.cookie = name + "=''; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=Strict";
    }
}

