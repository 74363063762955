import {Tracer} from "@api/common/Tracer";

export class OrderHistorySearch {

    constructor(name) {

        this.name = name;
        this._results = {};
        this._pages = {};


        //search parameters;
        this._searchBy = "";
        this._value = "";
        this._value2 = "";
        this._searchMethod = "";
        this._currentPage = -1;

    }

    get items() {
        return this._results.items;
    }

    get count() {
        return this._results.count;
    }

    get totalRecords() {
        return this._results.totalRecords;
    }

    get totalPages() {
        return this._results.totalPages;
    }

    get currentPage() {
        return this._results.page;
    }

    get empty() {
        if (!this._results.count) return true;
        if (this._results.count === 0) return true;
        if (this._results.items.length === 0) return true;
    }

    page(pageNumber) {

        Tracer.current.debug(`6TILBQ-(order_search): Paging to page number ${pageNumber} for search method ${this._searchMethod}`);

        this._currentPage = pageNumber;
        if (this._searchMethod === 'search') {
            return this.search(this._searchBy, this._value, pageNumber);
        }
        else if (this._searchMethod === 'searchDefault') {
            return this.searchDefault(pageNumber);
        }
        else if (this._searchMethod === 'searchMonths') {
            return this.searchLastMonths(12, pageNumber);

        } else if (this._searchMethod === 'searchDateRange') {
            return this.searchDateRange(this._value, this._value2, pageNumber);
        }

        throw new Error('418821: Unable to identify the previous search method to perform a page');
    }

    clear() {
        this._clear();
    }

    search(searchBy, value, page) {


        let searchPromise = null;

        if (['orderNumber', 'purchaseOrder', 'invoiceNumber', 'invoice', 'serialNumber', 'partNumber'].indexOf(searchBy) === -1) {
            throw new Error(`757642-(order_search)[invalid_search_by=${searchBy}]: The value of ${searchBy} is invalid as search by. Expected values are: orderNumber, purchaseOrder, InvoiceNumber, serialNumber, partNumber`);
        }

        if (!page) {
            throw new Error('001576-(order_search): currentPage argument is not defined');
        }

        this._searchBy = searchBy;
        this._value = value;
        this._searchMethod = 'search';

        if (searchBy === 'orderNumber') {
            searchPromise = this._searchByOrderNumber(value, page);
        }
        if (searchBy === 'purchaseOrder') {
            searchPromise = this._searchByPurchaseOrder(value, page);
        }
        if (searchBy === 'invoiceNumber' || searchBy === 'invoice') {
            searchPromise = this._searchByInvoiceNumber(value, page);
        }
        if (searchBy === 'serialNumber') {
            searchPromise = this._searchBySerialNumber(value, page);
        }
        if (searchBy === 'partNumber') {
            searchPromise = this._searchByPartNumber(value, page);
        }

        if (searchPromise == null) {
            throw new Error('120548-(order_search): Wrong extended class implementation. Expected a promise.')
        }

        return new Promise((resolve) => {
            const vm = this;
            searchPromise.then(function (data) {
                vm._setItems(data);
                resolve();

            }).catch(function (error) {

                Tracer.current.error('527790-(order_search): Error occurred while performing search against server');
                Tracer.current.error(error);

                vm._clear();
                resolve()
            });
        });


    }

    searchDateRange(startDate, endDate, page) {
        this._searchBy = '';
        this._value = startDate;
        this._value2 = endDate;
        this._searchMethod = 'searchDateRange';

        Tracer.current.debug(`448917: Search date ranges of ${this._value} to ${this._value2}. Page ${page}`);
        let searchPromise = this._searchByDateRange(this._value, this._value2, page);

        return new Promise((resolve) => {
            let vm = this;
            searchPromise.then(function (data) {
                vm._setItems(data);
                resolve();

            }).catch(function (error) {

                Tracer.current.error('527790: Error occurred while performing search against server');
                Tracer.current.error(error);

                vm._clear();
                resolve()
            });
        });
    }

    searchLastMonths(months, page) {

        this._searchBy = '';
        this._value = '';
        this._searchMethod = 'searchMonths';


        if (page == null) {
            page = 1;
        }

        if (months == null) {
            months = 12;
        }

        // if we already fetch the page, let's dont do again, just pass what we have.
        if (this._pages[page]) {
            this._results = this._pages[page];
            return new Promise((resolve => {
                resolve();
            }));
        }


        // todo: make months configurable or a constant.
        let searchPromise = this._searchByMonths(months, page);
        if (searchPromise == null) {
            throw new Error('853334: Wrong extended class implementation. Expected a promise for _searchByMonths.')
        }


        return new Promise((resolve) => {
            let vm = this;
            searchPromise.then(data => {

                if (!data) {
                    Tracer.current.warn('DADFYR Unexpected undefined value after performing search');
                    resolve();
                    return;
                }

                vm._setItems(data);
                resolve();

            }).catch(error => {

                Tracer.current.error('6TJKDQ: Error occurred while performing search against server');
                Tracer.current.error(error);

                vm._clear();
                resolve();
            });
        });

    }

    searchDefault(page) {

        this._searchBy = '';
        this._value = '';
        this._searchMethod = 'searchDefault';

        if (page == null) {
            page = 1;
        }

        // if we already fetch the page, let's don't do again, just pass what we have.
        if (this._pages[page]) {
            this._results = this._pages[page];
            return new Promise((resolve => {
                resolve();
            }));
        }


        let searchPromise = this._searchDefault(page);

        if (searchPromise == null) {
            throw new Error('AMGSOR: Wrong extended class implementation. Expected a promise for _searchDefault.')
        }


        return new Promise((resolve) => {
            let vm = this;
            searchPromise.then(data => {

                if (!data) {
                    Tracer.current.warn('388270: Unexpected undefined value after performing search');
                    resolve();
                    return;
                }

                vm._setItems(data);
                resolve();

            }).catch(error => {

                Tracer.current.error('833097: Error occurred while performing search against server');
                Tracer.current.error(error);

                vm._clear();
                resolve();
            });
        });

    }

    _searchByInvoiceNumber(value, page) {
        throw new Error('[e134112] This method must be extended.');
    }

    _searchByOrderNumber(value, page) {
        throw new Error('[e458454] This method must be extended.');
    }

    _searchBySerialNumber(value, page) {
        throw new Error('[e017171] This method must be extended.');
    }

    _searchByPartNumber(value, page) {
        throw new Error('132601: This method must be extended.');
    }

    _searchByPurchaseOrder(value, page) {
        throw new Error('067497: This method must be extended.');
    }

    _searchByMonths(months, page) {
        throw new Error('FRP540: This method must be extended.');
    }

    _searchByDateRange(startDate, endDate, page) {
        throw new Error('891503: This method must be extended.');
    }

    _searchDefault(page) {
        throw new Error('21B5Q2: This method must be extended.');
    }

    _clear() {
        Tracer.current.debug('390288: Order history items cleared');
        this._results = {};
        this._value = '';
        this._value2 = '';
        this._searchBy = '';
        this._searchMethod = '';
        this._pages = {};
    }


    _setItems(data) {

        if (!data) {
            throw new Error('[e188679] data argument is undefined. Unable to set items.');
        }

        this._results = data;
        this._pages[data.page] = data;
    }

}