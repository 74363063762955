import {PostalCodeItem} from "./PostalCodeItem";
import {IPostalCodeResponseItem} from "@/modules/PostalCodes/__client/IPostalCodeResponseItem";
import {PostalCodeClient} from "@/modules/PostalCodes/__client/PostalCodeClient";
import {Tracer} from "@api/common/Tracer";
import {StringUtils} from "@/lib/StringUtils";

export class PostalCodes {

    constructor(){
    }

    lookup(code : string, auth : string | null) : Promise<PostalCodeItem[]> {

        return new Promise<PostalCodeItem[]>((resolve, reject) => {

            if (StringUtils.isEmptyOrNull(code)){
                resolve([]);
                return;
            }

            if (auth == null){
                auth = "";
            }

            const client = new PostalCodeClient(auth);

            client.lookup(code).then( (items : IPostalCodeResponseItem[]) => {

                const results : PostalCodeItem[] = [];
                for (let i: number = 0; i < items.length; i++) {

                    const item: IPostalCodeResponseItem = items[i];
                    results.push(new PostalCodeItem(item.postalCode, item.country, item.city, item.region, item.regionAbbr))
                }

                Tracer.current.debug(`8FW2601-(postal_code): Total ${results.length} city returned.`);
                resolve(results);
                return;

            }).catch(err => {
                Tracer.current.error("76K3I80-(postal_code): Error digesting the postal code results");
                Tracer.current.error(err);
                reject(err);
            })

        });
    }
}