import {IAnalytics} from "@/lib/analytics/IAnalytics";
import "posthog-js/dist/recorder"
// import "posthog-js/dist/surveys"
import "posthog-js/dist/exception-autocapture"
import "posthog-js/dist/tracing-headers"
import "posthog-js/dist/web-vitals"
import posthog from 'posthog-js'
import { AppConfig } from "@/config/AppConfig";
import {Tracer} from "@api/common/Tracer";
import {LoggedUser} from "@/lib/analytics/LoggedUser";
import {PosthogConfig} from "@/config/PosthogConfig";



export class PostHogAnalytics implements IAnalytics {

    init(): void {

        const config: PosthogConfig = AppConfig.current.getPosthogConfig();
        const correlationId: string = AppConfig.current.correlationId;
        const instanceId: string = window.appInstanceId;
        const version: string = window.$build.version;

        posthog.init(config.key,
            {
                api_host: config.url,
                person_profiles: 'identified_only',

            });

        const currentUserId = posthog.get_distinct_id();
        posthog.identify(currentUserId, {}, {
            correlationId: correlationId,
            instanceId: instanceId,
            version: version
        })
    }

    captureError(error: any): void {
    }

    captureEvent(eventName: string, data: any): void {
    }

    capturePage(path: string): void {
    }


    identifyUser(loggedUser: LoggedUser): void {

        try {

            const correlationId: string = AppConfig.current.correlationId;
            const instanceId: string = window.appInstanceId;
            const version: string = window.$build.version;

            posthog.identify(
                loggedUser.userId, // Required. Replace 'distinct_id' with your user's unique identifier
                {
                    userId: loggedUser.userId,
                    sessionId: loggedUser.sessionId,
                    name: loggedUser.name
                },
                {
                    correlationId: correlationId,
                    instanceId: instanceId,
                    version: version
                });

            posthog.group("account", loggedUser.account);
            posthog.group("branch", loggedUser.branch)
        } catch (e) {
            Tracer.current.error(e);
        }

    }

    exit() : void {
        posthog.reset();
    }

}
