import { DateTime } from 'luxon';

export default {
    name: "MaintenanceBanner",
    data() {
        return {
        }
    },
    computed:{
        message(){
            return this.createMessage();
        },
        className() {
            return this.$store.getters.maintenanceStatus;
        },
        enabled() {
            return this.$store.getters.maintenanceStatus === 'On' || this.$store.getters.maintenanceStatus === 'Warning';
        }
    },
    methods: {
        createMessage(){

            const maintenance = this.$store.getters.maintenance;

            if (maintenance.status === "On"){

                const end =  maintenance.end.toLocaleString(DateTime.DATETIME_SHORT);
                return `Maintenance in progress. Please try again after ${end}.`;
            }
            else if (maintenance.status === "Warning"){

                const minutes = maintenance.warnTime;

                const start = maintenance.start.toLocaleString(DateTime.DATETIME_SHORT);
                const end = maintenance.end.toLocaleString(DateTime.DATETIME_SHORT);

                return `Maintenance will start in ${minutes} minutes and will last from ${start} to ${end}.`;
            }
            else {
                return "";
            }
        }
    }
}