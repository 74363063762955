<template>
    <div class="box grow view-title" :class="red === true? 'view-title-red' : ''">
      <div class="title-margin">{{title}}</div>
      <div class="sub-title"><slot></slot></div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                required: false,
                default: '',
            },
            red: {
              type: Boolean,
              required: false,
              default: false
            }
        }
        ,
    };
</script>
<style scoped lang="scss" src="./ViewHeader.scss"></style>

