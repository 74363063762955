import { Logtail } from "@logtail/browser";

const _EXCLUDE_LOCAL = false;
const _SUBMIT_INFO = false;

export class Tracer {

    constructor(){

        this._internal_tracer = new Logtail(process.env.VUE_APP_LOGTAIL_TOKEN);
        this._tags = new Map();
        this._submitLog = true;
        this._submitInfo = false;

        if (_EXCLUDE_LOCAL && window.config.isLocal){
            this._submitLog = false;
        }

    }

    get isDebugEnabled() {
        return (window.config.environment !== 'Production' || window.$debugMode === true);
    }

    setTag(name, value){

        if (name == null)return;
        if (value == null){
            value = "";
        }

        try {
            this._tags.set(name, value);
        }
        catch(error){
            console.error(error);
        }
    }

    /**
     * OBSOLETE - use SetTag
     * @param name
     * @param value
     */
    tag(name, value){
        this.setTag(name, value);
    }


    error(err, data){

        if (err == null || err === ""){
            return;
        }

        try{

            if (data == null){
                data = {};
            }

            console.error(err);

            if (this._submitLog) {
                const tmpTags = Object.fromEntries(this._tags);

                this._internal_tracer.error(err, {
                    instance: tmpTags,
                    additional_data: data
                });
            }
        }
        catch(ex){
            console.error(ex);
        }
    }

    criticalError(err, data){

        if (data == null){
            data = {};
        }

        try {

            if (err != null){
                console.error(err);
                console.error(data);
            }

            if (this._submitLog) {
                const tmpTags = Object.fromEntries(this._tags);
                this._internal_tracer.error(err, {
                    instance: tmpTags,
                    critical: true,
                    additional_data: data
                });
            }
        }
        catch(ex){
            console.error(ex);
        }
    }

    /**
     * Writes a warning message
     * @param message Warning message
     * @param data
     */
    warn(message, data) {
        try {

            console.warn(message);

            if (data == null) {
                data = {};
            }

            if (this._submitLog) {

                const tmpTags = Object.fromEntries(this._tags);
                this._internal_tracer.warn(message, {
                    instance: tmpTags,
                    additional_data: data
                });
            }

        } catch (ex) {
            console.error(ex);
        }
    }

    /**
     * Writes a warning message
     * @param message
     * @param data
     */
    warning(message, data){
        this.warn(message, data);
    }

    obsolete(message, data)
    {

        if (data == null){
            data = {};
        }

        let tmpMessage = `DEPRECATED:: ${message}`;
        console.warn(tmpMessage);

        try{
            if (this._submitLog) {
                const tmpTags = Object.fromEntries(this._tags);
                this._internal_tracer.warning(tmpMessage, {
                    instance: tmpTags,
                    deprecated_call: true,
                    additional_data: data
                });
            }
        }
        catch(error){
            console.error(error);
        }
    }

    /**
     * Writes a debug message
     * @param message
     */
    debug(message){

        if (this.isDebugEnabled) {
            console.debug(message);
        }

    }

    whenDebugInvoke(callback){
        if (this.isDebugEnabled) {
            if (callback != null){
                callback();
            }
        }
    }

    /**
     * Writes a information message
     * @param message
     * @param data - additional data
     */
    info(message, data){

        if (message == null || message === "")
        {
            return;
        }

        try {

            if (window.config.environment !== 'Production' ||
                window.$debugMode === true) {
                console.info(message);
            }


            if (data == null) {
                data = {};
            }

            if (this._submitLog && _SUBMIT_INFO) {
                const tmpTags = Object.fromEntries(this._tags);
                this._internal_tracer.info(message, {
                    instance: tmpTags,
                    additional_data: data
                });
            }

        } catch (ex) {
            console.error(ex);
        }
    }

    verbose(message){
        if (window.config.isLocal) {
            console.debug(message);
        }
    }

    /**
     * Gets the current tracer instance.
     * @returns {Tracer|*}
     */
    static get current() {

        if (window.tracer == null){
            window.tracer = new Tracer();
        }

        return window.tracer;
    }
}

if (window.tracer == null){
    window.tracer = new Tracer();
}