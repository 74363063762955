import axios from 'axios';
import {HttpUtils} from '@api/common/HttpUtils';
import {QuantityUpdateResult} from "../QuantityUpdateResult";
import {Tracer} from "@api/common/Tracer";
import {CartType} from "@api/modules/shoppingCart/services/CartType";
import {CountryRegionLookup} from "@/lib/CountryRegionLookup";

export class ShoppingCartService {

    constructor(auth) {
        this._auth = auth;
        this._apiUrl = `${window.config.api.partsUri}/v1/shopping-carts`;
        this._headers = HttpUtils.getHttpHeaders(auth, 'text/json');
    }

    getShoppingCart(userId)  {

        let url = `${this._apiUrl}/${userId}`;

         return new Promise((resolve, reject) => {
             axios.get(url, {headers: this._headers}).then(response => {

                 resolve(response.data);

             }).catch((e) => {

                 if (e.response) {
                     if (e.response.status === 404) {
                         resolve([]);
                         return;
                     }
                 }

                 Tracer.current.error(e);
                 HttpUtils.handleError(e);
                 reject(e);

             });
         });

    }

    addItem(userId, item) {

        return new Promise((resolve, reject) => {

            let url = `${this._apiUrl}/${userId}/add`;

            axios.post(url, item, {headers: this._headers}).then(response => {
                resolve(response.data);
            }).catch((e) => {
                if (e.response) {
                    if (e.response.status === 404) {
                        reject();
                        return;
                    }
                }

                window.tracer.error(e);
                HttpUtils.handleError(e);
                reject();
            });

        });
    }

    removeItem(userId, itemId) {

        let url = `${this._apiUrl}/${userId}/${itemId}`;

        return new Promise((resolve, reject) => {


            HttpUtils.delete(url, this._auth).then( result => {

                    if (result == null){

                        resolve({
                            success: false,
                            version: null,
                        });
                    }
                    else {

                        resolve({
                            success: true,
                            version: result.version
                        });

                        window.tracer.debug(`#3F27IQ:[CART_DELETE][ITEM:${itemId}]: Delete on the server`);
                    }

                }

            ).catch(err => {

                if (err != null){
                    window.tracer.error(err);
                }

                reject(err);
            });
        });
    };

    /**
     * Add items to the shopping cart (bulk shopping cart).
     * @param userId - the userId.
     * @param items - the list of items to be added.
     * @param emergency - if true, means this item is an emergency and it will be placed in a different "cart"
     * @returns {Promise<unknown>}
     */
    addItems(userId, items, emergency){

        try {
            return new Promise((resolve, reject) => {

                let url = `${this._apiUrl}/${userId}/bulk`;

                if (emergency != null && emergency) {
                    url = `${this._apiUrl}/${userId}/emergency`;
                }

                HttpUtils.post(url, this._auth, items).then(data => {
                    resolve(data);
                }).catch(err => {
                    Tracer.current.error(err);
                    reject(err);
                });

            });
        }
        catch(e){
            Tracer.current.error("7RPZ9G (add items): Failed to add items to the server.")
            Tracer.current.error(e);
        }
    }


    deleteItems(userId, emergency){
        return new Promise((resolve, reject) => {
            let url = `${this._apiUrl}/${userId}/bulk`;

            if (emergency != null && emergency){
                url = `${this._apiUrl}/${userId}/emergency`;
            }

            HttpUtils.delete(url, this._auth).then(data => {
                resolve();
            }).catch(err => {
                if (err != null){
                    window.tracer.error(err);
                }
                reject(err);
            });

        });
    }

    /**
     * Returns the items saved in the server.
     * @param userId
     * @param emergency
     * @returns {Promise<unknown>}
     */
    getItems(userId, emergency){
        return new Promise((resolve, reject) => {

            let url = `${this._apiUrl}/${userId}/bulk`;

            if (emergency != null && emergency){
                url = `${this._apiUrl}/${userId}/emergency`;
            }

            HttpUtils.get(url, this._auth).then(data => {
                if (data != null) {
                    resolve(data.items);
                }
                else{
                    resolve([]);
                }
            }).catch(err => {
                if (err != null){
                    window.tracer.error(err);
                }
                reject(err);
            });

        });
    }


    getEmergencyItems(userId){
        return new Promise((resolve, reject) => {

            let url = `${this._apiUrl}/${userId}/emergency`;

            HttpUtils.get(url, this._auth).then(data => {
                if (data != null) {
                    resolve(data.items);
                }
                else{
                    resolve([]);
                }
            }).catch(err => {
                if (err != null){
                    window.tracer.error(err);
                }
                reject(err);
            });

        });
    }


    updateQuantity(userId, itemId, quantity) {

        return new Promise((resolve, reject) => {

            let url = `${this._apiUrl}/${userId}/${itemId}/update-quantity`;

            axios.put(url, {qty: quantity}, {headers: this._headers}).then(response => {

                let data = response.data;
                let updateResult = QuantityUpdateResult.createInstance(data.cartItemId, data.partNumber, data.version, data.qty, data.inStock);
                resolve(updateResult);

            }).catch((e) => {
                if (e.response) {
                    if (e.response.status === 404) {
                        reject();
                        return;
                    }
                }

                window.tracer.error(e);
                HttpUtils.handleError(e);
                reject();

            });

        });

    }

    changeBranch(userId, itemId, branchId) {

        return new Promise((resolve, reject) => {

            let url = `${this._apiUrl}/${userId}/${itemId}/change-branch`;

            axios.put(url, {id: branchId}, {headers: this._headers}).then(response => {
                resolve({
                    success: true,
                    version: response.data.version
                });
            }).catch((e) => {

                if (e.response) {
                    if (e.response.status === 404) {
                        reject();
                        return;
                    }
                }

                window.tracer.error(e);
                HttpUtils.handleError(e);
                reject();
            });

        });

    }

    placeOrder(userId, order, type) {

        if (type !== CartType.STANDARD && type !== CartType.BULK && type !== CartType.EMERGENCY){
            throw new Error(`1E2LW4-(place_order)[invalid_cart]: The cart type '${type}' is not a valid`);
        }

        // sanitize state in case the state has more than 2 characters.

        let billingState = order.billing.state;
        let shippingState = order.shipping.state;

        if (order.billing.state.length > 2){

            const countryRegionLookup = new CountryRegionLookup();
            billingState = countryRegionLookup.findAbbreviation(order.billing.state);
        }

        if (order.shipping.state.length > 2){

            const countryRegionLookup = new CountryRegionLookup();
            shippingState = countryRegionLookup.findAbbreviation(order.shipping.state);
        }

        const data = {
            purchaseOrder: order.purchaseOrder,
            attention: order.attentionTo,
            phoneNumber: order.phoneNumber,
            vinNumber: order.vinNumber,
            emailAddress: order.emailAddress,
            comments: order.comments,
            dealercode: order.dealerCode,
            shipToBillingAddress: order.shipToBillingAddress,
            shipTo: {
                name: order.shipping.customer,
                address: order.shipping.address,
                address2: order.shipping.address2,
                city: order.shipping.city,
                state: shippingState,
                zipCode: order.shipping.zipCode
            },
            billTo: {
                name: order.billing.customer,
                address: order.billing.address,
                address2: order.billing.address2,
                city: order.billing.city,
                state: billingState,
                zipCode: order.billing.zipCode
            }
        };

        return new Promise((resolve, reject) => {

            let url = `${this._apiUrl}/${userId}/place-order`;

            if (type === CartType.BULK) {
                url = url + '/bulk';
            } else if (type === CartType.EMERGENCY) {
                url = url + '/emergency';
            }

            HttpUtils.post(url, this._auth, data).then(resData => {
                    resolve(resData);
                }
            ).catch(err => {

                if (err.status === 439) {
                    resolve(err.data);
                    return;
                }

                Tracer.current.error('8VGBM0-(place_order): Failed to post order to the server!')
                Tracer.current.error(err);
                reject();
            });
        });

    };

}

