
import {CountryCode} from "@/lib/CountryRegionLookup/CountryCode";

const _validCountryList = ["USA", "US", "CA", "CAN"];

/**
 * Represents Address information for placing order.
 * <br><br>
 *
 */
export class Address {

    constructor() {
        this._customerName = "";
        this._address1 = "";
        this._address2 = "";
        this._city = "";
        this._state = "";
        this._zipCode = "";

        // default country.
        this._country = "USA";
    }

    setAddress(customer, addr1, addr2, city, state, zipCode, country){
        this._customerName = customer;
        this._address1 = addr1;
        this._address2 = addr2;
        this._city = city;
        this._state = state;
        this._zipCode = zipCode;

        if (this._country != null && this._country.length > 0){
            this.__setCountry(country);
        }
        else{
            this._country = "USA";
        }

    }

    set customer(value){
        this._customerName = value;
    }

    get customer(){
       return this._customerName;
    }

    set address(value){
        this._address1 = value;
    }

    get address(){
       return this._address1;
    }

    set address1(value){
        this._address1 = value;
    }

    get address1(){
        return this._address1;
    }

    set address2(value){
        this._address2 = value;
    }

    get address2() {
        return this._address2;
    }

    set city(value){
        this._city = value;
    }

    get city(){
        return this._city;
    }

    set state(value){
        this._state = value;
    }

    get state(){
        return this._state;
    }

    set zipCode(value)
    {
        this._zipCode = value;
    }


    get zipCode(){
        return this._zipCode;
    }

    /**
     * set the country. Current valid values are "USA" and "CAN"
     * @param value
     */
    set country(value) {
        this.__setCountry(value);
    }

    /**
     *
     * @return {string}
     */
    get country(){
        return this._country;
    }

    /**
     * Returns the country as a country code.
     * @return {CountryCode}
     */
    getCountryCode() {
        switch(this._country){
            case "USA":
            case "US":
                return CountryCode.US;
            case "CA":
            case "CAN":
                return CountryCode.Canada;
            default:
                throw new Error("1SAUI7912-(place_order_address): Invalid country value for converting to a country code.");
        }
    }

    __setCountry(value){

        if (value == null){
            return;
        }

        value = value.toUpperCase();
        if (_validCountryList.includes(value)) {
            this._country = value;
        } else {
            throw new Error("21WQWC3-(place_order_address): Invalid country value");
        }
    }

}