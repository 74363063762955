/*
    Built upon the code crafted by https://gist.github.com/deeja/008c2c36764f11cbc02818e7d793738a
 */

export class VinValidator {

    constructor() {
    }

    static validate(vin: string): boolean {

        if (vin.length != 17) return false;

        const tmpVin : string = vin.trim().toUpperCase();
        return VinValidator.getCheckDigit(tmpVin) === tmpVin[8];
    }

    private static getCheckDigit(vin: string): string {

        if (vin == null){
            return "";
        }

        if (vin.length != 17){
            return "";
        }

        const map: string = "0123456789X";
        const weights: string = "8765432X098765432";
        let sum: number = 0;
        for (let i: number = 0; i < weights.length; ++i) {
            sum += VinValidator.transliterate(vin[i]) * map.indexOf(weights[i]);
        }

        return map[sum % 11];
    }

    private static transliterate(c: string): number {

        const letters: string = "0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ";
        return letters.indexOf(c) % 10;
    }

}