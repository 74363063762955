import { detect } from 'detect-browser'
const browser = detect();

// @ is an alias to /src
import { StoreProxy } from '@api/common/StoreProxy'
import HorizontalNav from '@/views/sections/Horizontal-Nav/horizontal-nav.vue';
import VerticalNav from '@/views/sections/vertical-nav.vue'
import Toolbar from './components/toolbar/Toolbar.vue';
import Footer from '@/views/sections/footer.vue';
import {Authentication} from "@/api/modules/auth/Authentication";
import {AppConfig} from "@/config/AppConfig";
import {HttpUtils} from "@api/common/HttpUtils";
import {Tracer} from "@api/common/Tracer";
import {DateTime} from "luxon";
import MaintenanceBanner from "@/components/MaintenanceBanner/MaintenanceBanner.vue";


export default {
    name: 'App',
    data() {
        return {
            collapseNav: false,
            openSidebar: false,
            innerWidth: 0,
            isExpectedVersion: true,
            expectedVersion: '',
            expectedVersionHash: '',
            version: window.$build.version,
            acceptedMaintenanceDialog: false
        }
    },
    computed: {
        navPos() {
            if(this.innerWidth <= 768 && (this.$store.getters.navPos === 'top' || this.$store.getters.navPos === 'bottom')) {
                return 'left'
            }
            return this.$store.getters.navPos
        },
        toolbar() {
            return this.$store.getters.toolbar
        },
        footer() {
            return this.$store.getters.footer
        },
        boxed() {
            return this.$store.getters.boxed
        },
        roundedCorners() {
            return this.$store.getters.roundedCorners
        },
        viewAnimation() {
            return this.$store.getters.viewAnimation || 'none'
        },
        isLogged() {
            return this.$store.getters.isLogged
        },
        splashScreen() {
            return this.$store.getters.splashScreen
        },
        showCreditAlert(){
            return StoreProxy.showCreditAlert(this.$store);
        }
    },
    components: {
        MaintenanceBanner,
        HorizontalNav,
        VerticalNav,
        Toolbar,
        Footer
    },
    created() {
        if(browser.name) {
            document.getElementsByTagName("html")[0].classList.add(browser.name);
        }

        this._timerHandler = 0;
    },
    mounted() {

        this.checkMaintenanceStatus();
        this.checkVersion();
        this.resizeOpenNav();
        this.revalidateSession();
        this.startCheckSession();

        window.addEventListener('resize', this.resizeOpenNav);

    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeOpenNav);
    },
    methods: {
        resizeOpenNav() {
            this.innerWidth = window.innerWidth;
            if (window.innerWidth <= 768) {
                this.collapseNav = false
            }
        },
        closeSidebar() {
            this.openSidebar = false
        },
        onOpenSidebar() {
            Tracer.current.debug(`209781-(app-mount): open side bar value: ${this.openSidebar}`);
            this.openSidebar = !this.openSidebar;
        },
        startCheckSession() {
            Tracer.current.debug("202627-(app-mount): Start monitoring session");
            this._timerHandler = window.setInterval(this.checkSession, 30000);
        },
        revalidateSession(){

            const vm = this;
            try {
                Tracer.current.debug("904374-(app-mount): check if session already exists");
                Authentication.revalidate(this.$store).then(() => {
                    Tracer.current.info("854406-(app-mount): Session revalidated");

                    vm.$router.push('/');

                }).catch(e => {

                    Tracer.current.error("614801-(app-mount): Error revalidating user");
                    Tracer.current.error(e);

                    StoreProxy.setLogout(this.$store);
                });

            }
            catch(err){
                Tracer.current.error("755224-(app-mount): RUNTIME ERROR");
                Tracer.current.error(err);
            }
        },
        checkSession() {

            try {

                const auth = StoreProxy.getAuth(this.$store);
                const userId = StoreProxy.getUserId(this.$store);

                if (auth == null) {
                    return;
                }

                if (window.appInstanceId == null){
                    return;
                }

                if (!auth.isAuthenticated)
                {
                    return;
                }

                if (userId == null || typeof userId === 'undefined' || userId === '') {
                    return;
                }

                const vm = this;

                Authentication.checkSession(this.$store).then( (result) => {
                    if (result.isExpired()){

                        vm.$alert('Your current session has timed out. Please proceed to log in again.', 'Please sign in again', {
                            confirmButtonText: 'OK',
                            callback: action => {
                                location.reload();
                            }
                        });

                    }
                    else if (result.isMaintenance()){

                        if (vm.acceptedMaintenanceDialog === false) {
                            vm.$alert("The application's functionality is limited due to regular maintenance. Your orders may not be processed during this time.", 'Maintenance', {
                                confirmButtonText: 'OK',
                                callback: action => {
                                    vm.acceptedMaintenanceDialog = true;
                                }
                            });
                        }
                    }
                    else if (result.isDuplicated()){

                        vm.$alert('Another user has logged in. Use incognito mode if you need to log in multiple users simultaneously on the same computer.', 'Another user has logged in.', {
                            confirmButtonText: 'OK',
                            callback: action => {
                                location.reload();
                            }
                        });

                    }
                    else {

                        const inMaintenance = vm.$store.getters.maintenanceStatus === 'On' || vm.$store.getters.maintenanceStatus === 'Warning';
                        if (inMaintenance){
                            // turn off maintenance.
                            vm.$store.commit("setMaintenanceOff");
                        }
                    }

                }).catch( (e) => {

                    Tracer.current.error("440693-(app-mount): Error when checking for the session");
                    Tracer.current.error(e);


                });

            }
            catch(err){
                Tracer.current.error(err);
            }
        },
        checkVersion() {

            const vm = this;

            const param = window.config.correlationId;
            HttpUtils.get(`build.json?${param}`, null).then(data => {
                if (data != null) {

                    vm.expectedVersion = data.version;
                    vm.expectedVersionHash = data.hash;
                    vm.compareVersion();
                }
            }).catch(err => {
                Tracer.current.error(err);
            });
        },
        compareVersion(){
            if (!AppConfig.current.isLocal) {
                this.isExpectedVersion = true;

                if (this.$build.commitHash === this.expectedVersionHash) {
                    this.isExpectedVersion = true;
                } else {
                    this.isExpectedVersion = false;
                    Tracer.current.setTag("deprecated-version", true);
                    Tracer.current.warning(`Client version mismatch. Expected Version ${this.expectedVersion}, User's version ${this.$build.version}`);
                }
            }
            else{
                this.isExpectedVersion = true;
            }
        },
        checkMaintenanceStatus(){

            if (typeof window.maintenance == "undefined")
            {
                return;
            }

            if (window.maintenance.status == null){
                Tracer.current.debug("31ZI2L-(app): maintenance status unknown");
                return;
            }

            const status = window.maintenance.status;
            const start = DateTime.fromISO(window.maintenance.start).toLocal();
            const end = DateTime.fromISO(window.maintenance.end).toLocal();
            const warnTime = window.maintenance.warnTime;


            this.$store.commit("setMaintenance", {
                status,
                start,
                end,
                warnTime
            });

            Tracer.current.debug(`FTFI753-(app): maintenance status ${status}. Start: ${start}, End: ${end}`);
        }
    }

}