import {UserManagementService} from "./services/UserManagementService";
import {Credit} from "./models/Credit";
import {ChangePasswordResult} from "./models/ChangePasswordResult";
import ImpersonateAccount from "@/components/ImpersonateAccount/ImpersonateAccount";
import {ImpersonatedAccount} from "@/api/modules/userManagement/models/ImpersonatedAccount";
import {Tracer} from "@api/common/Tracer";

/**
 *
 */
export class UserManagement {
    constructor(auth){
        this._auth = auth;
        this._service = new UserManagementService(this._auth);
    }

    /**
     * Gets the current credit limit for the current user.
     * @param userId
     * @returns {Promise<Credit>}
     */
    getUserCredit(userId){

        if (!userId){
            throw new Error('[e143148] userId missing.');
        }

       return new Promise((resolve, reject) => {

           this._service.getUserCredit(userId).then(data => {
               if(data == null){
                   resolve(Credit.empty());
               }
               else {
                   resolve(new Credit(data.availableCredit));
               }
               }).catch(error => {
                       console.error('[e237121] Error while retrieving user\' credit limit');
                       console.error(error);
                       reject(error);
               });
       });
    }

    getAvailableCredit(){
        return new Promise((resolve, reject) => {
            this._service.getAvailableCredit().then(value => {
                resolve(value);
            }).catch(error => {
                Tracer.current.error("#3DU1EZ:!AVAILABLE_CREDIT!: Error retrieving the available credit");
                Tracer.current.error(error);
                reject(error);
            });
        });
    }

    /**
     * Change logged user's password
     * @param currentPassword
     * @param newPassword
     * @returns {Promise<int>}
     *
     * Return a constant defined in the class ChangePasswordResult.
     *
     * If success it will return ChangePasswordResult.SUCCESS,
     * if password does not match it will return ChangePasswordResult.INVALID_PASSWORD
     *
     */
    changePassword(currentPassword, newPassword){

        return new Promise( (resolve, reject) => {


            this._service.changePassword(currentPassword, newPassword).then(data => {

                if (data === true){

                    resolve(ChangePasswordResult.SUCCESS);
                }
                else
                {
                    resolve(ChangePasswordResult.INVALID_PASSWORD);
                }

            }).catch(err => {

                window.tracer.warning('[w925053] password not updated!')
                if (err != null) {
                    window.tracer.error(e);
                    reject(err)
                } else {
                    reject();
                }

            })
        });

    }

    /**
     * search for an account.
     * This method is meant to be used with an autocompletion feature
     * @param searchString
     * @returns {Promise<string>}
     */
    searchAccounts(searchString){
        return new Promise((resolve, reject) => {

            this._service.searchAccounts(searchString).then(accounts => {
                resolve(accounts);

            }).catch(e => {

                window.tracer.error("[e824295] Error searching for accounts.")
                if (e != null) {
                    window.tracer.error(e);
                }

                reject(e);
            });
        });
    }

    /**
     * Impersonate an account
     * @param account
     * @returns {Promise<ImpersonatedAccount>}
     */
    impersonateAccount(account){
        return new Promise((resolve, reject) => {

            let vm = this;
            this._service.impersonateAccount(account).then(() => {

                window.tracer.debug(`[d516500] Succesfully impersonated ${account}. Now... pulling account info...`);
                vm._service.getCurrentAccount().then(accountInfo =>{

                        let impAccount = new ImpersonatedAccount(account, accountInfo.name);
                        resolve(impAccount);

                    }).catch(e => {

                        window.tracer.error("[e595328] Error access impersonated account information")
                        if (e != null) {
                            window.tracer.error(e);
                        }

                        reject(e);
                    });


            }).catch(e => {

                if (e != null) {
                    window.tracer.error("[e952891] Error impersonating account")
                    window.tracer.error(e);
                }

                reject(e);
            });
        });
    }


}