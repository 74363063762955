export class PostalCodeItem {

    private readonly _postalCode : string;
    private readonly _country : string;
    private readonly _city : string;
    private readonly _regionName : string;
    private readonly _regionAbrev : string;

    constructor(postalCode: string, country : string, city : string, regionName : string, regionAbrev : string) {
        this._postalCode = postalCode;
        this._country = country;
        this._city = city;
        this._regionName = regionName;
        this._regionAbrev = regionAbrev;
    }

    get postalCode(): string {
        return this._postalCode;
    }

    get regionAbrev(): string {
        return this._regionAbrev;
    }

    get regionName(): string {
        return this._regionName;
    }

    get city(): string {
        return this._city;
    }

    get country(): string {
        return this._country;
    }

}