import {OrderHistoryService} from "./services/OrderHistoryService";

export class OrderDetails {

    constructor(auth) {
        this._auth = auth;

        if (!this._auth) {
            throw new Error('[e698099] Auth is missing for instantiating OrderDetail')
        }

        this._service = new OrderHistoryService(this._auth);
    }


    getInvoiceDetails(invoiceNumber){

        return new Promise((resolve, reject) => {
            this._service.getInvoiceDetails(invoiceNumber).then(orderDetails =>{

                resolve(orderDetails);

            }).catch(error => {

               window.tracer.error('[err-480900] Failure when retrieving order details');

               if (error != null){
                   window.tracer.error(error);
               }

               reject(error);
            });
        });

    }

    getCreditsDetails(invoiceNumber){

        return new Promise((resolve, reject) => {

            this._service.getCreditsDetails(invoiceNumber).then(orderDetails => {
                resolve(orderDetails);
            }).catch(error => {
                window.tracer.error('[e240694] Failure when retrieving order details');
                if (error != null){
                    window.tracer.error(error);
                }

                reject(error);
            });
        });

    }

    getOpenOrderDetails(orderNumber){

        return new Promise((resolve, reject) => {

            this._service.getOpenOrderDetails(orderNumber).then(orderDetails => {
                resolve(orderDetails);

            }).catch(error => {
                window.tracer.error('[e608942] Failure when retrieving order details');

                if (error != null){
                    window.tracer.error(error);
                }

                reject(error);
            });
        });
    }

    getCancelledOrderDetails(orderNumber){

        return new Promise((resolve, reject) => {

          this._service.getOpenOrderDetails(orderNumber).then(orderDetails => {

                resolve(orderDetails);

            }).catch(error => {
              window.tracer.error('[e608942] Failure when retrieving order details');

              if (error != null){
                  window.tracer.error(error);
              }

              reject(error);
            });
        });
    }
}