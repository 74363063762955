import {required, minLength, maxLength, requiredIf, email} from "vuelidate/lib/validators";
import {mask} from 'vue-the-mask'
import {Address} from "./models/Address";
import {UserManagement} from "@/api/modules/userManagement/UserManagement";
import {StoreProxy} from "@/api/common/StoreProxy";
import ViewHeader from "../../../components/ViewHeader/ViewHeader.vue";
import {UserPermissions} from "@api/core/UserPermissions";
import {Tracer} from "@api/common/Tracer";
import {VinValidator} from "@api/common/VinValidator";
import {CountryRegionLookup} from "@/lib/CountryRegionLookup";
import {CountryCode} from "@/lib/CountryRegionLookup/CountryCode";
import {PostalCodes} from "@/modules/PostalCodes/PostalCodes";
import {StringUtils} from "@/lib/StringUtils";


const vinNumberValidator = (value) => {
    if (value === null) return true;
    if (value === "")return true;
    return VinValidator.validate(value);
}

const countryRegionLookup = new CountryRegionLookup();
const postalCodes = new PostalCodes();

const getCountryValueFromCountryCode = (value) => {

    switch (value){
        case CountryCode.US:
            return "USA";
        case CountryCode.Canada:
            return "CAN";
        default:
            throw new Error("CRYTC7-(place_order): Invalid country code");
    }
}

export default {
    name: "PlaceOrder",
    directives: {
        mask: mask
    },
    components: {
        ViewHeader: ViewHeader,
    },
    beforeRouteEnter (to, from, next) {

        next(vm => {

            let type = to.params.type;

            if (from.name === "place-order-confirm"){
                type = from.params.type;
                vm._loadShipping(from.params.order.shipping, from.params.order.details);
            }

            if (type == null){
                type = 'standard';
            }

            vm.type = type;
            Tracer.current.debug(`7VHAYP-(place_order): place order for type: ${vm.type}`);

            vm._setGrandTotal();
            vm._loadCreditLimit();

        });
        next();
    },
    beforeRouteUpdate (to, from, next){

        const vm = this;

        let type = to.params.type;

        if (from.name === "place-order-confirm"){
            type = from.params.type;
            vm._loadShipping(from.params.order.shipping, from.params.order.details);
        }

        if (type == null){
            type = 'standard';
        }

        vm.type = type;
        Tracer.current.debug(`ULTTN-(place_order)[order_type=${vm.type}]`);

        vm._setGrandTotal();
        vm._loadCreditLimit();


        next();
    },
    beforeRouteLeave (to, from, next){
        next();
    },
    created() {

        const vm = this;
        this.isOverCreditLimitRestricted = UserPermissions.isAccountOverCreditRestricted(this.$store);
        this._profile = StoreProxy.getProfile(this.$store);
        this.dealerCode = this._profile.account.dealerCode;

        vm._load();


    },
    mounted() {
    },
    data(){
        return {

            isLoading: false,
            creditLimitLoaded: false,
            type: 'standard',

            /**
             *  @type {Address}
             */
            billing: new Address(),

            /**
             *  @type {Address}
             */
            shipping: new Address(),

            /**
             *  @type {Address}
             */
            originalShipping: new Address(),
            purchaseOrder: "",
            vinNumber: "",
            comments: "",
            attentionTo: "",
            phoneNumber: "",
            dealerCode: "",
            email: "",
            isDifferentShippingAddress: false,
            shippingAddressChangeEnabled: false,
            grandTotal: 0,
            shippingView: 'normal',
            formError: false,
            lookupZipCode: "",

            /**
             *  @type {PostalCodeItem[]}
             */
            lookupAddresses: [],
            availableCredit: 0,
            isOverCreditLimitRestricted: false,
            creditAlertMessage: "",
            showCreditAlertMessage: false
        }
    },
    validations : {
        shipping: {
            customer: {required},
            address: {required},
            address2: {},
            city: {required},
            state: {required},
            zipCode: {
                required
            }
        },
        purchaseOrder: {required},
        vinNumber: {
            required: requiredIf(function (model) {
                return false;
            }),
            vinNumber: vinNumberValidator

        },
        email: {
            required: requiredIf(function (model) {
                return this.isEmergency;
            }),
            email
        },
        dealerCode: {
            required: requiredIf(function (model) {
                return this.isEmergency;
            })
        },
        comments: {},
        attentionTo: {
            required: requiredIf(function (model) {
                return this.isDifferentShippingAddress;
            })
        },
        phoneNumber: {
            required: requiredIf(function (model) {
                return this.isDifferentShippingAddress;
            })
        }
    },
    computed: {

        shippingStates() {
            return countryRegionLookup.getListByCountry(this.shipping.getCountryCode());
        },
        billingState() {

            if (this.billing.state === "") return;

            if (this.billing.state.length > 2) {
                return this.billing.state;
            } else {
                return countryRegionLookup.getRegionName(this.billing.state);
            }
        },

        disableConfirmButton() {

            if (this.grandTotal <= 0) {
                return true;
            }

            if (this.isOverCreditLimitRestricted) {
                return (this.grandTotal > this.availableCredit);
            }

            return false;
        },
        isEmergency() {
            return this.type === 'emergency';
        }
    },
    watch: {
        creditLimitLoaded(_new, _old){
          if (_new === true){
              this._checkCreditLimit();
          }
      }
    },
    methods: {
        _load(){

            const profile = this.$store.getters.getProfile;

            if (profile == null){
                Tracer.current.warn("33CVJL1-(place_order): Unable to retrieve the profile from the state store, " +
                                             "most likely this order will fail because the profile address will be empty!")
            }

            const billingAddress = profile.billingAddress;
            const shippingAddress = profile.shippingAddress;

            const billingRegion = countryRegionLookup.getRegion(billingAddress.state);

            if (billingRegion == null){
                Tracer.current.warn(`1MEV404-(place_order): The billing region for the state/province '${billingAddress.state}' couldn't be determined.`)
            }

            let shippingAddressState = shippingAddress.state;
            if (StringUtils.isEmptySpacesOrNull(shippingAddressState)){
                shippingAddressState = billingAddress.state;
            }

            const shippingRegion = countryRegionLookup.getRegion(shippingAddressState);
            const billingCountry = getCountryValueFromCountryCode(billingRegion.countryCode);

            if (shippingRegion == null){
                Tracer.current.warn(`O7SQ817-(place_order): The shipping region for the state/province '${shippingAddressState}' couldn't be determined.`)
            }

            const shippingCountry = getCountryValueFromCountryCode(shippingRegion.countryCode);

            this.billing.setAddress(
                                    billingAddress.customerName,
                                    billingAddress.address1,
                                    billingAddress.address2,
                                    billingAddress.city,
                                    billingAddress.state,
                                    billingAddress.zipCode,
                                    billingCountry);

            this.originalShipping.setAddress(
                                    shippingAddress.customerName,
                                    shippingAddress.address1,
                                    shippingAddress.address2,
                                    shippingAddress.city,
                                    shippingAddress.state,
                                    shippingAddress.zipCode,
                                    shippingCountry);

            this.shipping.setAddress(
                                    shippingAddress.customerName,
                                    shippingAddress.address1,
                                    shippingAddress.address2,
                                    shippingAddress.city,
                                    shippingAddress.state,
                                    shippingAddress.zipCode,
                                    shippingCountry);

            Tracer.current.debug('ERHPVH-(place_order)[load_info]: Load billing and shipping info from profile');
        },

        _loadShipping(shipping, details) {
            if (details) {
                this.purchaseOrder = details.purchaseOrder;
                this.vinNumber = details.vinNumber;
                this.comments = details.comments;
                this.attentionTo = details.attentionTo;
                this.phoneNumber = details.phoneNumber;
                this.isDifferentShippingAddress = details.isDifferentShippingAddress;

                if (this.isDifferentShippingAddress){
                    this.shipping = shipping;
                }
            }
        },
        onConfirmOrder : function () {


            this.$v.$touch();

            if (this.$v.$invalid) {
                this.formError = true;
                return;
            } else {
                this.formError = false;
            }

            const order = {
                billing: this.billing,
                shipping: this.shipping,
                details: {
                    purchaseOrder: this.purchaseOrder,
                    vinNumber: this.vinNumber,
                    comments: this.comments,
                    attentionTo: this.attentionTo,
                    phoneNumber: this.phoneNumber,
                    dealerCode: this.dealerCode,
                    email: this.email,
                    isDifferentShippingAddress: this.isDifferentShippingAddress
                }
            };

            this.$router.push({
                name: 'place-order-confirm',
                params: {order: order, type: this.type}
            });
        },
        changeShippingAddress(){

            if (this.isDifferentShippingAddress){
                this.shippingView = 'askZip';
                this.shippingAddressChangeEnabled = true;
            }
            else{
                this.shippingView = 'normal';
                this.shippingAddressChangeEnabled = false;
                this.shipping.setAddress(this.originalShipping.customer,
                                        this.originalShipping.address,
                                        this.originalShipping.address2,
                                        this.originalShipping.city,
                                        this.originalShipping.state,
                                        this.originalShipping.zipCode);
            }
        },
        addressLookup(){
          if (this.lookupZipCode.length >= 2){

              const auth = StoreProxy.getAuth(this.$store);
              postalCodes.lookup(this.lookupZipCode, auth).then(items => {
                  this.lookupAddresses = items;
              }).catch(err => {
                  this.lookupAddresses = [];
                  Tracer.current.error(`AKIQ8U9-(postal_codes)[code=${this.lookupZipCode}]: Error searching for zipcode.`)
                  this.$notify({
                      title: "There was an application error while attempting to look up a postal code.",
                      type: "error"
                  });
              });
          }
          else {
              this.lookupAddresses = [];
          }

        },
        addressSelected(item){
            this.shipping.setAddress("", "", "", item.city, item.regionAbrev, item.postalCode, item.country);
            this.shippingView = 'normal'

        },
        onShippingZipCodeFocus(){
            if (this.isDifferentShippingAddress){
                this.shippingView = 'askZip';
                this.lookupZipCode = this.shipping.zipCode;
                this.addressLookup();
            }
        },
        onZipContinue(){
            this.shipping.setAddress("", "", "", "", "", this.lookupZipCode);
            this.shippingView = "normal";
        },
        onZipCancel() {
            this.shippingView = "normal";
        },
        vinNumberUpperCase(){
          this.vinNumber = this.vinNumber.toUpperCase();
        },
        _setGrandTotal(){

            if (this.type === 'bulk') {
                this.grandTotal = StoreProxy.getBulkOrderGrandTotal(this.$store);
            }
            else if (this.type === 'emergency') {
                this.grandTotal = StoreProxy.getEmergencyOrderGrandTotal(this.$store);
            }
            else{
                this.grandTotal = this.$store.getters.shoppingCartGrandTotal;
            }

            if (this.grandTotal <= 0){
               Tracer.current.warn(`9ODE7V-(place_order)[no_grand_total]: Grand Total $0.00 for the place order view! - cart type ${this.type}`);
            }

        },
        _loadCreditLimit() {

            const auth = StoreProxy.getAuth(this.$store);
            const userManagement = new UserManagement(auth);

            const vm = this;
            vm.isLoading = true;
            userManagement.getAvailableCredit().then(value => {
                vm.availableCredit = value;
                vm.isLoading = false;
                vm.creditLimitLoaded = true;
            }).catch(e => {
                vm.isLoading = false;
                vm.creditLimitLoaded = true;
                Tracer.current.error(e);
            });
        },
        _showCreditLimitExceedAlertMessage() {


            this.$alert('Your order exceeded your credit limit, please call 888.AER.0001 to complete your order.', 'Credit Limit Alert', {
                confirmButtonText: 'OK',
                type: "warning"
            }).then(() => {
                this.$router.go(-1);
            }).catch(() => {
                Tracer.current.debug("8QS2TL-(place_order)[alert_dialog][cancel_event]: User cancelled the alert window");
            });

        },
        _checkCreditLimit(){

            const vm = this;

            if (this.isOverCreditLimitRestricted) {
                Tracer.current.debug(`Y787I-(place_order)[is_credit_limit_restricted=${this.isOverCreditLimitRestricted}]: Credit Limit Restricted`);

                if (vm.grandTotal > vm.availableCredit){
                    vm.creditAlertMessage = "Your order exceeded your credit limit, please call 888.AER.0001 to complete your order.";
                    vm.showCreditAlertMessage = true;
                    this._showCreditLimitExceedAlertMessage();
                }
            }
            else{
                if (vm.grandTotal > vm.availableCredit){
                    vm.showCreditAlertMessage = true;
                    this.creditAlertMessage = "Your order exceeded your credit limit. Please call the office";
                }

            }

            const unwatch = this.$watch('creditLimitLoaded', function(){
               Tracer.current.debug("E56DM2-(place_order)[unwatch=creditlimitloaded]");
            });
            unwatch();

        }
    }
}