/**
 * Represents the available roles
 *
 * The roles must exists on the API.
 */
import {Tracer} from "@api/common/Tracer";
import {StoreProxy} from "@api/common/StoreProxy";
import {StringUtils} from "@api/common/StringUtils";

export class Roles {

    constructor(){

    }

    /**
     * Gets the role name for the ADMIN role
     * @returns {String}
     */
    static get ADMIN() {
        return process.env.VUE_APP_ROLE_ADMIN;
    }

    /**
     * Gets the role name for the sales manager role
     * @returns {String}
     */
    static get SALES_MANAGER() {
        return process.env.VUE_APP_ROLE_SALES_MANAGER
    }

    /**
     * Gets the role name for the sales roles
     * @returns {String}
     */
    static get SALES(){
        return process.env.VUE_APP_ROLE_SALES;
    }

    /**
     * Gets the role name for the dealer admin
     * @returns {String}
     */
    static get DEALER_ADMIN(){
        return process.env.VUE_APP_ROLE_DEALER_ADMIN;
    }

    /**
     * Gets the role name for the back order restricted role
     * @returns {String}
     */
    static get BACK_ORDER_RESTRICTED(){
        let tmp_role = process.env.VUE_APP_ROLE_BACK_ORDER_RESTRICTED;
        if (tmp_role == null){
            Tracer.current.warning("[warn-C7D144] [BAD_ROLE_CONFIGURATION]:[ROLE=VUE_APP_ROLE_BACK_ORDER_RESTRICTED]: Role *BACK_ORDER_RESTRICTED* is not correctly configured!");
        }

        return tmp_role;
    }

    /**
     * Gets the role name for restricting submitting an order when the account does not have enough credit.
     * @returns {*}
     * @constructor
     */
    static get OVER_CREDIT_RESTRICTED(){
        let tmp_role = process.env.VUE_APP_ROLE_OVER_CREDIT_RESTRICTED;
        if (tmp_role == null){
            Tracer.current.warning("#4YRXNR:!BAD_ROLE_CONFIGURATION!:[ROLE=VUE_APP_ROLE_OVER_CREDIT_RESTRICTED]: Role *VUE_APP_ROLE_OVER_CREDIT_RESTRICTED* is not correctly configured!");
        }

        return tmp_role;
    }

    /**
     * Gets the role name for order restricted
     * returns {String}
     * @constructor
     */
    static get ORDERS_RESTRICTED(){
        let tmp_role =  process.env.VUE_APP_ROLE_ORDERS_RESTRICTED;
        if (tmp_role == null){
            Tracer.current.warning("#BYSJ11:!BAD_ROLE_CONFIGURATION!:[ROLE=VUE_APP_ROLE_ORDERS_RESTRICTED]: Role *ORDERS_RESTRICTED* is not correctly configured!");
        }

        return tmp_role;
    }

    /**
     * Gets the role name for emergency order enabled
     * returns {String}
     * @constructor
     */
    static get EMERGENCY_ORDER_ENABLED(){
        let tmp_role =  process.env.VUE_APP_ROLE_EMERGENCY_ORDER_ENABLED;
        if (tmp_role == null){
            Tracer.current.warning("#ET9DQX:!BAD_ROLE_CONFIGURATION!:[ROLE:VUE_APP_ROLE_EMERGENCY_ORDER_ENABLED]: Role *EMERGENCY_ORDERS_ENABLED* is not correctly configured!");
        }

        return tmp_role;
    }

    /**
     * Gets the role option for the role passed to this method
     * @param $store
     * @param role
     * @param property
     * @returns {string|null}
     */
    static getRoleOption($store, role, property){

        if ($store == null){
            throw new Error("#W63FV:[NULL_ARGUMENT:$store]: Argument is null");
        }

        if (StringUtils.isEmptyOrNull(role)){
            return '';
        }

        if (StringUtils.isEmptyOrNull(property)){
            return '';
        }

        let profile = StoreProxy.getProfile($store);
        if (profile == null){
            throw new Error('#FF6U2G:!!PROFILE_NOT_FOUND!: Unable to get role options of a non-existing profile');
        }

        if (profile.rolesOptions == null){
            return null;
        }

        let value = '';
        for(let i = 0; i < profile.rolesOptions.length; i++){
            let currentItem = profile.rolesOptions[i];
            if (currentItem.name === role){
                if (currentItem.properties != null){
                    if (currentItem.properties[property] != null){
                        value = currentItem.properties[property];
                        break;
                    }
                }
            }
        }

        return value;
    }

}
