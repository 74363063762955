import {PriorReplenishCount} from "@api/modules/catalogSearch/models/PriorReplenishCount";
import {Tracer} from "@api/common/Tracer";

/**
 * Holds the Part Fulfilment information, also known as build time, also known order position.
 */
export class PartFulfillmentInfo {


    constructor(position, replinishInfo) {
        this._position = position;
        this._replenishInfo = replinishInfo;

        if ( !(this._replenishInfo instanceof PriorReplenishCount)){
            throw new Error("ECY9T8-(fulfillment_info)[EXPECTED:<PriorReplenishCount>]: Invalid object type");
        }
    }

    /**
     * Gets the project position for this part if it is ordered.
     * @returns {number}
     */
    get position() {
        return this._position;
    }

    /**
     * Gets replenish information for 7, 14 and 21 days
     * @returns {PriorReplenishCount}
     */
    get replenishInfo() {
        return this._replenishInfo;
    }

    set position(value) {
        //this is read only.
        Tracer.current.warn("1X8M402-(fulfillment_info)[read_only_property]: IGNORED VALUE.");
    }

    set replenishInfo(value){
        //this is read only.
        Tracer.current.warn("89XN753-(fulfillment_info)[read_only_property]: IGNORED VALUE.");
    }





}