import {NotificationTypes} from "@api/modules/dealerAdmin/models/NotificationTypes";
import {DealerNotifications} from "@api/modules/dealerAdmin/DealerNotifications";
import {StoreProxy} from "@api/common/StoreProxy";
import {Tracer} from "@api/common/Tracer";
import {AppConfig} from "@/config/AppConfig";
import {StringUtils} from "@api/common/StringUtils";
import { required } from 'vuelidate/lib/validators'

/**
 * Validator
 *
 * Validates if the date is today's date.
 * @param value The date to be validated
 * @returns {boolean}
 */
const emailValidator = (value) => {

    if (StringUtils.isEmptyOrNull(value)){
        return false;
    }

    let verRegex = /[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    let result = verRegex.test(value.toLowerCase());

    if (!result){
        Tracer.current.warning(`[w-552068] The email ${value} failed regex validation.`);
    }

    return result;

};

export default {
    name: "EMails",
    data() {
        return {
            newEmail: {
              enabled: false,
              value: '',
              validation: {
                  error: false,
                  required: false,
                  invalidEmail: false
              }

            },
            isLoading: false,
            emails: [],

        }
    },
    props: {
      type: {
          type: String,
          required: true,
          default: '',
          validator: function (value) {
              return [NotificationTypes.INVOICE, NotificationTypes.SHIPPING, NotificationTypes.ORDER].indexOf(value) !== -1;
          }
      }
    },
    computed: {
      typeDescription() {
          if (this.type === NotificationTypes.INVOICE){
              return 'Invoice Notification';
          }

          if (this.type === NotificationTypes.SHIPPING){
              return 'Shipping Notification';
          }

          if (this.type === NotificationTypes.ORDER){
              return 'Order Notification';
          }

          return '!INVALID DESCRIPTION';
      },
      hasEmails() {
          return this.emails.length > 0;
      }
    },
    mounted() {

        this.isLoading = true;
        this._auth = StoreProxy.getAuth(this.$store);
        this._account = StoreProxy.getCustomerNumber(this.$store);

        this._dealerNotifications = new DealerNotifications(this._account, this._auth);

        this._loadEmails();
    },
    methods: {

        /**
         * Add new email to the list
         */
        activateNewEmailForm(){
            this.newEmail.enabled = true;

            this._validationReset(this.newEmail);
        },

        deactivateNewEmailForm(){
            this.newEmail.enabled = false;
            this._validationReset(this.newEmail);
        },
        resetValidation(value, obj){
            if (obj == null){
                return;
            }

            if (StringUtils.isEmptyOrNull(value)){
                this._validationReset(obj);
            }
        },
        preventBackspace(email){
            if (!email.enabled) {
                event.preventDefault();
                return false;
            }
        },
        /**
         * Change the current email
         * @param email{EmailRow}
         */
        save(email){


            if (StringUtils.isEmptyOrNull(email.email)){
                email.validation.error = true;
                email.validation.required = true;
                Tracer.current.info("[i-883773] Email is required");
                return false;
            }

            if (!emailValidator(email.email)){
                email.validation.error = true;
                email.validation.invalidEmail = true;

                Tracer.current.info("[i-883773] Email is invalid");
                return false;
            }

            this.isLoading = true;
            let vm = this;

            this._dealerNotifications.changeEmail(this.type, email.email, email.sequence).then(() => {

                vm.$notify({
                    title: `${email.email} Updated.`,
                    type: 'Info'
                });

                this._loadEmails();

                this.isLoading = false;
            }).catch(err => {

                Tracer.current.error('[e-298686] Error updating a new email notification');
                Tracer.current.error(err);
                vm.$notify.error({
                    title: 'Internal Error',
                    message: ''
                });

                this.isLoading = false;
            });
        },
        /**
         * Deletes an email from the list
         *
         * @param email{EmailRow}
         */
        remove(email){

            this.$confirm(
                `Email ${email.email} will be removed from the notification list. do you want to continue?`,
                'Delete',
                {
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            type: 'warning'
            }).then(() => {
                this._internalRemove(email);
            }).catch(() => {
                Tracer.current.info("[i-664074] Email delete cancelled");
            })
        },
        /**
         * Creates a new email address
         */
        create() {

            this._validationReset(this.newEmail);

            if (StringUtils.isEmptyOrNull(this.newEmail.value)){
                this.newEmail.validation.error = true;
                this.newEmail.validation.required = true;
                Tracer.current.info("[i-883773] Email is required");
                return false;
            }

            if (!emailValidator(this.newEmail.value)){
                this.newEmail.validation.error = true;
                this.newEmail.validation.invalidEmail = true;

                Tracer.current.info("[i-883773] Email is invalid");
                return false;
            }


            let email = this.newEmail.value;
            this.isLoading = true;
            let vm = this;
            this._dealerNotifications.addEmail(this.type, email).then(() => {

                vm.$notify({
                    title: `${email} added.`,
                    type: 'Info'
                });

                this._loadEmails();
                this.newEmail.value = '';
                this._validationReset(this.newEmail);

                this.isLoading = false;
            }).catch(err => {

                Tracer.current.error('[e-427009] Error adding a new email notification');
                Tracer.current.error(err);
                vm.$notify.error({
                    title: 'Internal Error',
                    message: ''
                });

                this.isLoading = false;
            });
        },
        /**
         * Load email address from the server.
         * @private
         */
        _loadEmails() {

            let vm = this;
            this.emails = [];

            this._dealerNotifications.getEmails(this.type).then( emailList => {

                for(let i = 0; i < emailList.length; i++){

                    let item = emailList[i];
                    if (AppConfig.current.isDebugMode) {
                        Tracer.current.debug(`[d-540915] email: ${item.email} with sequence of ${item.sequence} loaded`);
                    }

                    vm.$set(vm.emails, i, new EmailRow (item.email, item.sequence));
                }

                Tracer.current.debug(`[d-685677] email notifications for '${this.type}' retrieved`);
                this.isLoading = false;


            }).catch(e => {
                Tracer.current.error('[e-427009] Error loading notifications');
                Tracer.current.error(e);
                vm.$notify.error({
                    title: 'Internal Error',
                    message: 'Internal failure getting the email notifications from the server'
                });

                this.isLoading = false;
            });
        },
        /**
         * Deletes an email from the list
         *
         * @private
         * @param email{EmailRow}
         */
        _internalRemove(email){

            this.isLoading = true;
            let vm = this;
            this._dealerNotifications.removeEmail(this.type, email.sequence).then(() => {

                vm.$notify({
                    title: `${email.email} Removed.`,
                    type: 'Info'
                });

                this._loadEmails();

                this.isLoading = false;
            }).catch(err => {

                Tracer.current.error('[e-408300] Error removing a new email notification');
                Tracer.current.error(err);
                vm.$notify.error({
                    title: 'Internal Error',
                    message: ''
                });

                this.isLoading = false;
            });
        },
        _validationReset(obj){

            if (obj.validation != null){
                if (obj.validation.error){
                        obj.validation.error = false;
                        obj.validation.required = false;
                        obj.validation.invalidEmail = false;
                }
            }
        }


    }
}

class EmailRow
{
    constructor(email, sequence){
        this.email = email;
        this._previousEmail = email;
        this.sequence = sequence;

        this._enabled = false;
        this._changed = false;

        this.validation = {
            error : false,
            required: false,
            invalidEmail: false
        }
    }

    get enabled(){
        return this._enabled;
    }

    set enabled(value){
        this._enabled = value
    }


    edit(){
        this._enabled = true;

        this.validation.error = false;
        this.validation.required = false;
        this.validation.invalidEmail = false;
        Tracer.current.debug(`[d-879980] ${this.email} editable now.`);
    }

    cancelEdit(){
        this._enabled = false;

        this.validation.error = false;
        this.validation.required = false;
        this.validation.invalidEmail = false;

        if (this.email !== this._previousEmail){
            this.email = this._previousEmail;
        }

    }

}



