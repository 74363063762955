import {HttpUtils} from "@/api/common/HttpUtils";
import axios from "axios";
import {UserProfile} from "@/api/modules/auth/models/UserProfile";
import {SessionCheckResult} from "@/api/modules/auth/models/SessionCheckResult";
import {Tracer} from "@api/common/Tracer";
import {DealerAccountProfile} from "@api/modules/auth/models/DealerAccountProfile";

/**
 * Perform calls to the AUTH api
 * @Private - Only intented to be used within the `auth` module
 */
export class _AuthHttpService {

    constructor() {

        this.baseUrl = process.env.VUE_APP_AUTH_URL;
    }

    /**
     * Gets the profile of the authenticated user
     * @param auth
     * @returns {Promise<UserProfile>}
     */
    getProfile(auth) {

        if (auth == null){
            throw new Error("#CFRI3J:!ERROR!: Auth is expected!");
        }

        return new Promise((resolve, reject) => {

            let url = this.baseUrl + '/profile';
            HttpUtils.get(url, auth).then(data => {

                let profile = new UserProfile();
                profile.name = `${data.firstName} ${data.lastName}`;
                profile.roles = data.roles;
                profile.rolesOptions = data.rolesOptions;
                profile.userId = data.userId;
                profile.restricted = data.restricted;
                profile.customerNumber = data.customerNumber;
                profile.companyName = data.company.name;
                profile.companyId = data.company.id;
                profile.companyLogo = data.company.logoUri;

                //@fix: define address class
                profile.billingAddress = data.billingAddress;
                profile.shippingAddress = data.shippingAddress;

                if (data.account != null){

                    let dealerAccount = new DealerAccountProfile();
                    dealerAccount.accountNumber = data.account.accountNumber;
                    dealerAccount.homeBranch = data.account.homeBranch;
                    dealerAccount.dealerCode = data.account.dealerCode;

                    dealerAccount.roles = data.account.roles;

                    if (data.account.options != null){
                        if (data.account.options.backOrderRestricted != null){
                            dealerAccount.backOrderRestricted = data.account.options.backOrderRestricted;
                        }
                        else{
                            dealerAccount.backOrderRestricted = false;
                            Tracer.current.info("#4XPTE:!ASSERT!:[OPTION_NOT_FOUND:BACK_ORDER]: Back order configuration not set for this account. Default to not allow back orders");
                        }
                    }

                    profile.setAccount(dealerAccount);
                }
                else {

                    Tracer.current.warn("#5WO0UC:!API_INCOMPABILITY!:[MISSING_ACCOUNT_SCHEMA]: The account object is missing");

                    let dealerAccount = new DealerAccountProfile();
                    dealerAccount.accountNumber = profile.customerNumber;
                    dealerAccount.homeBranch = "";
                    dealerAccount.roles = [];
                    dealerAccount.backOrderRestricted = false;
                    dealerAccount.dealerCode = '';
                    profile.setAccount(dealerAccount);

                }

                resolve(profile);


            }).catch((e) => {
                Tracer.current.error("#5NZJ34:!ERROR_LOADING_USER_PROFILE!");
                Tracer.current.error(e);
                reject(e);
            });


        });

    }

    /**
     * Revalidates a session if the session is active on the server
     * @returns {Promise<{
     *                 jwt: '',
     *                 authenticated: false,
     *                 message: '',
     *                 sessionId: '',
     *                 status: 0
     *             }>}
     */
    revalidate() {

        return new Promise((resolve, reject) => {

            let authResult = {
                jwt: '',
                authenticated: false,
                message: '',
                sessionId: '',
                status: 0
            };

            let sessionId = HttpUtils.getCookie(window.$instance.sessionCookieName);


            if (sessionId == null) {
                authResult.status = 401;
                reject(authResult);
            }

            window.tracer.debug(`644529-(revalidate-session): Session \'${sessionId}\' found. Lets check if still active in the nether`);

            this.headers = HttpUtils.getHttpHeaders(null, 'application/json');

            const url = this.baseUrl + '/browser/revalidate';
            axios.create({withCredentials: true}).get(url, {headers: this.headers}).then(response => {

                if (response.status === 200 || response.status === 201) {
                    authResult.jwt = response.data.token;
                    authResult.status = 200;
                    authResult.authenticated = true;
                    authResult.sessionId = response.data.session;
                    resolve(authResult);
                }
                else{

                    window.trace.error("[e-590240] Unable to identify the response status of the server to revalidate the session");
                    authResult.jwt = ''
                    authResult.status = 200;
                    authResult.authenticated = false;
                    authResult.sessionId = '';
                    reject(authResult);
                }

            }).catch(e => {

                if (e.response) {
                    if (e.response.status === 401) {
                        authResult.jwt = '';
                        authResult.status = 401;
                        authResult.authenticated = false;
                        authResult.sessionId = '';
                        reject(authResult);
                    }
                }
                else{

                    Tracer.current.criticalError(e, {
                        url: url,
                        message: "GFJ45T-(authentication): HTTP error while user trying to authenticate"
                    });

                    authResult.jwt = '';
                    authResult.status = 500;
                    authResult.authenticated = false;
                    authResult.sessionId = '';
                    reject(authResult);
                }

            })
        });
    };

    authJwt(auth) {

        const url = `${this.baseUrl}?auth=${auth}`;

        const authResult = {
            jwt: '',
            authenticated: false,
            message: '',
            sessionId: '',
            status: 0
        };

        return new Promise((res, rej) => {

            HttpUtils.get(url, null).then(data => {

                if (data != null) {
                    authResult.jwt = data.token;
                    authResult.status = 200;
                    authResult.authenticated = true;
                    authResult.sessionId = data.session;
                    HttpUtils.setCookie(window.$instance.sessionCookieName, authResult.sessionId);
                    res(authResult);
                }
                else{
                    rej();
                }

            }).catch( e => {
                Tracer.current.error(e);
                rej(e);
                });
        });
    }

    login(userId, password) {

        const authResult = {
            jwt: '',
            authenticated: false,
            message: '',
            sessionId: '',
            status: 0
        };


        return new Promise((res, rej) => {

            let headers = HttpUtils.getHttpHeaders(null, 'application/json');

            HttpUtils.postAuth(this.baseUrl,
                {
                userId: userId,
                password: password
            }, {headers: headers}).then(data => {

                if (data != null) {
                    authResult.jwt = data.token;
                    authResult.status = 200;
                    authResult.authenticated = true;
                    authResult.sessionId = data.session;
                    HttpUtils.setCookie(window.$instance.sessionCookieName, authResult.sessionId);
                    res(authResult);
                }
                else{
                    rej();
                }

            }).catch(e => {

                Tracer.current.error(e);
                rej(e);
            })
        });
    };

    maintenanceInformation() {
        const url = `${this.baseUrl}/browser/maintenance`;

        return new Promise((resolve, reject) => {

            HttpUtils.get(url, null).then(data => {

                    if (data != null) {
                        resolve(data.maintenance);
                    }
                    else{
                        resolve({status : 'Off'})
                    }
                }
            ).catch(e => {
                    Tracer.current.error(e);

                    // Do not error out if we cannot resolve the maintenance status.
                    resolve({status : 'Off'})
                }
            );

        });
    }


    /**
     * Check if the user session is still active.
     * @param userId The user id.
     * @param auth The authentication token.
     * @return {Promise<unknown>}
     */
    checkSession(userId, auth) {

        const url = `${this.baseUrl}/browser/users/${userId}/check`;

        return new Promise((resolve, reject) => {

            if (userId == null || userId.length === 0){

                Tracer.current.warn("150449-(check_session): User id is not valid. Unable to check session", {
                    url: url,
                });

                resolve(SessionCheckResult.noAuthenticated());
                return;
            }

            if (auth == null){
                Tracer.current.debug("745613-(check_session): Auth missing. Unable to check session");

                resolve(SessionCheckResult.noAuthenticated());
                return;
            }

            HttpUtils.head(url, auth).then(result => {

                if (result.warning === true) {
                    resolve(SessionCheckResult.authenticatedWithMaintenanceWarning(null));
                }
                else{
                    resolve(SessionCheckResult.authenticated(null));
                }

            }).catch( e => {

                if (e == null){
                    Tracer.current.warn("452223-(check_session): Unable to resolve the response.",  {
                        url: url,
                    });

                    resolve(SessionCheckResult.noAuthenticated());
                    return;
                }

                if (e.response == null) {

                    Tracer.current.error(e, {
                        url: url,
                        message: "8HHQ6N-(check_session): Unable to check the response status"
                    });

                    resolve(SessionCheckResult.noAuthenticated());
                    return;
                }

                if (e.response.status === 401){
                    resolve(SessionCheckResult.expired());
                    return;
                }

                if (e.response.status === 409){
                    resolve(SessionCheckResult.duplicatedSession());
                    return;
                }

                if (e.response.status === 403 || e.response.status === 404){
                    resolve(SessionCheckResult.noAuthenticated());
                    return;
                }

                if (e.response.status === 503){

                    Tracer.current.warning(e, {
                        url: url,
                        message: "DN5G288-(check_session): Server is unavailable due to maintenance."
                    });

                    resolve(SessionCheckResult.InMaintenance());
                    return;
                }

                if (e.response.status === 500){

                    Tracer.current.error(e, {
                        url: url,
                        message: "994111-(check_session): Server error checking user session"
                    });

                    reject(e);
                    return;
                }

                Tracer.current.error(e, {
                    url: url,
                    message: "D83RCG-(check_session): Unidentified error (fall-through http status)"
                });

                reject(e);

            });


        });

    };

    /**
     * Logs out the user
     * @param auth
     * @return {Promise<unknown>}
     */
    logout(auth) {

        return new Promise((resolve, reject) => {

            HttpUtils.get(this.baseUrl + '/logout', auth).then(data => {
                resolve();
            }).catch(error => {
                Tracer.current.error(error);
                reject(error);
            });
        });
    };

}