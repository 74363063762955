import {HttpUtils} from "@api/common/HttpUtils";
import {WarrantyOption} from "./models/WarrantyOption";


export class PartLookupService {

    constructor(auth) {
        this._auth = auth;
        this.baseUrl = `${window.config.api.partsUri}/v1/catalog`;
        this.headers = HttpUtils.getHttpHeaders(auth, 'text/json');
        this.error = null;
    }


    getPartNumberAutoComplete(query)  {

        let url = `${this.baseUrl}/autocomplete?field=partnumber&q=${query}`;

        return new Promise((resolve, reject) => {

            if (query == null || query === ''){
                resolve([]);
                return;
            }

            HttpUtils.get(url, this._auth).then(data =>{
                resolve(data);
            }).catch(err => {

                window.tracer.error('[e883354] part number autocomplete error');
                if (err != null){
                    window.tracer.error(err);
                }

                reject(err);
            });

        });

    }


    getWarranties(){
        let url = `${this.baseUrl}/warranties/no-fault`;

        return new Promise((resolve, reject) => {

            let list = [];
            HttpUtils.get(url, this._auth).then(data => {

                if(data == null){
                    resolve(list);
                }
                else {
                    data.forEach(function (obj) {
                        list.push(new WarrantyOption(obj.id, obj.description, obj.price));
                    });
                    resolve(list);
                }
            }

            ).catch(e =>{
                window.tracer.error('[e953173] Error while retrieving warranties list');

                if (e != null) {
                    window.tracer.error(e);
                }

                reject(e);
            })
        });
    }

}