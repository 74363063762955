
export class ChangePasswordResult {

  static constructor() {

  }

  /**
   * Represents SUCCESS
   * @type {1}
   */
  static SUCCESS = 1;

  /**
   * Represents invalid password
   * @type {2} -
   */
  static INVALID_PASSWORD = 2;

  /**
   * Represents error when changing password
   * @type {3}
   */
  static ERROR = 3

}