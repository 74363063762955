import {VehicleCondition} from "./VehicleCondition";
import {IPartResults} from "./IPartResults";
import {IVehicle} from "./IVehicle";

export class Vehicle implements IVehicle {

     driveType?: VehicleCondition[];
     engineLiters?: VehicleCondition;
     engineVin?: VehicleCondition;
     fuelType?: VehicleCondition;
     make?: VehicleCondition;
     model?: VehicleCondition;
     results?: IPartResults;
     year: number;

    constructor() {
        this.driveType = [];
        this.engineLiters = new VehicleCondition('', '');
        this.engineVin = new VehicleCondition('', '');
        this.fuelType = new VehicleCondition('', '');
        this.make = new VehicleCondition('', '');
        this.model = new VehicleCondition('', '');
        this.year = 0;
    }

    setResults(results: IPartResults) {
        this.results = results
    }

    setYear(year: number) {
        this.year = year
    }

    setModel(id: string, name: string) {
        this.model = new VehicleCondition(id, name)
    }

    setMake(id: string, name: string) {
        this.make = new VehicleCondition(id, name)
    }

    setDriveType(id: string, name: string) {
        this.driveType?.push(new VehicleCondition(id, name));
    }

    setEngineVin(id: string, name: string) {
        this.engineVin = new VehicleCondition(id, name)
    }

    setEngineLiters(id: string, name: string) {
        this.engineLiters = new VehicleCondition(id, name)
    }

    setFuelType(id: string, name: string) {
        this.fuelType = new VehicleCondition(id, name)
    }
}