export default {
    name: "WarrantyDetailsViewer",
    data() {
        return {
            fileUri: window.config.pdfjs.noFaultWarrantyUrl
        }
    },
    methods: {
        close: function () {
            this.$emit('close');
        }
    }
}