// noinspection DuplicatedCode

import {_DealerAdminHttpService} from "@api/modules/dealerAdmin/_httpService/_DealerAdminHttpService";
import {NotificationTypes} from "@api/modules/dealerAdmin/models/NotificationTypes";

import {Tracer} from "@api/common/Tracer";
import _ from "lodash";

/**
 * Represents and manage dealer notifications
 */
export class DealerNotifications {

    /**
     * Gets the dealer account number for which this instance was created.
     * @returns {string}
     */
    get account() {
        return this._account;
    }

    constructor(account, auth){
        this._auth = auth;
        this._account = account;
    }

    /**
     * Gets the email notifications for the specify type.
     *
     *
     * The allow types are:
     * `EmailNotificationTypes.INVOICE`, `EmailNotificationTypes.SHIPPING`, `EmailNotificationTypes.ORDER`
     *
     * @param type The notification type. It must be one of these: `EmailNotificationTypes.INVOICE`, `EmailNotificationTypes.SHIPPING`,
     * `EmailNotificationTypes.ORDER`
     * @returns {Promise<NotificationItem[]>}
     */
    getEmails(type){

        this._validateType(type);

        try{

            return new Promise((resolve, reject) => {

                let service = new _DealerAdminHttpService(this._auth);

                service.getEmailNotifications(this.account, type).then(emails => {

                    if (emails == null){
                        Tracer.current.warning(`[w-168725] Undefined email array.`);
                        resolve([]);
                        return;
                    }

                    if (emails.length === 0){
                        Tracer.current.debug(`[d-358703] No emails..`);
                        resolve([]);
                        return;
                    }
                    /* let's order by the sequence */
                    _.sortBy(emails, [ function (o) { return o.sequence}]);

                    resolve(emails);

                }).catch(e => {
                        Tracer.current.error("[e-939078] Server error getting the email notifications");

                        if (e != null) {
                            Tracer.current.error(e);
                        }

                        reject();
                    }
                );

            });

        }
        catch(e){
            Tracer.current.error("[e-263905] Error getting the list of email notifications");
            Tracer.current.error(e);
            throw e;
        }
    }

    /**
     * Adds a new notification email address for the type provided
     * @param {string} type The type of notifications.
     * @param {string} email The new email address/
     * @returns {Promise<>}
     */
    addEmail(type, email){

        this._validateType(type);

        try {

            return new Promise((resolve, reject) => {
                let service = new _DealerAdminHttpService(this._auth);
                service.addEmailNotification(this._account, type, email).then(() => {
                    resolve();
                }).catch(e => {
                    reject();
                });
            });
        }
        catch( err ){

            Tracer.current.error("[e-263905] Error adding a new email notification to the list on the server");
            Tracer.current.error(err);
            throw err;

        }


    }

    /**
     * Changes the notification email address for a specific sequence number for the type provided
     * @param {string} type The type of notifications.
     * @param {string} email The new email address/
     * @param {string} sequence
     * @returns {Promise<>}
     */
    changeEmail(type, email, sequence){

        this._validateType(type);
        try {

            return new Promise((resolve, reject) => {
                let service = new _DealerAdminHttpService(this._auth);
                service.updateEmailNotification(this._account, type, email, sequence).then(() => {
                    resolve();
                }).catch(e => {
                    reject();
                });
            });
        }
        catch( err ){

            Tracer.current.error("[e-226477] Error updating email notification on the server");
            Tracer.current.error(err);
            throw err;

        }
    }

    /**
     * Removes a notification email address from the notification list for the type provided
     * @param {string} type The type of notifications.
     * @param {string} sequence
     * @returns {Promise<>}
     */
    removeEmail(type, sequence){

        this._validateType(type);

        try {

            return new Promise((resolve, reject) => {
                let service = new _DealerAdminHttpService(this._auth);
                service.removeEmailNotification(this._account, type, sequence).then(() => {
                    resolve();
                }).catch(e => {
                    reject();
                });
            });
        }
        catch( err ){

            Tracer.current.error("[e-629426] Error deleting email notification from the list on the server");
            Tracer.current.error(err);
            throw err;

        }
    }


    _validateType(type) {
        if (type !== NotificationTypes.INVOICE &&
            type !== NotificationTypes.SHIPPING &&
            type !== NotificationTypes.ORDER) {

            Tracer.current.debug(`Invoice: ${NotificationTypes.INVOICE}`);
            Tracer.current.debug(`Shipping: ${NotificationTypes.SHIPPING}`);
            Tracer.current.debug(`Order: ${NotificationTypes.ORDER}`);
            throw new Error(`[e-855532] Type ${type} not supported!`);
        }
    }

}