import {OrderHistorySearch} from "./OrderHistorySearch";
import {InvoicesSearch} from "@api/modules/orderHistory/InvoicesSearch";

export class InvoicesResults extends OrderHistorySearch {

    constructor(auth){

        super('invoices');
        this.auth = auth;
        this._orderSearch = new InvoicesSearch(auth);

    }

    _searchByInvoiceNumber(value, page){
        return this._orderSearch.searchByInvoiceNumber(value, page);
    }

    _searchByOrderNumber(value, page){
        return this._orderSearch.searchByOrderNumber(value, page);
    }

    _searchBySerialNumber(value, page) {
        return this._orderSearch.searchBySerialNumber(value, page);
    }

    _searchByPartNumber(value, page){
        return this._orderSearch.searchByPartNumber(value, page);
    }

    _searchByPurchaseOrder(value, page){
        return this._orderSearch.searchByPurchaseOrder(value, page);
    }

    _searchByMonths(months, page){
        return this._orderSearch.searchByMonths(months, page);
    }

    _searchByDateRange(startDate, endDate, page){
        return this._orderSearch.searchByDateRange(startDate, endDate, page);
    }

}
