export default {
    name: "CartConfirmationDialog",
    data() {
        return {

        }
    },
    props: [ 'part' ],
    methods: {
        close: function () {
            this.$emit('close');
        },

    }
}