import moment from 'moment';

export default {
    name: "OrderHistorySearchToolbox",
    props: {
        searchFields: {
            type: Array,
            required: false,
            default: function () {
                return [
                    {id: 'invoiceNumber', name: 'Invoice Number'},
                    {id: 'orderNumber', name: 'Order Number'},
                    {id: 'purchaseOrder', name: 'Purchase Order'},
                    {id: 'serialNumber', name: 'Serial Number'},
                    {id: 'partNumber', name: 'Part Number'},
                    {id: 'dateRange', name: 'Invoice Date'}
                ]
            }
        },
        selected: {
            type: String,
            required: false,
            default: 'invoiceNumber'
        },
        state: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            selectedSearchType: '',
            searchValue: '',
            dateRange: '',
            pickerOptions: {
                shortcuts: [{
                    text: 'Last week',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last month',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 3 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                },
                    {
                        text: 'Year To Date',
                        onClick(picker) {

                            let startYear = moment().startOf('year');
                            let today = moment();
                            let start = startYear.toDate();
                            let end = today.toDate();
                            picker.$emit('pick', [start, end]);
                        }
                    }]
            }
        }
    },
    mounted() {

        if (this.state != null && this.state.searchType != null) {

            this.selectedSearchType = this.state.searchType;

            if (this.selectedSearchType === 'dateRange') {
                this.dateRange = this.state.value;
                this.searchValue = '';
            } else {
                this.searchValue = this.state.value;
            }
        }

    },
    created(){
        this.selectedSearchType = this.selected;
    },
    computed: {
      searchTypes(){
          return this.searchFields;

      }
    },
    methods: {
        onSearchClick(){

            if (this.selectedSearchType === 'dateRange'){
                this.$emit('search', {
                    searchType: this.selectedSearchType,
                    value: this.dateRange
                });
            }
            else{
                this.$emit('search', {
                    searchType: this.selectedSearchType,
                    value: this.searchValue
                });
            }
        },
        onSearchClear(){

            this.searchValue = '';
            this.selectedSearchType = this.selected;
            this.$emit('search', {
                searchType: this.selectedSearchType,
                value: ''
            });
        },
        clearSearch(){
            this.onSearchClear();
        }

    }
}
