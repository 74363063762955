import moment from "moment";

/**
 * Represents the base search.
 */
export class OrderSearchBase {

    constructor(){
    }

    /*
       must be implemented by the inheritors
     */
    search(searchParams){
        throw new Error('[err-624255] [NOT_IMPLEMENTED_METHOD] This method must be implemented by inheritors.');
    }

    /**
     * Search by invoice number
     * @param value
     * @param page
     * @returns {Promise<unknown>}
     */
    searchByInvoiceNumber(value, page){

        if (!page){
            page = 1
        }

        let searchParam = {invoice:value, page: page};
        return this._search(searchParam);
    }

    searchByOrderNumber(value, page){

        if (!page){
            page = 1
        }

        let searchParam = {orderNumber:value, page: page};
        return this._search(searchParam);
    }

    searchByPartNumber(value, page){

        if (!page){
            page = 1
        }

        let searchParam = {partNumber:value, page: page};
        return this._search(searchParam);
    }

    searchBySerialNumber(value, page){

        if (!page){
            page = 1
        }

        let searchParam = {serialNumber:value, page: page};
        return this._search(searchParam);
    }

    searchByPurchaseOrder(value, page){

        if (!page){
            page = 1
        }

        let searchParam = {purchaseOrder:value, page: page};
        return this._search(searchParam);
    }

    searchByDateRange(startDate, endDate, page){

        let startDt = moment(startDate);
        let endDt = moment(endDate);


        if (!startDt.isValid()){
            throw new Error('522551: [INVALID_DATE]: startDate is not a valid Date. It cannot be parsed to a Date.');
        }

        if (!endDt.isValid()){
            throw new Error('445717: [INVALID_DATE]: endDate is not a valid Date. It cannot be parsed to a Date.');
        }

        if (!page){
            page = 1
        }

        let searchParam = {startDate: startDt.format('MM/DD/YYYY'), endDate: endDt.format('MM/DD/YYYY'), page: page};
        return this._search(searchParam);
    }

    searchByMonths(months, page){

        if (!months){
            months = 12
        }
        if (!page){
            page = 1
        }

        let searchParam = {months: months, page: page};
        return this._search(searchParam);
    }

    searchDefault(page){
        let searchParam = {page: page};
        return this._search(searchParam);
    }


    _search(searchParam){

        let servicePromise = this.search(searchParam);

        if (!servicePromise){
            throw new Error('373303: Expected a promise!. The value is undefined.')
        }

        return this._execPromise(servicePromise);
    }

    _execPromise(promise) {

        let vm = this;
        return new Promise((resolve, reject) => {
            promise.then(function (data) {
                let orders = vm._transformResults(data);
                resolve(orders);

            }).catch(function (e) {
                reject(e);
            });

        });
    }

    _transformResults(data){
        throw new Error('B6EJ3M: [NOT_IMPLEMENTED_METHOD] This method must be implemented by inheritors.');
    }

}
