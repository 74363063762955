export class OrderPartImageViewer {

    constructor() {
        this.partNumber = '';
        this.images = [];
        this.visible = false;
    }

    showImages(orderPartItem) {

        if (orderPartItem == null) return;

        this.images = orderPartItem.getImages();
        this.partNumber = orderPartItem.partNumber;
        this.visible = true;
    }

    close() {
        this.partNumber = '';
        this.images = [];
        this.visible = false;
    }
}