

export class AppBuild{

    get env() {

        if (this._env == null)return '';
        return this._env;
    }

    /**
     *
     * @returns {null}
     */
    get debugMode() {

        if (this._debugmode == null)return '';
        return this._debugmode;
    }

    /**
     * Gets the stamp string of this build
     * @returns {string}
     */
    get stamp() {

        if (this._stamp == null)return '';
        return this._stamp;
    }

    get date() {

        if (this._date == null)return '';
        return this._date;
    }

    get commitHash() {

        if (this._commitHash == null)return '';
        return this._commitHash;
    }

    get shortCommitHash() {

        if (this._shortCommit == null)return '';
        return this._shortCommit;
    }

    get version() {

        if (this._version == null)return '';
        return this._version;
    }

    get buildNumber() {
        if (this._buildNumber == null)return '';

        return this._buildNumber;
    }

    constructor() {
        this._stamp = null;
        this._debugmode = null;
        this._env = null;
        this._date = null;
        this._commitHash = null;
        this._version = null;
        this._shortCommit = null;
        this._buildNumber = null;
    }

    setStamp(value){
        if (value != null && this._stamp == null){
            this._stamp = value;
        }
    }

    setDebugMode(value){
        if (value != null && this._debugmode == null){
            this._debugmode = value;
        }
    }

    setEnv(value){
        if (value != null && this._env == null){
            this._env = value;
        }
    }

    setDate(value){
        if (value != null && this._date == null){
            this._date = value;
        }
    }

    setCommitHash(value){
        if (value != null && this._commitHash == null){
            this._commitHash = value;
            const shortCommitSize = 8;
            if (this._commitHash.length >= (shortCommitSize + 1)) {
                this._shortCommit = this._commitHash.slice(0, 8);
            }
        }
    }

    setVersion(value){
        if (value != null && this._version == null){
            this._version = value;
        }
    }

    setBuildNumber(value){
        if (value != null && this._buildNumber == null){
            this._buildNumber = value;
        }
    }




}