import {DealerAccountProfile} from "@api/modules/auth/models/DealerAccountProfile";

/**
 * Represents the current user profile.
 * <br />
 * <p>
 *   Note: This class is meant to be populated with the data retrieved from the server (API).
 * </p>
 */
export class UserProfile {

    constructor() {

        this._userId = '';
        this._customerNumber = '';
        this._restricted = false;
        this._name = '';
        this._roles = [];
        this._rolesOptions = [];
        this._companyId = '';
        this._companyName = '';
        this._companyLogo = '';
        this._branches = '';
        this._billingAddress = {};
        this._shippingAddress = {};
        this._account = new DealerAccountProfile();
    }

    /**
     * Gets the user Id
     * @returns {string}
     */
    get userId() {
        return this._userId;
    }

    /**
     * Sets the user Id
     * @param value
     */
    set userId(value) {
        this._userId = value;
    }

    /**
     * Gets the customer number
     * @returns {string}
     */
    get customerNumber() {
        return this._customerNumber;
    }

    /**
     * Sets the customer number
     * @param value
     */
    set customerNumber(value) {
        this._customerNumber = value;
    }

    /**
     * Gets if this profile is retricted
     * @returns {boolean}
     */
    get restricted() {
        return this._restricted;
    }

    /**
     * Sets whether this account is retricted
     * @param value
     */
    set restricted(value) {
        this._restricted = value;
    }

    /**
     * Gets the user's name
     * @returns {string}
     */
    get name() {
        return this._name;
    }

    /**
     * Sets the user's name
     * @param value
     */
    set name(value) {
        this._name = value;
    }

    /**
     * Gets the list of roles assigned to this profile
     * @returns {[]}
     */
    get roles() {
        return this._roles;
    }

    get rolesOptions(){

        if (this._rolesOptions == null){
            return [];
        }

        return this._rolesOptions;
    }

    set rolesOptions(value){
        this._rolesOptions = value;
    }
    /**
     * Sets the list of roles assigned to this profile.
     * @param value
     */
    set roles(value) {
        this._roles = value;
    }

    get companyId() {
        return this._companyId;
    }

    set companyId(value) {
        this._companyId = value;
    }

    get companyName() {
        return this._companyName;
    }

    set companyName(value) {
        this._companyName = value;
    }

    get companyLogo() {
        return this._companyLogo;
    }

    set companyLogo(value) {
        this._companyLogo = value;
    }

    get branches() {
        return this._branches;
    }

    set branches(value) {
        this._branches = value;
    }

    get billingAddress() {
        return this._billingAddress;
    }

    set billingAddress(value) {
        this._billingAddress = value;
    }

    get shippingAddress() {
        return this._shippingAddress;
    }

    set shippingAddress(value) {
        this._shippingAddress = value;
    }

    get account() {
        return this._account;
    }

    setAccount(value) {

        if ( !(value instanceof  DealerAccountProfile)){
            throw new Error("#25MC2D:[INVALID_CLASS>>EXPECTED_INSTANCE_OF::DealerAccountProfile]: Invalid object for account.");
        }

        this._account = value;
    }
}