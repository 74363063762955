import {OrderPartItem} from "./OrderPartItem";
import {StringUtils} from "@api/common/StringUtils";
import {Tracer} from "@api/common/Tracer";

/**
 * Represents the order collection in the order part view
 */
export class OrderPartList {

    static _ROWS_LEN = 5;
    static _ROWS_MAX = 50;
    static _ROWS_EMERGENCY_ORDER_LEN = 1;
    static _ROWS_EMERGENCY_ORDER_MAX = 1;

    /**
     * @param auth
     * @param isEmergencyOrder True if it is an emergency order transaction otherwise fase.
     */
    constructor(auth, isEmergencyOrder){

        if (auth == null){
            throw new Error('9MPLWP-(part_list)[missing_auth]: Auth argument missing.');
        }

        this._isEmergencyOrder = false;

        if (isEmergencyOrder != null && isEmergencyOrder){
            this._isEmergencyOrder = true;
        }

        Tracer.current.debug(`DK4Z56-(part_list)[is_emergency_order=${this._isEmergencyOrder}]`);

        this._auth = auth;
        this._rows = (this._isEmergencyOrder) ? OrderPartList._ROWS_EMERGENCY_ORDER_LEN : OrderPartList._ROWS_LEN;

        this._lines = [];

        for(let i = 0; i < this._rows; i++){
            this._lines.push(new OrderPartItem(this._auth, this._isEmergencyOrder));
        }

        if (this._isEmergencyOrder && this._lines.length !== 1){
            throw new Error('9JQ9NM:[INVALID_OBJECT_STATE]: Invalid Object State. Emergency order can only have one item');
        }
    }

    /**
     * Gets the rows available
     * @returns {OrderPartItem[]}
     */
    get rows(){
        return this._lines;
    }

    /**
     * DO NOT USE IT. Only for binding purposes.
     * @param value - will not set value.
     */
    set rows(value){
      // purposely empty.
    }

    /**
     * Clear data from the row and set to the default row number, **If** parts are saved in the shopping cart
     * then it will default to that information
     */
    clear(){

        this._rows = (this._isEmergencyOrder) ? OrderPartList._ROWS_EMERGENCY_ORDER_LEN : OrderPartList._ROWS_LEN;
        this._lines = [];

        for(let i = 0; i < this._rows; i++){
            this._lines.push(new OrderPartItem(this._auth, this._isEmergencyOrder));
        }
    }

    /**
     * Add parts to this instance
     * @param parts {OrderPartItem[]}
     * @return void
     */
    addLoadedParts(parts){

        // emergency orders should not save anything.
        if (this._isEmergencyOrder){
            return;
        }

        if (parts == null){
            return;
        }

        let tempLines = [];

        for(let i = 0 ; i < parts.length; i++){

            let tempPart = new OrderPartItem(this._auth, this._isEmergencyOrder);
            tempLines.push(tempPart);

            tempPart.setPartObject(parts[i]);

        }

        this._lines = tempLines;

    }

    addParts(parts){

        // emergency orders can't add more lines
        if (this._isEmergencyOrder){
            return;
        }

        if (parts == null){
            return;
        }

        let tempLines = [];
        for(let i = 0 ; i < parts.length; i++){
            let tempPart = new OrderPartItem(this._auth, this._isEmergencyOrder);
            tempPart.setPart(parts[i].partNumber, parts[i].qty);
            tempLines.push(tempPart);
        }

        this._lines = tempLines;
        this.addLines(this._rows);
    }

    /**
     * Adds a part number to the next empty line.
     *
     * @param partNumber {string} The part number
     * @param allowBackOrders {boolean} Whether back orders are allowed or not
     * @param cb {function} Callback function
     */
    addToNext(partNumber, allowBackOrders, cb){

        let vm = this;
        let emptyLineFound = false;
        let index = -1;

        if (this._isEmergencyOrder === true){
            index = 0;
        }
        else {
            // check first if all lines are occopied
            for (let i = 0; i < this._lines.length; i++) {
                if (StringUtils.isEmptyOrNull(this._lines[i].partNumber)) {
                    emptyLineFound = true;
                    index = i;
                    break;
                }
            }

            if (emptyLineFound === false) {
                // add one line
                this.addLines(1);
                index = this._lines.length - 1;
            }
        }

        let currentLine = this._lines[index];
        this._lines[index].load(partNumber, function(part) {

            // only update the inventory when back order is not allowed
            if (!allowBackOrders) {
                vm.updateInventory(partNumber, index);
            }

            emptyLineFound = true;
            cb(currentLine, part)
        });

    }

    /**
     * Updates the inventory of a branch based on the quantity ordered in other lines
     * @param partNumber
     * @param currentIndex
     */
    updateInventory(partNumber, currentIndex){

        if (currentIndex < 0 || currentIndex >= this._lines.length){
            throw new Error("#FZGE26:!INDEX_OUT_OF_RANGE! the currentIndex argument is outside the boundaries of the array holding the lines");
        }

        // emergency orders can only have 1 as quantity
        if (this._isEmergencyOrder){
            return;
        }


        Tracer.current.debug("#23Q39I:[SCAN_INVENTORY]: Scan inventory to be updated...");
        if (StringUtils.isEmptyOrNull(partNumber)){
            return;
        }

        let _tmpList = [];
        let _currentLine = null;
        for(let i = 0; i < this._lines.length; i++) {

            if (StringUtils.isEmptyOrNull(this._lines[i].partNumber)){
                continue;
            }

            if (i === currentIndex) {

                if (StringUtils.compareIgnoreCase(this._lines[i].partNumber, partNumber)) {
                    _currentLine = this._lines[i];
                }

                continue;
            }

            if (StringUtils.compareIgnoreCase(this._lines[i].partNumber, partNumber)) {
                _tmpList.push(this._lines[i]);
                Tracer.current.info("[info-100898] Same part number on the current list found.");
            }
        }

        if (_tmpList.length > 0){
            //now let's update the inventory of the current list based on the inventory selected.

            Tracer.current.debug(`[dbg-160178] ${_tmpList.length} lines with the current part number found; will update inventory accordingly`);

            for(let i = 0; i < _tmpList.length; i++){

                let tmpLine = _tmpList[i];

                if (StringUtils.isEmptyOrNull(tmpLine.partNumber)){
                    Tracer.current.debug(`[dbg-890294] part number is empty or null, continue with next element`);
                    continue;
                }

                let lineSelectedBranch = tmpLine.getSelectedBranch();
                let lineOrderQuantity = tmpLine.quantity;

                Tracer.current.debug(`[dbg-608797] The selected branch of line we are working currently is ${lineSelectedBranch.id} with an inventory of ${lineSelectedBranch.qty} and user selected ${lineOrderQuantity}`);

                let currentBranch = _currentLine.getBranch(lineSelectedBranch.id);
                Tracer.current.debug(`[dbg-245897] The selected branch of the current line is ${currentBranch.id}`);

                let newQty = lineSelectedBranch.qty - lineOrderQuantity;

                Tracer.current.debug(`[dbg-245897] The new quantity for the branch for the current line will be ${newQty}`);
                if (newQty < 0){
                    newQty = 0;
                }

                _currentLine.updateBranchQuantity(lineSelectedBranch.id, newQty);
                Tracer.current.debug(`[dbg-160178] ${lineSelectedBranch.id} new inventory quantity is ${newQty}`);

            }
        }
    }

    addLines(number){

        // emergency orders cannot add more lines
        if (this._isEmergencyOrder){
            return;
        }


        let tmpNumber = OrderPartList._ROWS_LEN;
        if (number != null){
            tmpNumber = number;
        }

        let index = this._rows;
        this._rows += tmpNumber;
        for(let i = 0; i < tmpNumber; i++){
            index++;
            this._lines.push(new OrderPartItem(this._auth, this._isEmergencyOrder));
        }

    }

    delete(index, deleteFunc){

        try
        {
            let line = this._lines[index];
            this._lines.splice(index, 1);

            if (line.cartItemId != null){
                Tracer.current.debug(`#1EFM7J:[DELETE_ITEM] deleting item with id of ${line.cartItemId}`);
                if (deleteFunc != null){
                    deleteFunc(line.cartItemId);
                }
            }

        }
        catch(err){
            Tracer.current.error(err);
            throw err;
        }

    }



}