
// DEFINITION OF CONSTANTS THROUGH THE APPLICATION
// CONSTANTS SHOULD BE DEFINED ALL UPPER CASE.


/** COMPANY SETTINGS KEY **/
export const SETTINGS_BACKORDER_CONFIRMATION_MESSAGE_KEY = "backorder-confirmation-message";
export const SETTINGS_BACKORDER_SHOPPING_CART_MESSAGE_KEY = "backorder-shopping-cart-message";
export const SETTINGS_BRANCH_SELECTED_MESSAGE_KEY = "closest-branch-selected-message";
export const SETTINGS_COMPANY_PHONE_NUMBER = "company-phone-number";
export const SETTINGS_COMPANY_LOGO_KEY = "company-logo";



