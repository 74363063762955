<template>
	<div class="footer flex align-center justify-space-between" :class="{'above':position === 'above'}">
		<div><span class="hidden-xs-only">All Rights Reserved - 2019-2024 AER Manufacturing.</span></div>
		<div>
			<span>{{versionLabel}}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Footer',
	props: {
    position: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    versionLabel(){
      return `${$build.version} | build: ${$build.buildNumber} ${$build.date}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.footer {
	width: 100%;
	height: 40px;
	padding: 0 20px;
	box-sizing: border-box;
	background: white;
	margin-top: 2px;
	font-size: 14px;
	border-top-left-radius: 20px;

	&.above {
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
		z-index: 1;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		border-radius: 0;
	}

	a {
		font-weight: bold;
		text-decoration: none;
		color: $text-color-accent;
	}

	.buy-btn {
		margin-left: 10px;
	}
}

@media (max-width: 768px) {
	.footer {
		padding: 0 12px;
		border-radius: 0;
	}
}
</style>
