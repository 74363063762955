/**
 * Represents an email item for the Dealer Email Notifications
 */
import {StringUtils} from "@api/common/StringUtils";
import {Convert} from "@api/common/Convert";
import {Tracer} from "@api/common/Tracer";

/**
 * Represents a email notification item.
 */
export class NotificationItem {

    /**
     * returns the type of notification.
     * @returns {*}
     */
    get type() {
        return this._type;
    }

    /**
     * Gets the sequence number of this email
     * @returns {*}
     */
    get sequence() {
        return this._sequence;
    }

    /**
     * Gets the email address
     * @returns {*}
     */
    get email() {
        return this._email;
    }

    constructor(email, sequence, type) {

        if (StringUtils.isEmptyOrNull(email)){
            throw new Error("[e-698527] Email argument cannot be empty");
        }

        if (StringUtils.isEmptyOrNull(type)){
            throw new Error("[e-629837] Type argument cannot be empty");
        }

        if (!Convert.isNumber(sequence)){
            throw new Error("[e-591676] sequence expected to be a number (integer)");
        }
        this._email = email;
        this._sequence = sequence;
        this._type = type;



        if (this._sequence === 0){
            Tracer.current.warning("[e-502548] Sequence 0 is not acceptable. This email notification most likely will be ignored!");
        }


    }

    change(email){
        if (StringUtils.isEmptyOrNull(email)){
            throw new Error("[e-800911] Unable to update with an empty email address");
        }
    }

}