import {Tracer} from "@api/common/Tracer";
import {StringUtils} from "@api/common/StringUtils";

/**
 * @private
 * Defines the default max quantity for emergency orders.
 * @type {number}
 */
const _MAX_QTY = 1;

// @todo: the max allow config should be server from the API (in the future).
// @IMPORTANT: The API is ready. We must use the api instead of this configuration for the _MAX_QTY!!
// @ticket: AER-913

/**
 * @private
 * Defines the max quantity per group.
 * @type {{"951": number, "72": number, "260": number}}
 */
const _MAX_QTY_GROUPS = {

    '72': 0,
    '260': 8,
    '741': 0,
    '951': 0,

    '300': 0,
    '301': 0,
    '302': 0,
    '303': 0,
    '304': 0,
    '305': 0,
    '306': 0,
    '307': 0,
    '308': 0,
    '309': 0,

    '310': 0,
    '311': 0,
    '312': 0,
    '313': 0,
    '314': 0,
    '315': 0,
    '316': 0,
    '317': 0,
    '318': 0,
    '319': 0,

    '320': 0,
    '321': 0,
    '322': 0,
    '323': 0,
    '324': 0,
    '325': 0,
    '326': 0,
    '327': 0,
    '328': 0,
    '329': 0,

    '330': 0,
    '331': 0,
    '332': 0,
    '333': 0,
    '334': 0,
    '335': 0,
    '336': 0,
    '337': 0,
    '338': 0,
    '339': 0,

    '340': 0,
    '341': 0,
    '342': 0,
    '343': 0,
    '344': 0,
    '345': 0,
    '346': 0,
    '347': 0,
    '348': 0,
    '349': 0,

    '350': 0,
    '351': 0,
    '352': 0,
    '353': 0,
    '354': 0,
    '355': 0,
    '356': 0,
    '357': 0,
    '358': 0,
    '359': 0,

    '360': 0,
    '361': 0,
    '362': 0,
    '363': 0,
    '364': 0,
    '365': 0,
    '366': 0,
    '367': 0,
    '368': 0,
    '369': 0,

    '370': 0,
    '371': 0,
    '372': 0,
    '373': 0,
    '374': 0,
    '375': 0,
    '376': 0,
    '377': 0,
    '378': 0,
    '379': 0,

    '380': 0,
    '381': 0,
    '382': 0,
    '383': 0,
    '384': 0,
    '385': 0,
    '386': 0,
    '387': 0,
    '388': 0,
    '389': 0,

    '390': 0,
    '391': 0,
    '392': 0,
    '393': 0,
    '394': 0,
    '395': 0,
    '396': 0,
    '397': 0,
    '398': 0,
    '399': 0,
}

/**
 * @private
 * Text message for parts that are in stock but cannot be ordered in the emergency orders
 * @type {string}
 * @private
 */
const _IN_STOCK_MESSAGE = 'THIS PART IS IN STOCK. IT CANNOT BE ORDERED AS EMERGENCY ORDER';

/**
 * Text message for parts that can only be order as back order.
 * @type {string}
 * @private
 */
const _ONLY_BACK_ORDER_ALLOWED = 'PLEASE BACKORDER THROUGH THE ORDER PARTS TAB';

/**
 * Text message for new parts in the emergency orders.
 * @type {string}
 * @private
 */
const _NEW_PART_MESSAGE = 'NEW PART PLEASE BACKORDER THROUGH THE ORDER PARTS TAB';

/**
 * Text message for new parts in the emergency orders.
 * @type {string}
 * @private
 */
const _EMERGENCY_RESTRICTED_PART_MESSAGE = 'BACKORDER ONLY - PLEASE BACKORDER THROUGH THE ORDER PARTS TAB';

/**
 * @private
 * The list of group code for new assemblies (engines, transmission) etc. T
 * This value is configured in the .env file
 */
const _NEW_ASSEMBLIES_GROUP_CODES = process.env.VUE_APP_NEW_ASSEMBLIES_GROUP_CODES;

/**
 * Contains the configuration for the emergency orders logic
 */
export class EmergencyOrdersConfig {

    /**
     * Get the default maximun allowed for emergency orders
     * @returns {number}
     */
    get maxQty(){
        return _MAX_QTY;
    }

    /**
     * Gets the custom message when the part is in stock, and
     * it cannot be ordered as emergency orders
     * @returns {string}
     */
    get inStockMessage(){
        return _IN_STOCK_MESSAGE;
    }


    get backOrderMessage() {
        return _ONLY_BACK_ORDER_ALLOWED;
    }

    /**
     * Gets the custom message for new parts in the emergency orders
     * @returns {string}
     */
    get newPartMessage(){
        return _NEW_PART_MESSAGE;
    }


    /**
     * Gets the custom message for parts not allowed for emergency orders
     * @returns {string}
     */
    get restrictedEmergencyOrderPartMessage(){
        return _EMERGENCY_RESTRICTED_PART_MESSAGE;
    }

    /**
     * Tells us if the group belongs to a new part (new assembly) group or not.
     * @param group - The group which the part number belongs to
     * @returns {boolean}
     */
    isNewPart(group){
        return StringUtils.existsInList(_NEW_ASSEMBLIES_GROUP_CODES, group);
    }

    isRestricted(group){

        if (this.isNewPart(group)){
            return false;
        }

        let value = _MAX_QTY_GROUPS[group];
        if (value == null){
            return false;
        }

        return value === 0;
    }

    /**
     * Gets the maximun allowed based on the group the part belong.
     * @param group
     * @returns {number|*}
     */
    getMaxQty(group){

        const value = _MAX_QTY_GROUPS[group];

        Tracer.current.debug(`75640-(em_orders_cfg)[group=${group}][max=${value}]: The max quantity for ${group} is ${value}`);
        if (value == null){
            return this.maxQty;
        }
        else{
            return value;
        }
    }


}