import moment from "moment";

/**
 * Represents the result of a session check results
 */
export class SessionCheckResult {


    constructor() {
        this._status = 0;
        this._expires = null;
    }

    /**
     * Returns whether the session is authenticated.
     * @return {boolean}
     */
    isAuthenticated() {
        return this._status === SessionCheckResult.__STATUS.authenticated || this._status === SessionCheckResult.__STATUS.authenticatedWithWarning;
    }

    hasWarning() {
        return this._status === SessionCheckResult.__STATUS.authenticatedWithWarning;
    }

    /**
     * Returns whether the session is not authenticated because the session expired.
     * @return {boolean}
     */
    isExpired() {
        return this._status === SessionCheckResult.__STATUS.expired;
    }

    /**
     * Returns whether the session is not authenticated because there is another live session with the same user.
     * @return {boolean}
     */
    isDuplicated() {
        return this._status === SessionCheckResult.__STATUS.duplicated;
    }

    /**
     * Returns whether the session is not authenticated because the server is in maintenance.
     * @return {boolean}
     */
    isMaintenance() {
        return this._status === SessionCheckResult.__STATUS.maintenance;
    }

    /**
     * Returns an instance of the `SessionCheckResult` that represents that the user is not authenticated.
     * @return {SessionCheckResult}
     */
    static noAuthenticated() {
        const result = new SessionCheckResult();
        result.__setNotAuthenticated();
        return result;
    }

    /**
     * Returns an instance of the `SessionCheckResult` that represents that the server is in maintenance.
     * @return {SessionCheckResult}
     * @constructor
     */
    static InMaintenance() {
        const result = new SessionCheckResult();
        result.__setMaintenance();
        return result;
    }

    /**
     * Returns an instance of the `SessionCheckResult` that represents that the user is authenticated.
     * @param expires
     * @return {SessionCheckResult}
     */
    static authenticated(expires) {
        const result = new SessionCheckResult();

        if (expires != null) {
            result.__setExpires(expires);
        }

        result.__setAuthenticated();
        return result;
    }

    /**
     * Returns an instance of the `SessionCheckResult` that represents that the user is authenticated with a maintenance warning.
     * @param expires
     * @return {SessionCheckResult}
     */
    static authenticatedWithMaintenanceWarning(expires) {
        const result = new SessionCheckResult();

        if (expires != null) {
            result.__setExpires(expires);
        }

        result.__setAuthenticatedWithWarning();
        return result;
    }

    /**
     * Returns an instance of the `SessionCheckResult` that represents that the session has expired.
     * @return {SessionCheckResult}
     */
    static expired() {
        const result = new SessionCheckResult();
        result.__setExpired();
        return result;
    }

    static duplicatedSession() {
        const result = new SessionCheckResult();
        result.__setDuplicated();
        return result;
    }



    /*
       -- Private methods
     */

    /**
     * Returns the status of the session.
     * @return {{duplicated: number, authenticated: number, notAuthenticated: number, expired: number, authenticatedWithWarning: number, maintenance: number}}
     * @private
     */
    static get __STATUS() {
        return {
            authenticated: 1,
            authenticatedWithWarning: 2,
            expired: 3,
            duplicated: 4,
            maintenance: 5,
            notAuthenticated: 0,

        }
    }

    /**
     * Sets the expiration date time of the session.
     * @param expireDateTime
     * @private
     */
    __setExpires(expireDateTime) {
        this._expires = moment(expireDateTime);
    }

    /**
     * Sets the status of the session to authenticated.
     * @private
     */
    __setAuthenticated() {
        this._status = SessionCheckResult.__STATUS.authenticated;
    }

    /**
     * Sets the status of the session to authenticated with warning.
     * @private
     */
    __setAuthenticatedWithWarning() {
        this._status = SessionCheckResult.__STATUS.authenticatedWithWarning;
    }


    /**
     * Sets the status of the session to expired.
     * @private
     */
    __setExpired() {
        this._status = SessionCheckResult.__STATUS.expired;
    }

    /**
     * Sets the status of the session to duplicated.
     * @private
     */
    __setDuplicated() {
        this._status = SessionCheckResult.__STATUS.duplicated;
    }

    /**
     * Sets the status of the session to not authenticated.
     * @private
     */
    __setNotAuthenticated() {
        this._status = SessionCheckResult.__STATUS.notAuthenticated;
    }

    /**
     * Sets the status of the session to not auhthenticated because the server is in maintenance.
     * @private
     */
    __setMaintenance() {
        this._status = SessionCheckResult.__STATUS.maintenance;
    }

}